import React, { useEffect } from "react";
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom";
import colors from "../../../../config/theme/colors";
import styled from "styled-components";
import {
  Grid,
  TextField,
  Typography,
  Divider,
  MenuItem,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";
import OurButton from "../../button/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ChipInput from "material-ui-chip-input";
import ChipCustom from "./Chip";
import arrayMoveImmutable from "array-move";

const ChipInputCustomSkus = styled(ChipInput)`
  .MuiInputBase-root {
    align-items: flex-start;
    display: block;
  }
  .MuiInputBase-input {
    min-width: auto;
    padding: 0;
    margin: 0;
  }
  > div {
    margin: 0;
    height: 100%;
  }
  fieldset {
    border: none;
  }
`;
const TextFieldCustom = styled(TextField)`
  input,
  .MuiSelect-select {
    font-size: 16px;
    padding: 16px;
    border-radius: 0;
    border-color: ${colors.grey.lighter.hue700};
    @media screen and (max-width: 1450px) {
      font-size: 14px;
      padding: 14px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 13px;
      padding: 13px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
      padding: 13px;
    }
  }
  & > p {
    margin: 0;
  }
`;

const TextFieldAccordionCustom = styled(TextField)`
  input {
    height: 40px;
    padding: 0;
  }
  fieldset {
    border: none;
  }
  input::placeholder {
    font-style: italic;
  }
`;

const SkuSelector = (props) => {
  const [refToCopy, setRefToCopy] = React.useState([]);

  useEffect(async () => {
    if (refToCopy?.length > 0) {
      await navigator.clipboard.writeText(refToCopy.join(" "));
    }
  }, [refToCopy]);

  useEffect(() => {
    if (!props.value) {
      props.setSelectedProducts(props.input.stateName, "new", "group");
    }
  }, [props.value]);

  const move = (items, dragIndex, hoverIndex, groupIndex) => {
    props.setSelectedProducts(
      props.input.stateName,
      arrayMoveImmutable(items, dragIndex, hoverIndex),
      "products",
      groupIndex
    );
  };

  return (
    <>
      <Typography
        style={{
          fontWeight: "bold",
          color: colors.black.regular,
          marginBottom: 5,
        }}
      >
        {props.input.label}
      </Typography>
      {props?.value?.map((group, groupIndex) => {
        let left = 0;
        let top = 0;
        let radiusLimit = 1;
        let focus = false;
        return (
          <React.Fragment key={groupIndex}>
            <AccordionCustom
              style={{ marginBottom: 20 }}
              custompadding={"0 !important"}
              detailsStyles={{ height: "250px", overflow: "scroll" }}
              title={
                <>
                  <TextFieldAccordionCustom
                    onChange={(evt) =>
                      props.setSelectedProducts(
                        props.input.stateName,
                        evt.target.value,
                        "title",
                        groupIndex
                      )
                    }
                    variant="outlined"
                    fullWidth
                    placeholder={"Ajoutez un nom de collection"}
                    style={{
                      fontWeight: "bold",
                      color: colors.black.regular,
                    }}
                    value={group?.title}
                  />
                  <KeyboardArrowDownIcon
                    onClick={() => {
                      if (groupIndex + 1 < props?.value?.length) {
                        props.setSelectedProducts(
                          props?.input?.stateName,
                          null,
                          "order",
                          groupIndex,
                          null,
                          "down"
                        );
                      }
                    }}
                    style={{
                      cursor:
                        groupIndex + 1 === props?.value?.length
                          ? "not-allowed"
                          : "pointer",
                      color:
                        groupIndex + 1 === props?.value?.length
                          ? "grey"
                          : colors.black.regular,
                    }}
                  />
                  <KeyboardArrowUpIcon
                    onClick={() => {
                      if (groupIndex > 0) {
                        props.setSelectedProducts(
                          props.input.stateName,
                          null,
                          "order",
                          groupIndex,
                          null,
                          "up"
                        );
                      }
                    }}
                    style={{
                      cursor: groupIndex === 0 ? "not-allowed" : "pointer",
                      color: groupIndex === 0 ? "grey" : colors.black.regular,
                    }}
                  />
                  <DeleteIcon
                    onClick={() =>
                      props.setSelectedProducts(
                        props.input.stateName,
                        null,
                        "deleteGroup",
                        groupIndex
                      )
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </>
              }
              forcedExpanded
            >
              <ChipInputCustomSkus
                color="secondary"
                variant="outlined"
                value={group?.products || []}
                disabled={false}
                fullWidth
                fullWidthInput
                chipRenderer={(
                  {
                    value,
                    text,
                    chip,
                    isFocused,
                    isDisabled,
                    isReadOnly,
                    handleClick,
                    handleDelete,
                    className,
                  },
                  key
                ) => {
                  let addToCopy = (value) => {
                    let array = [...refToCopy];
                    let exist = array.find((e) => e === value);
                    if (exist) {
                      array = array.filter((e) => e !== value);
                    } else {
                      array.push(value);
                    }
                    setRefToCopy(array);
                  };
                  return (
                    <ChipCustom
                      className={className}
                      key={key}
                      index={key}
                      move={(dragIndex, hoverIndex) =>
                        move(group?.products, dragIndex, hoverIndex, groupIndex)
                      }
                      value={value}
                      label={chip}
                      isFocused={isFocused}
                      onClick={() => addToCopy(value)}
                      onDelete={handleDelete}
                      size="small"
                      style={{
                        backgroundColor: refToCopy.find((e) => e === value)
                          ? colors.blue.lighter.hue200
                          : null,
                        borderRadius: "0px",
                        width: "95%",
                        justifyContent: "space-between",
                        padding: "15px 5px",
                      }}
                    />
                  );
                }}
                inputRef={(input) => input?.parentElement?.target?.blur()}
                InputProps={{
                  onfocus: (event) => (!focus ? event.target.blur() : null),
                }}
                onFocus={(event) => (!focus ? event.target.blur() : null)}
                onMouseDown={(event) => {
                  left = event.pageX;
                  top = event.pageY;
                }}
                onMouseUp={(event) => {
                  var deltaX = event.pageX - left;
                  var deltaY = event.pageY - top;
                  var euclidean = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                  if (euclidean > radiusLimit) {
                    focus = false;
                  } else {
                    focus = true;
                  }
                }}
                onDelete={(chip, index) => {
                  let array = group?.products.filter((e) => e !== chip);
                  props.setSelectedProducts(
                    props.input.stateName,
                    array,
                    "products",
                    groupIndex
                  );
                }}
                onAdd={(chip) => {
                  let initialArray = group?.products || [];
                  let delimiter = chip.indexOf(" ")
                    ? " "
                    : chip.indexOf(",")
                    ? ","
                    : chip.indexOf("|")
                    ? "|"
                    : " ";
                  let getSplit = chip
                    .split(delimiter)
                    .filter((e) => e !== " " && e !== "");
                  props.setSelectedProducts(
                    props.input.stateName,
                    initialArray.concat(getSplit),
                    "products",
                    groupIndex
                  );
                }}
              />
            </AccordionCustom>

            {group?.subRef?.map((sub, i) => {
              return (
                <Grid
                  container
                  spacing={1}
                  key={i}
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={5}>
                    <TextFieldCustom
                      variant="outlined"
                      select
                      fullWidth
                      onChange={(evt) => {
                        props.setSelectedProducts(
                          props.input.stateName,
                          {
                            sku: group?.products?.find(
                              (e) => e === evt.target.value
                            ),
                            sub: sub?.sub,
                          },
                          "subRef",
                          groupIndex,
                          i
                        );
                      }}
                      value={sub?.sku}
                    >
                      {group?.products?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </TextFieldCustom>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <KeyboardArrowRightIcon
                      style={{ color: colors.black.regular }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextFieldCustom
                      variant="outlined"
                      fullWidth
                      value={sub?.sub}
                      onChange={(evt) => {
                        props.setSelectedProducts(
                          props.input.stateName,
                          { sku: sub?.sku, sub: evt.target.value },
                          "subRef",
                          groupIndex,
                          i
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DeleteIcon
                      onClick={() => {
                        props.setSelectedProducts(
                          props.input.stateName,
                          null,
                          "subRef",
                          groupIndex,
                          i,
                          "delete"
                        );
                      }}
                      style={{
                        cursor: "pointer",
                        color: colors.black.regular,
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
            <Typography
              onClick={() => {
                props.setSelectedProducts(
                  props.input.stateName,
                  { sku: null, sub: null },
                  "subRef",
                  groupIndex,
                  null,
                  "add"
                );
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                color: colors.green.regular,
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              <AddCircleIcon fontSize="small" /> Ref de remplacement
            </Typography>
            <Divider
              style={{
                margin: "20px 0",
              }}
            />
            {groupIndex === props?.value?.length - 1 && (
              <OurButton
                onClick={() => {
                  props.setSelectedProducts(
                    props.input.stateName,
                    "new",
                    "group"
                  );
                }}
                style={{
                  width: "100%",
                }}
                bgcolor={colors.blue.lighter.hue900}
                bgcolorhover={colors.blue.lighter.hue600}
                color={colors.blue.darker.hue300}
                colorhover={colors.blue.darker.hue300}
                border={`1px solid ${colors.blue.darker.hue300}`}
              >
                + Ajouter une collection
              </OurButton>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SkuSelector;
