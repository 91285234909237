import CardContent from "../components/CardContent";
import TableContent from "../components/TableContent";
import colors from "../../../../../config/theme/colors";

export const config = {
  mappers: [
    {
      view: "card",
      mapper: "contentsCardMapper",
    },
    {
      view: "list",
      mapper: "contentsListMapper",
    },
  ],
  cardComponent: CardContent,
  tableComponent: TableContent,
  views: {
    default: "card",
    options: [
      {
        label: "common.list",
        value: "list",
      },
      {
        label: "common.card",
        value: "card",
      },
    ],
  },
  columns: [
    {
      title: "",
    },
    {
      title: "Image",
    },
    {
      title: "Status",
    },
    {
      title: "Name",
      padding: "normal",
    },
    {
      title: "Categories",
    },
    {
      title: "",
    },
  ],
  // perPage: {
  //   show: true,
  //   default: 12,
  //   options: [12, 24, 36, 48, 60, 72],
  // },
  // order: {
  //   show: false,
  //   stateName: "order",
  //   default: "desc",
  //   options: [
  //     {
  //       label: "common.sort.desc",
  //       value: "desc",
  //       icone: <img src={bottomArrow} alt="bottom-arrow-icon" />,
  //     },
  //     {
  //       label: "common.sort.asc",
  //       value: "asc",
  //       icone: <img src={topArrow} alt="top-arrow-icon" />,
  //     },
  //   ],
  // },
  lang: false,
  tableDraggable: true,
  filters: {
    options: [
      {
        label: "common.search",
        stateName: "sku",
        type: "text",
        placeholder: "brand.list.filters.searchPlaceholder",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={colors.grey.lighter.hue600}
            height={25}
          >
            <title>SearchIcon</title>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        ),
      },
    ],
  },
};
