import _ from "lodash";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export default function TableRowDraggable(props) {
  const {
    item,
    isLast,
    selectionIsActive,
    selectedProducts,
    setSelectedProducts,
    tableProps,
    config,
    index,
    moveRow,
    updateRow
  } = props;

  const { id } = item;
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "TableRowDraggable",
    item: { type: "TableRowDraggable", id, index },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isDragging: monitor.isDragging(),
    }),
    end : (item, monitor) => {
      const dragIndex = item.index;
      updateRow(dragIndex)
    }
  });

  const [, drop] = useDrop({
    accept: "TableRowDraggable",
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      item.index = hoverIndex;
      moveRow(dragIndex, hoverIndex);
    }
  });

  drag(drop(ref));

  return (
    <config.tableComponent
      {...tableProps}
      style={{
        opacity: isDragging ? 0.2 : 1,
        cursor: "move",
      }}
      tableRef={ref}
      item={item}
      selectionIsActive={selectionIsActive}
      selectedProducts={selectedProducts}
      setSelectedProducts={setSelectedProducts}
      isLast={isLast}
    />
  );
}
