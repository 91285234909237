import { Grid } from "@material-ui/core";
import React from "react";
import CardCustom from "../../../Card/CardCustom";
import { connect } from "react-redux";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const GridView = (props) => {
  const { config, items, currentLang, cardProps } = props;
  return (
    <Grid container spacing={1}>
      {items?.length > 0
        ? items.map((item, index) => (
            <Grid
              item
              key={`list${capitalize(props.label)}-${index}`}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
            >
              <CardCustom
                style={{
                  width: "100%",
                  height: "100%",
                  padding: 0,
                  ...props.cardContainerProps?.style,
                }}
                cardContentStyle={{ padding: 0 }}
                contentpadding={props.cardContainerProps?.contentpadding}
              >
                <config.cardComponent
                  item={item}
                  {...cardProps}
                  currentLang={currentLang}
                />
              </CardCustom>
            </Grid>
          ))
        : null}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    openDrawer: state.openDrawer,
  };
};

export default connect(mapStateToProps)(GridView);
