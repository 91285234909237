import { withTranslation } from "react-i18next";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import styled from "styled-components";
import moment from "moment";
import colors from "../../../../../config/theme/colors";

const DatePickerCustom = styled(KeyboardDatePicker)`
  &.MuiTextField-root {
    background-color: ${colors.white};
    height: 45px;
    width: 100%;
    .MuiInput-root {
      border: 0.5px solid ${colors.grey.lighter.hue700};
      height: 100%;
      &::before {
        border-bottom: none;
      }
      .MuiInputAdornment-root {
        button span svg {
          fill: #000;
        }
      }
    }
    .MuiFormHelperText-root {
      position: absolute;
    }
  }
  input {
    padding: 0 10px;
    caret-color: transparent;
    font-weight: bold;
  }
`;

const TextFieldCustom = styled(TextField)`
  background-color: ${colors.white};
  border-radius: 0;
  border: 1px solid ${colors.grey.border};
  input,
  .MuiSelect-select {
    font-size: 14px;
    padding: 13px 13px 13px ${(props) => (props.icon ? "5px" : "13px")};
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & > p {
    margin: 0;
  }
`;

const CustomSelect = styled(Select)`
  border: 1px solid ${colors.grey.border};
  border-right: ${(props) =>
    props.noBorder ? "none" : `1px solid ${colors.grey.border}`};
  background-color: ${colors.white};
  height: 45px;
  .MuiSelect-root {
    padding-top: 13px;
    padding-bottom: 13px;
    &:focus {
      background-color: unset !important;
    }
  }
  fieldset {
    border: none;
  }
  .MuiPopover-root.MuiPopover-paper {
    box-shadow: none;
    border: 1px solid ${colors.grey.border};
  }
`;

const SimpleFilters = (props) => {
  const { t, config, filters, setFilters } = props;

  const handleFilter = (stateName, value) => {
    const newFilters = { ...filters };
    if (!value || value?.length === 0) {
      delete newFilters?.[stateName];
    } else {
      newFilters[stateName] = value;
    }
    setFilters(newFilters);
  };

  return (
    <Grid container style={{ gap: 15 }}>
      {config?.filters?.options?.map((option, i) => {
        if (option.type === "select") {
          return (
            <Grid
              key={i}
              item
              xs={"auto"}
              style={{
                width: 200,
              }}
            >
              <CustomSelect
                key={`${option.stateName}-${i}`}
                value={filters?.[option.stateName] || option.defaultValue || ""}
                onChange={(evt) =>
                  handleFilter(option.stateName, evt.target.value)
                }
                variant="outlined"
                fullWidth
                MenuProps={{
                  PaperProps: {
                    square: true,
                    style: {
                      outline: `0.5px solid ${colors.grey.border}`,
                      width: 300,
                      maxHeight: "500px !important",
                    },
                  },
                }}
              >
                {option.values?.map((e, j) => {
                  return (
                    <MenuItem
                      style={{ color: colors.black.regular }}
                      value={e.value}
                      key={`${e.label}-${i}`}
                    >
                      {t(e.label)}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            </Grid>
          );
        }
        if (option.type === "date") {
          const initialValue =
            filters?.[option.stateName] || option.defaultValue || "";
          const validDate = moment(initialValue, "YYYY-MM-DD").isValid()
            ? moment(initialValue, "YYYY-MM-DD")
            : moment();
          return (
            <Grid
              item
              xs={"auto"}
              style={{
                width: 200,
              }}
            >
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <DatePickerCustom
                  disableToolbar
                  autoOk
                  invalidDateMessage={""}
                  maxDateMessage={""}
                  variant="inline"
                  labelFunc={(date, invalidLabel) =>
                    moment(date).format("DD MMM YYYY")
                  }
                  format="yyyy-MM-DD"
                  value={validDate}
                  // maxDate={moment()}
                  onChange={(date, value) =>
                    handleFilter(option.stateName, value)
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          );
        }
        return (
          <Grid item xs={option.xs ? option.xs : true}>
            <TextFieldCustom
              icon={option.icon}
              InputProps={
                option.icon
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          {option.icon}
                        </InputAdornment>
                      ),
                    }
                  : null
              }
              placeholder={t(option.placeholder)}
              variant="outlined"
              // onChange={(evt)=>handleSearch(option.stateName,evt.target.value)}
              // value={filtersState[option.stateName] || option.defaultValue || ""}
              onKeyDown={(evt) =>
                evt.code === "Enter"
                  ? handleFilter(
                      option.stateName,
                      evt.target.value?.length > 0 ? evt.target.value : null
                    )
                  : null
              }
              fullWidth
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withTranslation()(SimpleFilters);
