import { withTranslation } from "react-i18next";
import { TextField, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import arrows from "../../../../assets/pictos/all.svg";

export const TextFieldCustom = styled(TextField)`
  input,
  .MuiSelect-select {
    padding: 8px;
    height: 100%;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 0;
    border-color: ${colors.grey.border};
    display: flex;
    align-items: center;
    @media screen and (max-width: 1450px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
    }
  }
  & > p {
    margin: 0;
  }
  .MuiInputBase-root {
    background-color: white;
    border: none;
    height: 100%;
  }
  fieldset {
    border-color: ${colors.grey.border} !important;
    border-width: 1px !important;
  }
  &:hover {
    fieldset {
      border-color: ${colors.grey.border} !important;
    }
  }
  .MuiSelect-select:focus {
    background-color: #fff !important;
  }
`;

const MenuItemCustom = styled(MenuItem)`
  color: ${colors.black.regular};
  padding: 8px !important;
  gap: 10px;
  background-color: ${colors.white} !important;
  font-size: 14px;
  &.Mui-selected {
    font-weight: bold;
  }
`;

const SortView = (props) => {
  let { config, pagination, setPagination, t } = props;

  const handleOrder = (order) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        order,
      };
    });
  };

  return (
    <div>
      <TextFieldCustom
        variant="outlined"
        color="secondary"
        select
        fullWidth
        SelectProps={{
          IconComponent: () => null,
          renderValue: () => (
            <img src={arrows} width={"100%"} height={"100%"} />
          ),
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            elevation: 0,
            MenuListProps: {
              style: {
                padding: 0,
              },
            },
            PaperProps: {
              square: true,
              style: {
                border: `1px solid ${colors.grey.border}`,
              },
            },
          },
        }}
        value={pagination?.order ? pagination?.order : "desc"}
        onChange={(evt) => {
          handleOrder(evt.target.value);
        }}
        style={{
          height: 35,
          width: 35,
        }}
      >
        {config?.order?.options.map((option, index) => {
          return (
            <MenuItemCustom key={`OptionSelect${index}`} value={option.value}>
              {option.icone}
              {t(option.label)}
            </MenuItemCustom>
          );
        })}
      </TextFieldCustom>
    </div>
  );
};

export default withTranslation()(SortView);
