import {TABS} from '../../../../../js/constants/form-types';
import { ALLOWED } from '../../../../../js/constants/medias-types';
import Button from '../../../../ui/button/Button';

export default function formContentAdd(currentLang, categories, customAttributes, metaAttributes, imageAttributes, categoriesSelected, allGroups, errors, seeErrors, handleMediaPicker, mediaCategories, selectedMediaCategories,isSystemAttributes) {
    const hasTitle = isSystemAttributes?.find(e => e.node.identifier === 'content_title');
    const hasDescription = isSystemAttributes?.find(e => e.node.identifier === 'content_description');
    const hasImage = isSystemAttributes?.find(e => e.node.identifier === 'content_image');
    let typeInput = '';
    let getTime = false;
    let attributeSetup = attribute => {
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case "product_selection":
                typeInput = 'skuSelector';
                break;
            case 'image':
                typeInput = 'mediaPicker';                
                break;
            case 'file':
                typeInput = 'file';
                break;
            case 'date':
                typeInput = 'date';
                break;
            case "media_category":
                typeInput = 'selectTree';
                break;
            case 'datetime':
                typeInput = 'date';
                getTime= true;
                break;
            case 'bundle_group':
                typeInput = 'bundle_group';
                break;
            default: typeInput = null;
        }

        return ({
            type: typeInput,
            getTime,
            label: langSelected?.node.value ?? defaultLang?.node.value ?? attribute.node.identifier,
            handleMediaPicker:handleMediaPicker,
            translated: true,
            helper: {
                text: '',
                link: false,
            },
            isSystem: false,
            currentLang,
            doubleRow:true,
            allowedTypes:ALLOWED,
            mediaInline:attribute.node.identifier === "content_image" ? false : true,
            mediasCategories : attribute.node.attributeType.input==="media_category",
            fullwidth:typeInput === 'mediaPicker'?true:false,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node?.value ?? langSelectedValuesDefault?.node?.value
                });
            }),
        });
    };

    let generalInputs = [
        {
            type: 'select',
            label: `Modèle`,
            helper: {
                text: `Indiquez le modèle`,
                link: false,
            },
            translated: false,
            required: true,
            disabled: true,
            stateName: 'groupAttribut',
            value: allGroups.map(group => {
                const langSelectedValuesDefault = group.node.translation.translationDatas.edges[0];

                const langSelectedValues = group.node.translation.translationDatas.edges.find(
                    lang  => lang.node.locale.code === currentLang
                );

                return ({
                    value: group.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? group.node.identifier
                });
            })
        },
        {
            type: 'text',
            label: 'Code article',
            helper: {
                text: 'Code article du contenu',
                link: false,
            },
            
            required: true,
            stateName: 'sku',
            disabled: true,
        },
        // {
        //     type: 'text',
        //     label: 'Titre du contenu',
        //     helper: {
        //         text: 'Indiquez le nom du contenu ici',
        //         link: false,
        //     },
        //     translated: true,
        //     required: true,
        //     stateName: 'content_title',
        //     showInCard:true
        // },
        {
            type: 'selectTree',
            label: 'Catégories',
            translated: false,
            helper: {
                text: 'Modifier les catégories du contenu',
                link: false,
            },
            required: true,
            stateName: 'categories',
            multiselect: true,
            data: (() => {
                let data = categories.filter(e => e.parent === null && e.libelle === 'Root');

                let populateChildren = (cats, parent) => {
                    let catSelected = categoriesSelected.find(e => e.id === parent.id);
                    
                    let allNames    = parent.contentCategoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                    const defaultValue  = allNames.find(e => e.node.locale.code === currentLang);
                    const value         = allNames.find(e => e.node.locale.code === currentLang);

                    let finalValue = value?.node.value ?? defaultValue?.node.value ?? parent.libelle;


                    parent.value    = parent.id;
                    parent.label    = finalValue;
                    parent.checked  = catSelected ? true : false;

                    parent.expanded = true;
                    parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(categories, parent);

                return data;
            })()
        },
        // {
        //     type: 'selectTree',
        //     label: 'Catégories médias',
        //     translated: false,
        //     required: false,
        //     helper: {
        //         text: 'Catégories médias',
        //         link: false,
        //     },
        //     multiselect: true,
        //     stateName: 'selectedMediaCategories',
        //     data: (() => {
        //         let data = [];
                
        //         if (mediaCategories?.length > 0)
        //             data = mediaCategories.filter(e => e.node.parent === null && e.node.libelle === 'Root').map(e => e.node);

        //         let populateChildren = (cats, parent) => {
        //             let catSelected = selectedMediaCategories?.find(e => e.id === parent.id);
        //             parent.value  = parent.id;
        //             parent.label  = parent.libelle === 'Root' ? '/' : parent.libelle;
        //             parent.disabled = false;
        //             parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.id).map(e => e.node);
        //             parent.expanded         = true;
        //             parent.checked   = catSelected ? true : false;

        //             for (let child of parent.children)
        //                 populateChildren(cats, child);
        //         };

        //         for (let parent of data)
        //             populateChildren(mediaCategories, parent);

        //         return data;
        //     })()
        // },
        // {
        //     type: 'textarea',
        //     label: 'Description',
        //     helper: {
        //         text: 'Décrivez votre contenu',
        //         link: false,
        //     },
        //     translated: true,
        //     required: false,
        //     stateName: 'content_description',
        //     isContent: true,
        //     showInCard:hasDescription?true:false,
        //     height:"500px"
        // },
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: false,
            stateName: 'status',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
    ];
    
    if (hasTitle) {
        generalInputs.push({
            type: 'text',
            label: 'Titre du contenu',
            helper: {
                text: 'Indiquez le nom du contenu ici',
                link: false,
            },
            translated: true,
            required: true,
            stateName: 'content_title',
            showInCard:true
        })
    }
    if (hasDescription) {
        generalInputs.push({
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Décrivez votre contenu',
                link: false,
            },
            translated: true,
            required: false,
            stateName: 'content_description',
            isContent: true,
            showInCard:true,
            height:"500px"
        })
    }

    let imageInputs     = imageAttributes.map(attributeSetup);
    let metaInputs      = metaAttributes.map(attributeSetup);
    let customInputs    = customAttributes.filter(e => e.node.attributeType.input !== 'image').map(attributeSetup);

    let hasErrors = (inputs) => {
        for (let input of inputs) {
            if (errors[input.stateName])
                return true;
        }

        return false;
    };

    let children = [
        {
            labelName: 'Général',
            error: hasErrors(generalInputs),
            optionsInputs: generalInputs,
            showInSidebar:true
        },
        {
            labelName: 'Médias',
            isOptionnal: false,
            error: hasErrors(imageInputs),
            optionsInputs: imageInputs,
            showInSidebar:false
        }
    ];

    if(metaInputs.length){
        children.push({
            labelName: 'Métas',
            isOptionnal: false,
            error: hasErrors(metaInputs),
            optionsInputs: metaInputs,
            showInSidebar:true
        });
    }
    if(customInputs.length){
        children.push({
            labelName: 'Variables de modèle',
            isOptionnal: false,
            error: hasErrors(customInputs),
            optionsInputs: customInputs,
            showInSidebar:true
        })
    }

    var obj = {
        titleForm: 'Ajouter un contenu',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre contenu',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            seeErrors,
            children
        }
    };

    return obj;
}