import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import AccordionCustom from "../../Accordion/AccordionCustom";
import colors from "../../../../config/theme/colors";
import {  Grid, Typography } from "@material-ui/core";
import InputBuilder from "../../../ui/form/InputBuilder";
import { useState } from "react";
import { useEffect } from "react";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import styled from "styled-components";
import { Fragment } from "react";
import { GET_PRODUCTS_BY_SKUS } from "../../../../queries/products";
import CarouselProductsContent from "../../Carousel/CarouselProductsContent";

const CardContentDetails = props =>{
    const [imageOptions,setImagesOptions] =useState({})
    const [otherOptions,setOtherOptions] =useState([])
    const [productCarousel,setProductCarousel] =useState([])
    const [productCarouselReady,setProductCarouselReady] =useState(false)

    useEffect(()=>{        
        setImagesOptions(props.dataLayout.formConfig.children.find(e=>e.labelName === "Médias").optionsInputs.find(e=>e.stateName === "content_image"))
        setOtherOptions(props.dataLayout.formConfig.children.find(e=>e.labelName === "Général").optionsInputs.filter(e=>e.showInCard))
    },[props])

    useEffect(()=>{  
        getProducts()
    },[props?.refProductChanged,props?.allState?.currentLang])

    const getProducts = async ()=>{
        setProductCarousel([])
        setProductCarouselReady(false)
        let identifiers = props.dataLayout.formConfig.children.filter(e=>e.showInSidebar).find(e=>e.labelName === 'Variables de modèle')?.optionsInputs.filter(e=>e.type === "skuSelector")?.map(e=>e.stateName)
        let skus= []
        if (identifiers) {
            identifiers?.forEach((stateName,i)=>{
                if (props?.allState[props?.allState?.currentLang][stateName])
                skus.push(...props?.allState[props?.allState?.currentLang][stateName]?.map(e=>{return {title:e.title,products:e.products}}))
            })
        }
        let data = []
        if (skus?.length > 0){
            skus.forEach(async element => {
                if (element?.products?.length > 0) {
                    await props.client.query({
                        query: GET_PRODUCTS_BY_SKUS,
                        variables: { 
                            skus:element?.products
                        }
                      }).then( result => {                     
                        data.push({title : element?.title ,products:result.data.researchProducts?.edges})
                    })
                    setProductCarousel(data)
                    setProductCarouselReady(true)
                }
            })  
        }
        setProductCarouselReady(true)        
    }
    
    return (
        <AccordionCustom custompadding={"20px 20px 0 20px !important"} overflow={"scroll"} style={{height:"100%",overflow:"hidden"}} forcedExpanded title={'Contenu'}>  
        {
            !props.allState.ready ?
            <PageLoader/>
            :<>
            <div style={{
                width:'100%',
                display:"grid",
                gap:20,
                gridTemplateColumns: imageOptions && otherOptions?.length === 2 ?"1fr 1fr": "1fr"
            }}>
                {
                    imageOptions &&
                    <div style={{
                        display:"grid",
                        gridTemplateRows:"auto 1fr"
                    }}>
                        <div>
                            <Typography style={{fontSize:16,color:colors.black.regular,marginBottom:5}}>
                                {imageOptions?.label}
                            </Typography>
                        </div>
                        <div style={{minHeight: "350px"}}>
                        {imageOptions?
                        <InputBuilder
                            height={"100%"}
                            doubleRow={true}
                            value={
                                imageOptions.translated
                                    ? imageOptions.valueWithFlags
                                        ? props.allState[props.allState.currentLang][imageOptions.stateName]?.value
                                        : props.allState[props.allState.currentLang][imageOptions.stateName]
                                    : imageOptions.valueWithFlags
                                        ? props.allState[imageOptions.stateName]?.value
                                        : props.allState[imageOptions.stateName]
                            }                                 
                            allState={props.allState}
                            input={imageOptions}
                            border={true}
                            handleTreeChange={props.handleTreeChange}
                            expandNodes={props.expandNodes}
                            treeEditCat={props.treeEditCat}
                            copyCategories={props.copyCategories}
                            treeAddCat={props.treeAddCat}
                            errorCallback={props.errorCallback ? (error) => props.errorCallback(imageOptions.stateName, error) : () => { }}
                            stateCallback={(evt, custom) => props.stateCallback(imageOptions.stateName, evt, custom, imageOptions.translated)}
                            stateCallbackOriginal={props.stateCallback}
                            handleButtonGroupChange={props.handleButtonGroupChange
                                ? props.handleButtonGroupChange
                                : null}
                                gridItems={props.gridItems}
                                loadGrid={props.loadGrid}
                                addProducts={props.addProducts}
                                merchModal={props.merchModal}
                                resetReloadListing={props.resetReloadListing}
                                catalogDatas={props.catalogDatas}
                        />
                        :null}
                        </div>
                    </div>
                }
                <div>
                {
                otherOptions?.map((w,i)=>{                    
                    return (
                        <div key={i}>
                            <div>
                                <Typography style={{fontSize:16,color:colors.black.regular,marginBottom:5}} >
                                    {w.label}
                                </Typography>
                            </div>
                            <div className="card-content">
                                <InputBuilder
                                    value={
                                        w.translated
                                            ? w.valueWithFlags
                                                ? props.allState[props.allState.currentLang][w.stateName]?.value
                                                : props.allState[props.allState.currentLang][w.stateName]
                                            : w.valueWithFlags
                                                ? props.allState[w.stateName]?.value
                                                : props.allState[w.stateName]
                                    }
                                    allState={props.allState}
                                    input={w}
                                    handleTreeChange={props.handleTreeChange}
                                    expandNodes={props.expandNodes}
                                    treeEditCat={props.treeEditCat}
                                    copyCategories={props.copyCategories}
                                    treeAddCat={props.treeAddCat}
                                    errorCallback={props.errorCallback ? (error) => props.errorCallback(w.stateName, error) : () => { }}
                                    stateCallback={(evt, custom) => props.stateCallback(w.stateName, evt, custom, w.translated)}
                                    stateCallbackOriginal={props.stateCallback}
                                    handleButtonGroupChange={props.handleButtonGroupChange
                                        ? props.handleButtonGroupChange
                                        : null}
                                    gridItems={props.gridItems}
                                    loadGrid={props.loadGrid}
                                    addProducts={props.addProducts}
                                    merchModal={props.merchModal}
                                    resetReloadListing={props.resetReloadListing}
                                    catalogDatas={props.catalogDatas}
                                />
                            </div>
                        </div>
                    )
                })
            }
                </div>
            </div>
            {
                productCarouselReady ?
                productCarousel.length > 0&&
                    <Grid container style={{margin:"15px 0"}}>
                        <Grid item xs={12} style={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center"
                        }}>
                        </Grid>                        
                            {
                                productCarousel.map((c,i)=>{ 
                                    return (
                                        <Fragment key={i}>
                                            <Grid container item xs={12} style={{width:"100%",paddingBottom:"20px"}}>
                                                <Grid item xs={12} style={{paddingTop:"10px"}}>
                                                    <Typography style={{fontSize:16,color:colors.black.regular}}>{c.title}</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{paddingTop:"15px",width:"100%"}}>
                                                    <CarouselProductsContent index={i} products={c.products}/>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )
                                })                        
                            }                    
                        </Grid>       
                :<PageLoader/>

            }
            </>
        }            
        </AccordionCustom>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
        attributeGroups: state.attributeGroups
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(CardContentDetails)));
