import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_PRODUCTS_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import { PRODUCTS, PRODUCTS_PRODUCTS, CREATE, UPDATE } from '../../../../js/constants/constant-rights';
import {  ALLOWED_IN_PRODUCT } from '../../../../js/constants/medias-types';

import { ADD_PRODUCT, ADD_PRODUCT_DATA, UPDATE_PRODUCT } from '../../../../queries/products';
import { GET_CATEGORIES_LIGHT_2 } from '../../../../queries/categories';

import colors from '../../../../config/theme/colors';
import request from '../../../../js/utils/fetch';
import * as moment from "moment";

import { Grid, Typography } from '@material-ui/core';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardProductDetails from '../../../layouts/Card/cardContent/CardProductDetails';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import InputBuilder from '../../../ui/form/InputBuilder';
import formProductAdd from './config/formProductAdd.config';
import formVariantProductAdd from './config/formVariantProductAdd.config';

import DialogModal from '../../../ui/dialog/DialogModal';
import { checkRouting } from '../../../../js/utils/checkRouting';

class ProductAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            groupAttribut: '',
            customAttributes: [],
            attributesSelected: [],
            metaAttributes: [],
            imageAttributes: [],
            attributes: [],
            maxImageNumber: 0,
            imageSrc: [],
            productId: this.props.history.location?.state?.productId,
            sku: null,
            title: '',
            categories: [],
            categoriesData: [],
            nbCatalog: 0,
            description: '',
            status: '',
            metaTitle: '',
            metaDesc: '',
            openForm: false,
            openFormVariant: false,
            openDialog: false,
            allGroups: [],
            ready: false,
            errors: {},
            secondErrors: {},
            seeErrors: false,
            isVariant: false,
            variantsProduct: [],
            fetchedDatas: null,
            typeTesting: {
                type: 'product',
                testingState: ['sku'],
                testingTypingState: 'sku',
                identifierState: 'sku'
            },
        }
        this.typingTimer = null;
        this.typingSearchTimer = null;
    }

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;

        if (this.state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: typeTesting.identifierState, errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.' });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    handleToggleDrawer = (drawer) => {
        if (drawer === 'form') {
            this.setState({
                openForm: !this.state.openForm,
            });
        }
        if (drawer === 'addVariant') {
            this.setState({
                openFormVariant: !this.state.openFormVariant,
                // variantsProduct : this.state.variantsValidated,
            });
            if (this.state.variantsValidated?.values?.length > 0) {
                let copyVariantsValidated = [...this.state.variantsValidated.values]
                this.setState({
                    variantsProduct: copyVariantsValidated
                })
            }
        }
        this.setState({
            seeErrors: false
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    setValue = (stateName, value, custom, translated) => {
        if (typeof custom === 'string') {
            if (translated) {
                let values = this.state[this.state.currentLang];
                if (!values) {
                    values = {};
                }
                if (values[stateName]) {
                    if (!values[stateName]?.new) {
                        values[stateName][custom] = value;
                        values[stateName].updated = true;
                    } else {
                        values[stateName][custom] = value;
                        values[stateName].updated = true;
                    }
                } else {
                    values[stateName] = {
                        [custom]: value,
                    }
                }


                this.setState({
                    [this.state.currentLang]: values,
                });
            }

        } else {
            if (stateName === 'product_price' && value.includes(',')) {
                value = value.replace(',', '.');
            }
            if (translated) {
                let values = this.state[this.state.currentLang];

                if (!values) {
                    values = {};
                }
                if (values[stateName]) {
                    values[stateName].value = value;
                } else {
                    values[stateName] = {
                        value: value,
                    }
                }
                // values[stateName] = value;

                this.setState({
                    [this.state.currentLang]: values,
                });
            } else {
                this.setState({
                    [stateName]: value,
                }, async () => {
                    if (stateName === 'groupAttribut') {
                        await this.prepareAttributes();
                        this.prepareAttributeValues();
                    }
                });
            }
        }
        if (stateName === "sku")
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, custom, translated);
        if (stateName === 'groupAttribut') {
            this.setState({
                variantsValidated: ''
            })
        }
    };

    resetState() {
        this.setState({
            imageSrc: [],
            categories: [],
            errors: {}
        });
    }

    resetVariant = () => {
        this.setState({ variantsProduct: [] });
    }

    saveVariant = (drawer) => {
        this.handleToggleDrawer(drawer)
        let copyVariants = this.copy(this.state.variantsProduct)

        this.setState({
            variantsProduct: [],
            variantsValidated: {
                attributes: this.state.attributesSelected,
                values: copyVariants,
            }
        });
    }

    handleCancel = () => {
        this.resetState();
        this.initProduct();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    copy(array) {
        let newArray = [];

        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = (product, variants) => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);
            let getProductData = [];
            for (let attribute of attributes) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let isMedia = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';
                    if (formValue && isMedia) {
                        isMedia = true;
                    }

                    if (formValue && formValue.value) {

                        let variables = {
                            "attributeOption": attribute.node.attributeType.input === 'select' ? formValue.value : null,
                            "product": product,
                            "attribute": attribute.node.id,
                            "locale": locale.node.id,
                            "isLocked": formValue.isLocked,
                            "updatedAt": moment().format('YYYY-MM-DD'),
                        };

                        if (isMedia) {
                            if (variants) {
                                variants.image.data
                                    ? variables.media = formValue.value.id
                                    : variables.media = variants.image.id;
                            } else {
                                variables.media = formValue.value.id;
                            }
                        }


                        if (!isMedia)
                            if (variants) {
                                for (let newAttr of variants.allAttr) {
                                    if (attribute.node.id === newAttr.id) {
                                        variables.attributeOption = newAttr.values
                                    } else {
                                        if (attribute.node.attributeType.input !== 'select') {
                                            variables.value = formValue.value;
                                        }
                                    }
                                }
                                if (attribute.node.identifier === "product_price") {
                                    variables.value = variants.price.value ? variants.price.value : variants.price;
                                }
                            } else {
                                if (attribute.node.attributeType.input !== 'select') {
                                    variables.value = formValue.value;
                                }
                            }
                        let resultMutation = await this.props.client.mutate({
                            mutation: ADD_PRODUCT_DATA,
                            variables
                        })
                        getProductData.push(resultMutation.data.createProductData.productData)
                    }
                }
            }

            resolve(getProductData);
        });
    };

    getVariantForChildren = () => {
        return new Promise(async (resolve, reject) => {
            let values = this.state.variantsValidated.values
            let getChildren = []
            for (let i = 0; i < values.length; ++i) {
                let skuVariant = this.state.sku + '-variant' + (i + 1);
                let variables = {
                    'sku': skuVariant,
                    'attributeGroup': this.state.groupAttribut,
                    'categories': this.state.categories.map(e => parseInt(e.id.replace('/api/categories/', ''))),
                    'createdAt': moment().format('YYYY-MM-DD'),
                    'updatedAt': moment().format('YYYY-MM-DD'),
                    'status': this.state.status,
                };
                if (this.state.selectedProducts) {
                    variables.suggestions = this.state.selectedProducts;
                }
                const ADD_PRODUCT_VARIANT_RESULT = await this.props.client.mutate({
                    mutation: ADD_PRODUCT,
                    variables,
                });
                await this.saveAttributes(ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id, values[i]);
                let variantCreate = ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id
                getChildren.push(variantCreate)
            }
            resolve(getChildren);
        });
    };

    createVariant = () => {
        return new Promise(async (resolve, reject) => {
            let getChildren = await this.getVariantForChildren();
            resolve(getChildren);
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.initProduct();

        this.props.snack(ALERT_SUCCESS, 'Produit ajouté !');

        this.handleToggleDrawer('form');
        this.resetState();

        this.props.stopLoading();

        this.goTo(ROUTE_PRODUCTS_LIST);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleSecondFormError = (stateName, error) => {
        let secondErrors = this.state.secondErrors;

        secondErrors[stateName] = error;

        this.setState({ secondErrors })
    }

    hasErrors = (formError) => {
        if (formError === 'firstForm') {
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        } else {
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }


        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors('firstForm')) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let variables = {
                'sku': this.state.sku,
                'attributeGroup': this.state.groupAttribut,
                'categories': this.state.categories.map(e => parseInt(e.id.replace('/api/categories/', ''))),
                'createdAt': moment().format('YYYY-MM-DD'),
                'updatedAt': moment().format('YYYY-MM-DD'),
                'status': this.state.status,
                'superAttribute': this.state.isVariant ? this.state.variantsValidated.attributes.map(e => e.node.id) : [],
            };
            if (this.state.selectedProducts) {
                variables.suggestions = this.state.selectedProducts;
            }
            const ADD_PRODUCT_RESULT = await this.props.client.mutate({
                mutation: ADD_PRODUCT,
                variables
            });


            await this.saveAttributes(ADD_PRODUCT_RESULT.data.createProduct.product.id);
            if (this.state.isVariant) {
                const newVariants = await this.createVariant();
                await this.props.client.mutate({
                    mutation: UPDATE_PRODUCT,
                    variables: {
                        'id': ADD_PRODUCT_RESULT.data.createProduct.product.id,
                        'children': newVariants
                    }
                })
                this.handleSuccess();
            }

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    };

    rightButtonCallback = (status) => {
        if (status === 'open') {
            this.getFetchedDatas()
            this.setState({
                readyFetched: false
            })
        }
        this.setState({
            openDialog: !this.state.openDialog,
        })
    };

    resetFetchedDatas = () => {
        this.setState({
            fetchedDatas: null
        })
    };

    getFetchedDatas = () => {
        let data = new FormData();
        data.append('barcode', this.state[this.state.currentLang].product_ean);
        request(`${process.env.REACT_APP_API}/products/ean/check`, 'post', data, 'multipart/form-data').then(async (data) => {
            if (data.success) {
                let product = data.products?.products[0];
                if (product) {
                    let getName = product['product_name']
                    let getDescription = product['description']
                    let getImages = product['images']
                    this.setState({
                        fetchedDatas: {
                            name: getName,
                            description: getDescription,
                            image: getImages[0],
                        },
                    })
                }
            }
            this.setState({
                readyFetched: true,
            })
        });
    };

    handleAutocomplete = () => {
        this.setValue('product_name', this.state.fetchedDatas.name, true);
        this.setValue('product_description', this.state.fetchedDatas.description, true);
        let data = new FormData();
        data.append('url', this.state.fetchedDatas.image);
        request(`${process.env.REACT_APP_API}/media-objects-from-url`, 'post', data, 'multipart/form-data')
            .then(async (data) => {
                if (data.success) {
                    this.setValue('product_image', data.mediaObject, true);
                }
            });
        this.rightButtonCallback();
        this.resetFetchedDatas();
    };


    componentDidMount() {
        checkRouting(this.props);
        this.initProduct();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.groupAttribut !== prevState.groupAttribut) {
            for (let customAttr of prevState.customAttributes) {
                delete this.state.errors[customAttr.node.identifier];
            }
        }
    }

    render() {
        const { sku, status, ready } = this.state;

        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value: this.props.locales.map((locale) => {
                return (
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
        };

        if (!ready)
            return <PageLoader />;

        return (
            <div>
                <Grid container direction='column' style={{ width: true ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item xs={5}>
                            <Grid container justifyContent={'flex-end'}>
                                <InputBuilder value={this.state.currentLang} input={selectLang} stateCallback={this.handleLang} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <CardCustom style={{ marginTop: 8,width:"100%" }}>
                        {
                            this.state.ready ? (
                                <CardProductDetails
                                    sku={sku}
                                    product={{ status }}
                                    routeProduct={() => this.goTo(ROUTE_PRODUCTS_LIST)}
                                    categories={this.state.categories}
                                    toggleDrawer={this.handleToggleDrawer}
                                    openForm={this.state.openForm}
                                    imageAttributes={this.state.imageAttributes}
                                    customAttributes={this.state.customAttributes}
                                    currentLang={this.state.currentLang}
                                    allState={this.state}
                                    locales={this.props.locales}
                                    creation={true}
                                    selectedProducts={this.state.selectedProducts}
                                    smallCard={true}
                                    scrappingButton={false}
                                />
                            ) : null
                        }
                        <LayoutBuilder
                            isSublayout={true}
                            scrollable={true}
                            isProtected={false}
                            validateButton={true}
                            //handleCancel={this.handleCancel}
                            opened={this.state.openForm}
                            forClose={() => { this.goTo(ROUTE_PRODUCTS_LIST) }}
                            handlerMutation={this.handlerMutation}
                            icomoon={'ico-ajouter-produit'}
                            //noCancel={true}
                            useLocking={true}
                            dataLayout={formProductAdd(
                                this.state.currentLang,
                                this.state.categoriesData,
                                this.state.customAttributes,
                                this.state.metaAttributes,
                                this.state.imageAttributes,
                                this.state.categories,
                                this.state.allGroups,
                                this.state.errors,
                                this.state.seeErrors,
                                this.handleMediaPicker,
                                this.state.isVariant,
                                this.handleToggleDrawer,
                                this.state.variantsValidated,
                                this.state.isVariant,
                                () => this.rightButtonCallback('open'),
                                ALLOWED_IN_PRODUCT,
                                this.state.hasPrice
                            )}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            allState={this.state}
                            stateCallback={this.handleInputChange}
                            errorCallback={this.handleFormError}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            drawerWidth={this.props.drawerWidth}
                            deleteButton={false}
                        />
                        <LayoutBuilder
                            scrollable={true}
                            isSublayout={false}
                            validateButton={true}
                            handleCancel={this.resetVariant}
                            opened={this.state.openFormVariant}
                            forClose={() => this.handleToggleDrawer('addVariant')}
                            handlerMutation={() => this.saveVariant('addVariant')}
                            icomoon={'ico-modifier-produit'}
                            //noCancel={true}
                            dataLayout={formVariantProductAdd(
                                this.state.currentLang,
                                // this.state.categoriesData, 
                                this.state.customAttributes,
                                this.state.attributesSelected,
                                this.state[this.state.currentLang]?.product_price,
                                this.state.variantsValidated,
                                this.state.imagesSelected ? this.state.imagesSelected[0]?.original : '',
                                this.handleMediaPicker,
                                this.state.hasPrice
                            )}
                            backStepperButtonAction={[
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                null
                            ]}
                            stepperButtonDisabled={[null, () => this.state.variantsProduct.length === 0]}
                            stepperButtonAction={[
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                },
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                },
                                null,
                            ]}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            allState={this.state}
                            stateCallback={this.handleInputChange}
                            errorCallback={this.handleSecondFormError}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            drawerWidth={this.props.drawerWidth}
                            deleteButton={false}
                        />
                    </CardCustom>
                </Grid>

                <DialogModal
                    open={this.state.openDialog}
                    title={`Voulez vous compléter ces champs produits ?`}
                    primaryAction={this.state.fetchedDatas ? () => { this.handleAutocomplete(); this.resetFetchedDatas() } : null} primarybgcolor={colors.green.regular} primarybgcolorhover={colors.green.darker} primaryText="Compléter les champs"
                    secondaryAction={() => { this.rightButtonCallback(); this.resetFetchedDatas() }} secondarycolor={colors.grey.regular} secondarybgcolor={colors.white} secondarybgcolorhover={colors.grey.lighter.hue900} secondaryborder={`1px solid ${colors.grey.regular}`}
                    windowWidth={this.props.windowWidth}
                >
                    {
                        this.state.readyFetched ?
                            this.state.fetchedDatas ?
                                <Grid container>
                                    <Grid container justifyContent="center">
                                        <img src={this.state.fetchedDatas.image} style={{ maxHeight: 250 }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h4'>Titre du produit:</Typography>
                                        <Typography variant='body1'>{this.state.fetchedDatas.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h4'>Description:</Typography>
                                        <Typography variant='body1'>{this.state.fetchedDatas.description}</Typography>
                                    </Grid>
                                </Grid>
                                : <Typography>Pas de données pour ce code EAN.<br /> Utilisez un code EAN valide afin de remplir les champs produits</Typography>
                            : <PageLoader />
                    }
                </DialogModal>
            </div>
        );
    }

    prepareAttributes() {
        return new Promise(async (resolve, reject) => {
            let group = this.state.allGroups.find(e => e.node.id === this.state.groupAttribut);

            let isSystemAttributes = this.props.attributes.product.attributes.edges.filter(e => e.node.isSystem);
            let metaAttributes = this.props.attributes.product.attributes.edges.filter(e => e.node.isSystem && e.node.identifier.indexOf('meta') > -1);
            let customAttributes = group.node.attributes.edges.filter(e => !e.node.isSystem);
            let hasPrice = group.node.attributes.edges.find(e => e.node.identifier === "product_price") ? true : false;
            let imageAttributes = isSystemAttributes.filter(e => e.node.attributeType.input === 'image')
                .concat(customAttributes.filter(e => e.node.attributeType.input === 'image'));

            this.setState({
                isSystemAttributes,
                metaAttributes,
                customAttributes,
                imageAttributes,
                hasPrice,
                maxImageNumber: imageAttributes.length,
            });

            resolve();
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};

            for (let attribute of this.state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length) {
                        values[attribute.node.identifier] = {
                            value: attribute.node.isRequired ? attribute.node.attributeOptions.edges[0].node.id : null
                        }
                    }
                }
            }

            this.setState({
                [locale.node.code]: values
            });
        }
    }

    initProduct() {
        this.setState({
            sku: null,
            groupAttribut: this.props.attributeGroups?.find(e => e.node.identifier === 'default')?.node.id,
            categories: [], // product categories
            allGroups: this.props.attributeGroups?.filter(e => (!e.node.isSystem) || e.node.identifier === 'default'),
            attributes: [],
            status: true
        }, async () => {
            await this.prepareAttributes();

            this.prepareAttributeValues();

            const GET_CATEGORIES_RESULT = await this.props.client.query({
                query: GET_CATEGORIES_LIGHT_2,
                variables: {
                    "exists": [{
                        "catalog": false
                    }]
                },
                fetchPolicy: "no-cache"
            });

            this.handleToggleDrawer('form');

            this.setState({ categoriesData: GET_CATEGORIES_RESULT.data.categories, ready: true }); // all categories
        });
    }

    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductAdd)));
