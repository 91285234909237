import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import colors from "../../../config/theme/colors";
import IcomoonReact from "icomoon-react";
import iconSet from "../../../assets/selection.json";

const OurReturnLink = (props) => {
  return (
    <button
      type="button"
      onClick={props.goTo || props.history.goBack}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        color: colors.black.regular,
        border: `1px solid ${colors.grey.border}`,
        backgroundColor: "white",
        padding: "5px",
        margin: 0,
        ...props.style,
      }}
    >
      <IcomoonReact
        iconSet={iconSet}
        color={colors.black.regular}
        size={23}
        icon={"ico_go_back"}
      />
    </button>
  );
};

export default withTranslation()(withRouter(OurReturnLink));
