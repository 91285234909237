import { withTranslation } from "react-i18next";
import { Radio, Divider, TextField, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { t } from "i18next";

export const TextFieldCustom = styled(TextField)`
  .MuiInputBase-root {
    cursor: pointer;
  }
  input,
  .MuiSelect-select {
    height: 35px;
    padding: 8px 30px 8px 10px;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 0;
    border-color: ${colors.grey.border};
    @media screen and (max-width: 1450px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 13px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 13px;
    }
  }
  & > p {
    margin: 0;
  }
  .MuiInputBase-root {
    background-color: white;
    border: none;
  }
  svg {
    fill: ${colors.black.regular};
    top: auto;
    transition: all 0.2s ease;
  }
  fieldset {
    border-color: ${colors.grey.border} !important;
    border-width: 1px !important;
  }
  &:hover {
    fieldset {
      border-color: ${colors.grey.border} !important;
    }
  }
  .MuiSelect-select:focus {
    background-color: #fff !important;
  }
`;

const MenuItemCustom = styled(MenuItem)`
  background-color: ${(props) =>
    props.active && !props.radioActive
      ? colors.blue.lighter.hue900
      : "#FFF"} !important;
  color: ${(props) =>
    props.active ? colors.blue.darker.hue300 : "#000"} !important;
  font-weight: ${(props) =>
    props.active || props.radioActive ? "bold" : "initial"};
  display: flex;
  gap: 5px;
  font-size: 14px;
  padding: ${(props) =>
    props.view ? "0.5rem 1rem 0.5rem 0.75rem" : null} !important;
  &:hover {
    background-color: ${colors.blue.lighter.hue900} !important;
    color: ${colors.blue.darker.hue300} !important;
  }
`;

const RadioCustom = styled(Radio)`
  svg {
    fill: ${colors.blue.darker.hue300} !important;
    width: 20px;
    height: 20px;
  }
`;

const ChangeView = (props) => {
  const { config, pagination, setPagination } = props;

  const handleView = (view) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        viewMode: view,
      };
    });
  };

  const handlePerPage = (perPage) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        itemsPerPage: perPage,
        page: 1,
      };
    });
  };

  return (
    <div>
      <TextFieldCustom
        variant="outlined"
        color="secondary"
        select
        fullWidth
        SelectProps={{
          renderValue: () => <span>{t("common.view")}</span>,
          IconComponent: (props) => (
            <ExpandMoreOutlined style={{ fontSize: "20px" }} {...props} />
          ),
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            elevation: 0,
            MenuListProps: {
              style: {
                padding: 0,
              },
            },
            PaperProps: {
              square: true,
              style: {
                border: `1px solid ${colors.grey.border}`,
              },
            },
          },
        }}
        value={pagination?.viewMode}
        onChange={(evt) => {
          if (
            config?.views.options?.find((e) => e.value === evt.target.value) &&
            evt.target.value !== pagination?.viewMode
          )
            handleView(evt.target.value);
          if (
            config?.perPage?.options.find(
              (e) =>
                e === evt.target.value &&
                evt.target.value !== pagination?.itemsPerPage
            )
          )
            handlePerPage(evt.target.value);
        }}
      >
        {config?.views &&
          config?.views?.options?.length > 1 &&
          config?.views.options?.map((option, index) => {
            return (
              <MenuItemCustom
                view
                key={`OptionSelect${index}`}
                value={option.value}
                radioActive={pagination?.viewMode === option.value}
              >
                <RadioCustom
                  size={"medium"}
                  disableRipple
                  checked={pagination?.viewMode === option.value}
                  color={
                    pagination?.viewMode === option.value
                      ? colors.blue.darker.hue300
                      : colors.black.regular
                  }
                  style={{
                    padding: 0,
                  }}
                />
                {t(option.label)}
              </MenuItemCustom>
            );
          })}
        {config?.views && config?.views?.options?.length > 1 && <Divider />}
        {config?.perPage?.options?.map((option, index) => {
          return (
            <MenuItemCustom
              key={`OptionSelect${index}`}
              value={option}
              active={pagination?.itemsPerPage === option}
            >
              {option}
            </MenuItemCustom>
          );
        })}
      </TextFieldCustom>
    </div>
  );
};

export default withTranslation()(ChangeView);
