import { Chip } from "@material-ui/core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const ChipCustom = (props) => {
  const { index, value } = props;
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: "Chip",
    item: { type: "Chip", value, index },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const [, drop] = useDrop({
    accept: "Chip",
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      item.index = hoverIndex;
      props.move(dragIndex, hoverIndex);
    },
  });

  drag(drop(ref));
  return (
    <Chip
      component={"div"}
      className={props.className}
      key={props.key}
      label={<div style={{display:"flex",alignItems:"center"}}><DragIndicatorIcon fontSize="small"/>{props.label}</div>}
      ref={ref}
      isFocused={props.isFocused}
      onClick={props.onClick}
      onDelete={props.onDelete}
      size="small"
      style={props.style}
    />
  );
};

export default ChipCustom;
