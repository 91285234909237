import { Box, Grid, Typography } from "@material-ui/core"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import styled from "styled-components"
import colors from "../../../config/theme/colors"
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../assets/selection.json";
import OurTypography from "../typography/Typography"

const ReturnLink = styled(Box)`
  cursor: pointer;
  display: flex;
  display: inline-block;
`

const ReturnLayout = styled(Grid)`
  gap: 5px;
`

const OurReturnLink = (props) => {
  return (
    <ReturnLink variant={'body2'} onClick={props.goTo}>
      <ReturnLayout container alignItems="center">
        <IcomoonReact iconSet={iconSet} color={colors.black.regular} size={props.windowWidth < 1400 ? 20 : 24} icon={"ico_go_back"} />
        <OurTypography text={props.t("spread.active_assets.return")} style={{ fontSize: '16px' }} colortext={colors.black.regular} fontweighttext={900} />
      </ReturnLayout>
    </ReturnLink>
  )
}

export default withTranslation()(withRouter(OurReturnLink))