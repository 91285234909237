export const attributeSetup = (attribute, currentLang) => {
  let typeInput = "";
  let isEmail = false;
  const defaultLang = attribute.node.translation.translationDatas[0];

  const langSelected = attribute.node.translation.translationDatas.find(
    (lang) => lang.locale.code === currentLang
  );

  switch (attribute.node.attributeType.input) {
    case "textarea":
      typeInput = "textarea";
      break;
    case "number":
    case "decimal":
      typeInput = "decimal";
      break;
    case "text":
      typeInput = "text";
      break;
    case "mail":
      typeInput = "text";
      isEmail = true;
      break;
    case "link":
      typeInput = "text";
      break;
    case "select":
      typeInput = "select";
      break;
    case "image":
      typeInput = "mediaPicker";
      break;
    case "file":
      typeInput = "file";
      break;
    case "date":
      typeInput = "date";
      break;
    default:
      typeInput = null;
  }
  return {
    type: typeInput,
    label: langSelected?.value ?? defaultLang.value,
    translated: false,
    helper: {
      link: false,
    },
    isSystem: false,
    currentLang,
    stateName: attribute.node.identifier,
    email: isEmail,
    value:
      attribute.node?.attributeOptions?.edges ||
      [].map((values) => {
        const langSelectedValuesDefault =
          values.node.translation.translationDatas[0];

        const langSelectedValues =
          values.node.translation.translationDatas.find(
            (lang) => lang.locale.code === currentLang
          );

        return {
          value: values.node.id,
          label: langSelectedValues?.value ?? langSelectedValuesDefault?.value,
        };
      }),
  };
};
