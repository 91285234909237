import { useHistory, withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "../../../../js/constants/action-types";
import { spaceOnNumbers } from "../../../../js/utils/spaceOnNumbers";
import { config } from "./config/listContents.config";
import { ROUTE_CONTENU_ADD } from "../../../../js/constants/route-names";
import importProductsConfig from "./config/importProducts.config";
import request from "../../../../js/utils/fetch";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
} from "../../../../js/constants/alert-types";
import DialogModal from "../../../../components/ui/dialog/DialogModal";
import {
  DELETE_CONTENT,
  SEARCH_CONTENTS_SKU_BY_CAT,
} from "../../../../queries/contents";
import { Box, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import BreadcrumbCustom from "../../../../components/ui/breadcrumb/Breadcrumb";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import FolderSharpIcon from "@material-ui/icons/FolderSharp";
import ListingNew from "../../../layouts/Listing/ListingNew";
import TopPanel from "../../../layouts/TopPanel/TopPanel";
import LayoutFormBuilder from "../../../ui/form/LayoutFormBuilder";
import PlaceholderModel from "../../../../assets/pictos/icono-tempalte.svg";
import OurButton from "../../../ui/button/Button";
import { CARD } from "../../../../js/constants/form-types";

const FolderContainer = styled(Grid)`
  display: grid;
  grid-template-columns: ${(props) =>
    props.windowWidth > 1500
      ? "repeat(auto-fill, minmax(20%, 1fr))" // 4 columns
      : props.windowWidth > 765
      ? "repeat(auto-fill, minmax(25%, 1fr))" // 3 columns
      : "repeat(auto-fill, minmax(33%, 1fr))"}; // 2 columns
  width: 100%;
  gap: 17px;
  margin-top: 9px;
`;

const FolderCustom = styled(Grid)`
  background-color: ${colors.white};
  border: 0.5px solid ${colors.grey.lighter.hue700};
  padding: 10px 15px;
  color: ${colors.black.regular};
  font-weight: bold;
  height: 52px;
  gap: 10px;
  cursor: pointer;
  "&:hover": {
    background-color: ${colors.grey.lighter.hue900};
  }
`;

const ContentsNew = (props) => {
  const {
    t,
    windowWidth,
    locales,
    startLoading,
    stopLoading,
    snack,
    client,
    user,
  } = props;
  const history = useHistory();

  const [count, setCount] = useState(0);
  const langStorage = user?.preferredLangcode || "fr_FR";
  const [lang, setLang] = useState(langStorage);
  const [refresh, setRefresh] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  // Folders navs
  const [categories, setCategories] = useState([]);
  const [categorieIsLoaded, setCategoriesLoaded] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [previousFolders, setPreviousFolders] = useState([]);
  const [skuListIsLoaded, setSkuListIsLoaded] = useState(false);
  const [skuList, setSkuList] = useState([]);
  const [error, setError] = useState(false);

  // Models
  const [models, setModels] = useState([]);
  const [openFormModel, setOpenFormModel] = useState(false);

  const [state, setState] = useState({
    importFile: null,
  });

  const stateCallback = (stateName, value, custom, translated, callback) => {
    setState(
      {
        ...state,
        [stateName]: value?.target?.value ?? value,
      },
      callback
    );
  };

  const listingCallback = (count) => {
    if (count) setCount(count);
  };

  const handleGetCategories = () => {
    setCategoriesLoaded(false);
    return new Promise((resolve, reject) => {
      request(
        `${process.env.REACT_APP_API}/categories-list/content`,
        "get"
      ).then((data) => {
        if (data.success && data.datas.length > 0) {
          setCategories(data.datas);
          setCategoriesLoaded(true);
        } else {
          setError(true);
        }
        resolve();
      });
    });
  };

  const saveCategoryOnLocalStorage = () => {
    if (currentFolder) {
      const objectToSave = {
        currentFolder,
        previousFolders,
      };
      localStorage.setItem("CONTENT_CATEGORY", JSON.stringify(objectToSave));
    } else {
      localStorage.removeItem("CONTENT_CATEGORY");
    }
  };

  // Click on breadcrumb
  const getClickedCategory = (id) => {
    if (id) {
      const categoryIndex = previousFolders.findIndex(
        (folder) => folder?.id === id
      );
      setCurrentFolder(previousFolders[categoryIndex]);
      setPreviousFolders(previousFolders.slice(0, categoryIndex));
    } else {
      setCurrentFolder(null);
      setPreviousFolders([]);
    }
  };

  // Click on Folder
  const getPreviousFolders = (category) => {
    if (previousFolders.length <= 1) {
      const parentCategory = categories.find(
        (folder) => folder.id === category.parent
      );
      if (parentCategory) {
        previousFolders.push(parentCategory);
      } else {
        previousFolders.push(null);
      }
    } else {
      previousFolders.push(currentFolder);
    }
  };

  const getSkuList = () => {
    setSkuListIsLoaded(false);
    props.client
      .query({
        query: SEARCH_CONTENTS_SKU_BY_CAT,
        variables: {
          idlist: currentFolder.id,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let list = [];
        if (result?.data?.contentCategories[0]?.contents?.edges?.length === 0) {
          list = [];
        } else {
          result?.data?.contentCategories[0]?.contents?.edges?.map((item) => {
            list.push(item.node.sku);
          });
        }
        setSkuList(list);
        setSkuListIsLoaded(true);
      });
  };

  const handlerMutation = async () => {
    startLoading();

    const importConfig = {
      url: `${process.env.REACT_APP_API_ROOT}${
        this.state.media?.contentUrl ?? null
      }`,
      mapper: this.state.mapper,
      eavType: this.props.attributes.eavTypes.find(
        (e) => e.node.code === "content"
      ).node.id,
      locale: this.state.importLang,
      delimiter: this.state.importSep,
    };

    try {
      await request(
        `${process.env.REACT_APP_API_ROOT}/api/file-imports`,
        "post",
        importConfig,
        undefined,
        true
      );
      this.props.snack(
        ALERT_SUCCESS,
        this.props.t("content.content.importMediaSuccess"),
        6000
      );
      this.getAll();
      this.handleToggleDrawer("openForm");
      this.handleToggleDrawer("openFormImport");
      this.props.stopLoading();
    } catch (e) {
      this.props.snack(
        ALERT_ERROR,
        props.t("content.content.importMediaFailed")
      );
      this.props.stopLoading();
    }
  };

  const deleteMutation = () => {
    let query = null;
    let variables = null;

    startLoading();

    query = DELETE_CONTENT;
    variables = { id: idToDelete };

    request(
      `${
        process.env.REACT_APP_API
      }/wordpress/content/delete/${idToDelete.replace("/api/contents/", "")}`,
      "get"
    );

    client
      .mutate({
        mutation: query,
        variables,
      })
      .then((result) => {
        getSkuList();
        setRefresh(true);
        setDeleteModal(false)
        snack(ALERT_SUCCESS, "Le contenu a bien été supprimé", 6000);
        stopLoading();
      })
      .catch((e) => {
        console.log(e)
        snack(ALERT_ERROR, "Une erreur est survenue", 6000);
        stopLoading();
      });
  };

  const skuQueryVars = skuList.reduce((acc, sku, index) => {
    acc[`sku[${index}]`] = sku;
    return acc;
  }, {});

  const handleDragNDrop = async (e) => {
    const updatedData = e.map((item, index) => {
      return {
        id: item.replace("/api/contents/", ""),
        position: index,
      };
    });

    try {
      await request(`${process.env.REACT_APP_API}/contents/order`, "POST", {
        orders: updatedData,
      });
      setRefresh(true);
    } catch (error) {
      snack("error", "Une erreur est survenue lors du déplacement du contenu");
      console.error(error);
    }
  };

  useEffect(() => {
    const savedCategory = JSON.parse(localStorage.getItem("CONTENT_CATEGORY"));

    // If user clicks on left drawer menu it will reset the currentFolder and previousFolders else it goes back to the previous state
    if (props.history.action !== "POP") {
      setCurrentFolder(null);
      setPreviousFolders([]);
    } else if (savedCategory) {
      setCurrentFolder(savedCategory.currentFolder);
      setPreviousFolders(savedCategory.previousFolders);
    }
  }, []);

  useEffect(() => {
    saveCategoryOnLocalStorage();
    if (!currentFolder) {
      setSkuList([]);
      handleGetCategories();
    } else {
      getSkuList();
      const models = props.attributeGroups.filter(
        (e) =>
          (!e.node.isSystem && e.node.isForContent) ||
          e.node.identifier === "content"
      );
      const getAttributesList = (e) => {
        return `Ajouter un contenu comprenant : ${e?.node?.attributes?.edges?.map(
          (item) => {
            return ` ${item?.node?.translation?.translationDatas?.edges[0]?.node?.value?.toLowerCase()}`;
          }
        )}`;
      };
      const newModels = models.map((e) => {
        return {
          libelle: e.node.translation.translationDatas.edges[0]?.node.value,
          picto: PlaceholderModel,
          textButton: "Créer le contenu",
          description: getAttributesList(e),
          onClick: () =>
            history.push(ROUTE_CONTENU_ADD, {
              model: e.node.id,
              currentFolder: currentFolder,
            }),
        };
      });
      setModels(newModels);
    }
  }, [currentFolder]);

  return (
    <div>
      <TopPanel
        title={`${t("content.content.manageContents")} (${spaceOnNumbers(
          count
        )})`}
        subtitle={props.t("content.content.manageContentSubtitle")}
        windowWidth={windowWidth}
        locales={locales}
        handlerAdd={() => setOpenFormModel(!openFormModel)}
        textAdd={props.t("content.content.addContent")}
        openForm={openForm}
        handlerImport={() => setOpenForm(!openForm)}
        textImport={props.t("content.content.importContent")}
        buttonAvailable={currentFolder && categorieIsLoaded ? true : false}
        hasBorder={true}
      />

      <div style={{ padding: 15 }}>
        {/* Breadcrumb */}
        <Grid item xs={12} style={{ marginBottom: 18, marginTop: 18 }}>
          {categories.length > 0 ? (
            <BreadcrumbCustom
              currentFolder={currentFolder}
              previousFolders={previousFolders}
              rootName={"Tous les contenus"}
              windowWidth={props.windowWidth}
              getClickedCategory={getClickedCategory}
              nbPreviousItems={4}
              separator={<ArrowForwardIosSharpIcon fontSize="small" />}
              size={25}
            />
          ) : null}
        </Grid>

        {/* Folders */}
        <Box style={{ paddingBottom: 15 }}>
          <Grid item xs={12}>
            <Typography
              style={{
                color: colors.black.regular,
                fontSize: "20px",
              }}
            >
              {categories.length > 0
                ? currentFolder
                  ? "Liste des contenus du template"
                  : "Liste des templates"
                : null}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {categorieIsLoaded ? (
              <FolderContainer container windowWidth={props.windowWidth}>
                {(!currentFolder
                  ? // Root
                    categories && categories.length > 0
                    ? categories
                    : []
                  : // Subfolders
                    currentFolder.childrens
                )?.map((category, i) => {
                  const title = category?.categoryDatas?.length
                    ? category.categoryDatas.find(
                        (data) => data?.attribute === "category_name"
                      )?.value ?? category.libelle
                    : category.libelle;

                  return (
                    <FolderCustom
                      style={{
                        display: "flex",
                        height: "80px",
                        padding: "0px 25px",
                      }}
                      key={`cat-${category.id}`}
                      alignItems={"center"}
                      onClick={() => {
                        getPreviousFolders(category);
                        setCurrentFolder(category);
                      }}
                    >
                      <Grid item>
                        <FolderSharpIcon
                          style={{ marginTop: "3px", fontSize: "28px" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          fontSize: "20px",
                        }}
                      >
                        {title}{" "}
                        {category?.contentsTotal !== 0
                          ? `(${category?.contentsTotal})`
                          : null}
                      </Grid>
                    </FolderCustom>
                  );
                })}
              </FolderContainer>
            ) : null}
          </Grid>
        </Box>

        {currentFolder ? (
          skuListIsLoaded ? (
            skuList.length > 0 ? (
              <ListingNew
                // restUrl="contents"
                label="contents"
                config={config}
                currentLang={lang}
                setCurrentLang={setLang}
                listingCallback={listingCallback}
                identifier={"contents"}
                refresh={refresh}
                setRefresh={setRefresh}
                dragCallback={handleDragNDrop}
                queryVariables={{
                  skulist: skuList,
                  order: "asc",
                }}
                cardProps={{
                  deleteContent: (id) => {
                    setDeleteModal(true);
                    setIdToDelete(id);
                  },
                }}
                tableProps={{
                  deleteContent: (id) => {
                    setDeleteModal(true);
                    setIdToDelete(id);
                  },
                }}
              />
            ) : currentFolder?.childrens.length === 0 ? (
              <Typography
                style={{
                  margin: "0",
                }}
              >
                Aucun résultats
              </Typography>
            ) : null
          ) : null
        ) : null}
      </div>
      <LayoutFormBuilder
        isSublayout={true}
        opened={openFormModel}
        icomoon={"ico-import-fichier"}
        forClose={() => setOpenFormModel(false)}
        dataLayout={{
          langSelect: false,
          drawerType: "swipeable",
          noForm: true,
          component: OurButton,
          formConfig: {
            type: CARD,
          },
        }}
        dataCard={models}
        // drawerWidth={props.drawerWidth}
        // handleCancel={handleCancel}
        handlerMutation={handlerMutation}
        allState={state}
        stateCallback={stateCallback}
        // stepperButtonDisabled={[() => state.headers === null, null]}
        stepperButtonAction={[null, null]}
        backStepperButtonAction={[null, null, null]}
      />
      <LayoutFormBuilder
        isSublayout={true}
        opened={openForm}
        icomoon={"ico-import-fichier"}
        forClose={() => setOpenForm(false)}
        dataLayout={importProductsConfig}
        // drawerWidth={props.drawerWidth}
        // handleCancel={handleCancel}
        handlerMutation={handlerMutation}
        allState={state}
        stateCallback={stateCallback}
        // stepperButtonDisabled={[() => state.headers === null, null]}
        stepperButtonAction={[null, null]}
        backStepperButtonAction={[null, null, null]}
      />
      <DialogModal
        open={deleteModal}
        type="delete"
        title={t("content.delete_confirm")}
        icon
        primaryAction={deleteMutation}
        secondaryAction={() => setDeleteModal(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    attributeGroups: state.attributeGroups,
    locales: state.locales,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentsNew))
  )
);
