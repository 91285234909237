export const contentsCardMapper = async (contents, lang) => {
  const results = await contents.map((content, index) => {
    return {
      // REST API contents
      // id: content["@id"],
      // title: content.sku,
      // status: content.status,
      // image: null,
      // categorie: content.contentCategories[0],
      
      // GraphQL query contents
      id: content.node.id,
      image: content.node.contentDatas.edges.find(
              (e) => e.node.attribute.identifier === "content_image"
            )?.node.media?.filePath
      ? `${process.env.REACT_APP_MEDIAS}/${content.node.contentDatas.edges.find(
              (e) => e.node.attribute.identifier === "content_image"
            )?.node.media?.filePath}`
      : null,
      title: content.node.contentDatas.edges?.[0]?.node.value,
      status: content.node.status,
      categorie: content.node.contentCategories[0]?.contentCategoryDatas?.edges?.find(e=>e.node.attribute.identifier === "category_name")?.node?.value || content.node.contentCategories[0]?.libelle,
    };
  });
  return results;
};

export const contentsListMapper = async (contents, lang) => {
  const results = await contents.map((content, index) => {
    return {
      // REST API contents
      // id: content["@id"],
      // title: content.sku,
      // status: content.status,

      // GraphQL query contents
      id: content.node.id,
      title: content.node.contentDatas.edges?.[0]?.node.value,
      status: content.node.status,
      categorie: content.node.contentCategories[0]?.contentCategoryDatas?.edges?.find(e=>e.node.attribute.identifier === "category_name")?.node?.value || content.node.contentCategories[0]?.libelle,
      image: content.node.contentDatas.edges.find(
              (e) => e.node.attribute.identifier === "content_image"
            )?.node.media?.filePath
        ? `${process.env.REACT_APP_MEDIAS}/${content.node.contentDatas.edges.find(
              (e) => e.node.attribute.identifier === "content_image"
            )?.node.media?.filePath}`
        : null,
    };
  });
  return results;
};
