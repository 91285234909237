import {  Box, Grid, Modal } from "@material-ui/core"
import React, {  useRef, useState } from "react"
import styled from "styled-components"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PageLoader from "../../ui/loadings/page-loader/PageLoader";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { SNACK } from '../../../js/constants/action-types';
import { connect } from "react-redux";
import { ALERT_SUCCESS } from "../../../js/constants/alert-types";
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../assets/selection.json";
import colors from "../../../config/theme/colors";
import NoImage from "../../../assets/images/image-fake-card.png"

const CarouselContainer = styled(Grid)`
     * {
      outline: none !important;
    } 
    .slick-slider{
        width:100%;
        // margin: 0 -10px;
    }    
    .slick-slide {        
        outline: none;
        >div{
          padding: 0 5px;
        }
    }
    .slick-list {        
      margin: 0 -5px;
  }
    .slick-track{
      margin-left:0;
    }
`
const ContainerSliderModal = styled.div`
  &:focus-visible {
    outline: none !important;
  }
  *:focus,*:focus-visible {
    outline: none !important;
  }
`

const Thumbnail = styled.img`
    width:100%;
    height:100%;
    cursor:pointer;
    object-fit:${props=> props.cover ? "cover":'contain'};
    &:focus-visible {
      outline: none !important;
    }
`

const ThumbnailZoom = styled.img`
    max-width:100%;
    max-height:100%;
    cursor:pointer;
    object-fit:contain;
`

const CustomDiv = styled.div`
    height:80vh;
    width:auto !important;
    overflow:hidden;
    &> div >div{
      background-size:contain !important;
    }
`

const CopyIcon = styled(Box)`
  padding: 5px;
  border: ${props => `1px solid ${colors.grey.border}`};
  display: inline-block;
  background-color: ${props => colors.white};
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 30px;
  display: none;
  z-index:1;
`
const ImageWrapper = styled(Box)`
  min-height:350px;
  max-height:400px;
  height:400px;
  // height:99%;
  position: relative;
  border:0.2px solid rgba(0, 0, 0, 0.05);
  &:focus-visible {
    outline: none !important;
  }
  &:hover {
    ${CopyIcon} {
      display: block;
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 15px;
}
`

const useStyles = makeStyles((theme) => ({
  arrow: {
    display:'block',    
    zIndex:2,
    fill:'black',
    position:"absolute",
    backgroundColor:'white',
    border:"0.5px solid black",
    borderRadius:"50%",
    width:'30px',
    height:'30px',
    opacity:'1', 
    "&:hover":{
      opacity:'1 !important',
      fill:'black',
      backgroundColor:'white',
    }   
  },
  right:{
    right:'-20px'
  },
  left:{
    left:'-20px'
  }
}));


const CarouselCustom = props=>{    
    const {images,imagesWithoutPlaceholder}=props
    const [openModal,setOpenModal]= useState(false)
    const [initialModalSlide,setInitialModalSlide]= useState(null)
    const [isStart,setIsStart]= useState(true)
    const [isEnd,setIsEnd]= useState(false)
    const ref = useRef()
    const firstCarouselRef = useRef(null)
    const classes = useStyles();

    function Arrow(props) {
      const { className, style, onClick } = props;
      const handle = ()=>{
        if (props.value === 'next') {
          ref.current.slickGoTo(initialModalSlide + 1,true)
        }else if (props.value === 'prev') {
          ref.current.slickGoTo(initialModalSlide - 1,true)
        }
      }
      return (
        props.value === 'next'?
        <ChevronRightIcon onClick={handle} className={[classes.arrow,classes.right,className]} style={{...style,display:"block"}}/>
        :<ChevronLeftIcon onClick={handle} className={[classes.arrow,classes.left,className]}  style={{...style,display:"block"}}/>
      );
    }

    const SampleArrow = (props) =>{
      const { className, style, onClick } = props;     
      return (        
        props.value === 'next'?
        <ChevronRightIcon onClick={onClick} className={[classes.arrow,classes.right,className]} style={{...style,display:isEnd ? "none": "block"}}/>
        :<ChevronLeftIcon onClick={onClick} className={[classes.arrow,classes.left,className]}  style={{...style,display:isStart?"none":"block"}}/>
      );
    }    

    const checkIndex = (i)=>{
      if (i === 0) {
        setIsStart(true)
        setIsEnd(false)
      }
      if (i !== 0) {
        setIsStart(false)
      }
      if (i === images.length - firstCarouselRef?.current.props.slidesToShow) {
        setIsEnd(true)
      }
    }

    const settings = {
      infinite: false,
      dots: false,
      slidesToShow: props.slidesNb?props.slidesNb:3.5,
      slidesToScroll: 1,            
      autoplay: false,    
      arrows:true ,
      swipe:true,     
      nextArrow: <SampleArrow value={'next'} />,
      prevArrow: <SampleArrow value={'prev'}/>,
      afterChange:(index)=>{checkIndex(index)}
    };

    const modalSliderSettings = {
      infinite: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,            
      autoplay: false,    
      arrows:true ,
      swipe:true,     
      initialSlide:initialModalSlide,
      nextArrow: <Arrow value={'next'}/>,
      prevArrow: <Arrow value={'prev'}/>,
      beforeChange:(oldIndex,newIndex)=>{setInitialModalSlide(newIndex)},      
    };

    const handleOpen = (e)=>{    
      setOpenModal(true)    
    }

    const handleClose = ()=>{
      setOpenModal(false)   
      setInitialModalSlide(null)
    }

    const handleSlide = (e,i)=>{     
      if (imagesWithoutPlaceholder &&i < imagesWithoutPlaceholder.length) {
        setInitialModalSlide(i)
        handleOpen()
      }
    }

    const handleCopyImageUrl = (imageUrl) => {
      navigator.clipboard.writeText(imageUrl)
      props.snack(ALERT_SUCCESS, 'URL de l\'image copiée');
    }

    const checkMediaType = (image)=>{
      const suffix =image.substring(image.lastIndexOf('.') +1,image.length) 
      let type
      if (suffix === 'mp4') {
        type = "video"
      }else{
        type = 'image'
      }
      return type
    }

    return (
        <CarouselContainer container style={{position:"relative"}} isScrap={props.isScrapNew}>  
          {
            images.length >0?
            <Slider style={{ width: '100%'}} ref={firstCarouselRef} {...settings}>
            {                                
                images.map((image,i)=>{
                  const type = checkMediaType(image)
                    return (                                     
                        <ImageWrapper >
                          <CopyIcon onClick={() => handleCopyImageUrl(image)}>
                            <IcomoonReact iconSet={iconSet} color={colors.black.lighter} size={25} icon={"ico_copy"} />
                          </CopyIcon>
                          {
                            type === "video" ?
                              <video onClick={(e)=>handleSlide(e,i)} style={{height:"100%",width:"100%",objectFit:"cover",cursor:"pointer"}} muted autoPlay repeat>
                                    <source src={image} type="video/mp4"/>                                                                
                              </video>
                            :<Thumbnail                                                          
                              onClick={(e)=>props.disableModal ? null:handleSlide(e,i)}
                              src={image} 
                              alt=''
                              cover={image === NoImage}
                            />
                          }
                        </ImageWrapper>
                    )
                })
            }                                           
            </Slider>    
            :
            <PageLoader/>
          }
            <Modal              
              disableEnforceFocus={true}
              open={openModal}     
              onClose={handleClose}                                     
              style={{
                  display:"flex",
                  alignItems:'center',
                  justifyContent:"center"
                }}
                >                                
                <ContainerSliderModal>                                    
                    <Slider                     
                    {...modalSliderSettings}       
                    ref={ref}                                                            
                    >
                    {     
                      !imagesWithoutPlaceholder ? (
                      <Box>
                          <PageLoader/>
                      </Box>
                      ) : 
                      imagesWithoutPlaceholder.map((image,i)=>{
                        const type = checkMediaType(image)
                        return (  
                          <CustomDiv key={i}>
                            {
                            type === "video" ?
                              <video onClick={(e)=>handleSlide(e,i)} style={{height:"600px",width:"600px",objectFit:"cover",cursor:"pointer"}} muted autoPlay repeat>
                                    <source src={image} type="video/mp4"/>                                                                
                              </video>
                            :<ThumbnailZoom 
                            key={i} 
                            src={image} 
                            alt=''
                          />   
                          }                                                     
                          </CustomDiv>
                        )
                      })
                    }                                           
                    </Slider>     
                </ContainerSliderModal>
            </Modal>                 
        </CarouselContainer>
    )
}

const mapDispatchToProps = dispatch => {
  return {
      snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
  };
};

export default connect( null, mapDispatchToProps)(CarouselCustom);