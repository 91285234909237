import { Box, TableRow, TableCell, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import StatusInfo from "../../../../ui/status-info/StatusInfo";
import colors from "../../../../../config/theme/colors";
import { ROUTE_CONTENU_LIST_DETAIL } from "../../../../../js/constants/route-names";
import OurMenu from "../../../../ui/menu/Menu";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ImgNotFound from "../../../../../assets/images/image-fake.png";

const Cell = (props) => {
  return (
    <TableCell
      align={props.align ?? "left"}
      padding={props.padding ?? "checkbox"}
      style={{
        padding: props.customPadding ? props.customPadding : 20,
        ...props.style,
        borderBottom: props.noborder
          ? "none"
          : `0.5px solid ${colors.grey.border}`,
      }}
    >
      {props.children}
    </TableCell>
  );
};

const CellContent = (props) => {
  return (
    <Box display={"flex"} alignItems={"center"} style={{ gap: 5 }}>
      {props.children}
    </Box>
  );
};

const TableContent = (props) => {
  const { t, item, isLast, deleteContent, tableRef, style } = props;

  const { id, title, status, categorie, image } = item;

  return (
    <TableRow ref={tableRef} style={{ width: "100%", ...style }}>
      <TableCell
        style={{
          padding: 0,
          paddingLeft: 10,
          color: colors.grey.lighter.hue600,
          borderBottom: isLast ? "none" : `0.5px solid ${colors.grey.border}`,
        }}
      >
        <DragIndicatorIcon />
      </TableCell>
      <Cell noborder={isLast} customPadding={5} align="center">
        <img
          style={{
            height: "50px",
            objectFit: "cover",
            aspectRatio: "1/1",
            border: `1px solid ${colors.grey.border}50`,
            opacity: status ? 1 : 0.4,
          }}
          src={image || ImgNotFound}
          alt={title}
        />
      </Cell>
      <Cell noborder={isLast}>
        <StatusInfo status={status} />
      </Cell>
      <Cell noborder={isLast} padding={"normal"}>
        <Typography style={{ fontWeight: "bold" }} variant="body1">
          {title}
        </Typography>
      </Cell>
      <Cell noborder={isLast}>
        <CellContent>
          <Typography style={{ whiteSpace: "nowrap" }} variant={"body2"}>
            {categorie}
          </Typography>
        </CellContent>
      </Cell>
      <Cell
        noborder={isLast}
        style={{
          paddingLeft: 50,
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <Link
            to={`${ROUTE_CONTENU_LIST_DETAIL.replace(
              ":id",
              id.replace("/api/contents/", "")
            )}`}
            style={{
              whiteSpace: "nowrap",
              color: colors.blue.darker.hue300,
              fontWeight: "bold",
            }}
          >
            Voir le contenu
          </Link>
          <OurMenu
            menuOptions={[
              {
                label: t("common.delete"),
                color: colors.red.regular,
                icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                action: (e) => {
                  e.stopPropagation();
                  deleteContent(id);
                },
              },
            ]}
            handleClick={(e, item) => item.action(e)}
          />
        </div>
      </Cell>
    </TableRow>
  );
};

export default withTranslation()(TableContent);
