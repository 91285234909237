import { gql } from 'apollo-boost';


//----- Ajouter un file import 
/*
 Exemple de variables
  {
  "url": "${process.env.REACT_APP_API_ROOT}/csv/stocks.csv",
  "mapper": {
    "sku":1,
    "stock":3      
  },
  "retailer":"/api/retailers/1",
  "delimiter": ";",
  "productSource": "/api/product-sources/8",
  "user": "/api/users/1",
  "scrapStatus" : "1"
  }
*/
export const ADD_FILE_IMPORT = gql`
mutation AddFileImport($url: String!, $mapper: Iterable!, $eavType: String, $locale: String, $retailer: String, $delimiter: String, $productSource: String, $user: String, $scrapStatus: String){
	createFileImport(input: {url: $url, mapper: $mapper, eavType: $eavType, locale: $locale, retailer: $retailer, delimiter: $delimiter, productSource: $productSource, user:$user, scrapStatus:$scrapStatus}){
    fileImport{
      id
      url
    }
  }  
}`;



//----- Modifier un file import  
/*
 Exemple de variables
  {
  "id": "/api/file-imports/9",
  "url": "${process.env.REACT_APP_API_ROOT}/csv/stocks.csv",
  "mapper": {
    "sku":1,
    "stock":3      
  },
  "retailer":"/api/retailers/1",
  "delimiter": ";",
  "scrapStatus" : "2"
}
*/
export const UPDATE_FILE_IMPORT = gql`
mutation UpdateFileImport($id:ID!, $url: String, $mapper: Iterable, $eavType: String, $locale: String, $retailer: String, $delimiter: String, $scrapStatus: String){
	updateFileImport(input: {id:$id, url: $url, mapper: $mapper, eavType: $eavType, locale: $locale, retailer: $retailer, delimiter: $delimiter, scrapStatus: $scrapStatus}){
    fileImport{
      id
      url
    }
  }  
}`;


//----- Supprimer un file import 
/*
 Exemple de variables
  {
"id": "/api/file-imports/9"
  }
*/
export const DELETE_FILE_IMPORT = gql`
mutation DeleteFileImport($id: ID!){
	deleteFileImport(input: {id:$id}){
    fileImport{
      id
    }
  }  
}`;

/*
{"scrapStatus": [1,3]}

Détails $scrapStatus
0 = A enrichir ( 1 permets le lancement du cron )
1 , 2 = Enrichissement en cours ( 1 permets le lancement du cron )
3 = Terminé

Détails $productScrapStatus 
A faire manuellement : 404
A verifier : 200
A valider : 99

*/
export const GET_FILE_IMPORT_BY_SCRAP_STATUS = gql`
query customFileImports($scrapStatus:[String],$productScrapStatus:String) {
  customFileImports(scrapStatus_list:$scrapStatus, products_scrapStatus:$productScrapStatus) {
    edges {
      node {
        id
        importedAt
				url
        scrapStatus
        totalRow
        productSource{libelle}
        totalImported
        user{
          lastname
        	firstname
        }
        products(scrapStatus:$productScrapStatus){
          totalCount
          edges{
            node{
              id
              scrapStatus
            }
          }
        }
      }
    }
  }
}
`;

export const GET_FILE_IMPORTS = gql`
query fileImports {
  fileImports {
    edges {
      node {
        id
        importedAt
				url
        scrapStatus
        totalRow
        productSource{libelle}
        totalImported
        user{
          lastname
        	firstname
        }
        eavType{
          id
          name
          code
        }
        products{
          totalCount
          edges{
            node{
              id
              scrapStatus
            }
          }
        }
      }
    }
  }
}
`;