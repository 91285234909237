import { withTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Chip,
  Box,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import IcomoonReact from "icomoon-react";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";

import colors from "../../../../../config/theme/colors";
import InputSearch from "../../../../ui/search/components/InputSearch";
import { useEffect, useRef, useState } from "react";
import OurIconButton from "../../../../ui/icon-button/OurIconButton";
import axios from "../../../../../js/utils/axios";
import { getItemsLocalStorage } from "../../../../../js/helpers/filters";
import {
  SNACK,
  START_LOADING,
  STOP_LOADING,
} from "../../../../../js/constants/action-types";
import { attributeSetup } from "../../../../../js/utils/attributesSetupInputs";
import iconSet from "../../../../../assets/selection.json";

const TextFieldCustom = styled(TextField)`
  background-color: ${colors.white};
  input,
  .MuiSelect-select {
    height: 45px;
    font-size: 14px;
    padding: 13px;
    border-radius: 0;
    border: 1px solid ${colors.grey.border};
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & > p {
    margin: 0;
  }
`;

const CustomSelect = styled(Select)`
  border: 1px solid ${colors.grey.border};
  border-right: ${(props) =>
    props.noBorder ? "none" : `1px solid ${colors.grey.border}`};
  background-color: ${colors.white};
  input {
    height: 45px;
  }
  .MuiSelect-root {
    padding-top: 13px;
    padding-bottom: 13px;
    &:focus {
      background-color: unset !important;
    }
  }
  fieldset {
    border: none;
  }
  .MuiPopover-root.MuiPopover-paper {
    box-shadow: none;
    border: 1px solid ${colors.grey.border};
  }
`;

const ProductsFilters = (props) => {
  const {
    t,
    config,
    filters,
    setFilters,
    currentLang,
    activeFilter,
    setActiveFilter,
    attributes,
    client,
  } = props;
  const [currentSearch, setCurrentSearch] = useState("");
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [oldFiltersHandler, setOldFiltersHandler] = useState(false);
  const [chipsDatas, setChipsDatas] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (currentSearch.length > 0 && !oldFiltersHandler) {
      setCurrentSearch("");
    }
    setOldFiltersHandler(false);
  }, [activeFilter, filters]);

  useEffect(() => {
    let array = [];
    if (filters?.sku || filters?.skus) {
      array.push({
        label: "SKU",
        identifier: filters?.skus ? "skus" : "sku",
        value: filters?.sku || filters?.skus.map((e) => e).join(","),
      });
    }
    if (filters?.categories) {
      let currentAttribute = productAttributes.find(
        (f) => f.stateName === "categories"
      );
      array.push({
        label: "Catégorie",
        identifier: "categories",
        value: currentAttribute?.value?.find(
          (e) =>
            Number(e?.value?.replace("/api/categories/", "")) ===
            filters?.categories?.[0]
        )?.label,
      });
    }
    if (filters?.productDatas?.length > 0) {
      filters?.productDatas?.forEach((e) => {
        let currentAttribute = productAttributes.find(
          (f) => f.stateName === e?.identifier
        );
        array.push({
          label: currentAttribute?.label,
          identifier: e?.identifier,
          value:
            e?.value ||
            currentAttribute?.value?.find((f) => f.value === e?.option)?.label,
          option: e?.option,
        });
      });
    }
    setChipsDatas(array);
  }, [filters]);

  const handleFile = async (file) => {
    setUploadFileLoading(true);
    const type = file?.type;
    let formData = new FormData();
    if (!file) {
      setUploadFileLoading(false);
      return;
    }
    try {
      if (type === "text/csv") {
        formData.append("file", file);
        formData.append("type", "csv");
        let resultMedia = await axios(
          `${process.env.REACT_APP_API}/media-objects`,
          "post",
          formData
        );
        let resultHeaders = await axios(
          `${process.env.REACT_APP_API_ROOT}/api/csv/data/${resultMedia.id}`,
          "get"
        );
        let array = resultHeaders.data.map((e) => e[0]);
        if (array.length > 0) {
          // setCurrentSearch(array.slice(1).map(e=>e).join(","))
          handleFilter(array.length > 1 ? "skus" : "sku", array.slice(1));
        }
      }
      setUploadFileLoading(false);
      if (inputRef.current) {
        inputRef.current.value = null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilter = (stateName, value, isProductDatas) => {
    let currentFilters = { ...filters };
    let finalStateName = stateName;
    let finalValue = value;
    if ((!value || value?.length === 0) && !isProductDatas) {
      if (stateName === "sku") {
        delete currentFilters?.["skus"];
        delete currentFilters?.["sku"];
      } else {
        delete currentFilters?.[stateName];
      }
    } else if ((!value || value?.length === 0) && stateName === "categories") {
      delete currentFilters?.[stateName];
    } else {
      if (stateName === "sku") {
        const rgx = /[\s,;| ]/g;
        const formatSku = value.split(rgx).filter((e) => e?.length > 0);
        if (formatSku.length > 1) {
          finalStateName = "skus";
          finalValue = formatSku;
          if (currentFilters?.sku) {
            delete currentFilters.sku;
          }
        } else {
          finalValue = formatSku[0];
          if (currentFilters?.skus) {
            delete currentFilters.skus;
          }
        }
      } else if (stateName === "categories") {
        finalValue = [Number(value?.replace("/api/categories/", ""))];
      } else if (isProductDatas && stateName !== "categories") {
        let productDatas = [...(currentFilters?.productDatas || [])];
        finalStateName = "productDatas";
        if (!value || value?.length === 0) {
          productDatas = productDatas.filter(
            (e) => e?.identifier !== stateName
          );
        } else {
          let current = {
            identifier: stateName,
            value:
              productAttributes.find((e) => e.stateName === activeFilter)
                ?.type === "select"
                ? null
                : value,
            locale: currentLang,
            option:
              productAttributes.find((e) => e.stateName === activeFilter)
                ?.type === "text"
                ? null
                : value,
          };
          if (productDatas.find((e) => e?.identifier === stateName)) {
            productDatas = productDatas.filter(
              (e) => e?.identifier !== stateName
            );
          }
          productDatas.push(current);
        }
        finalValue = productDatas;
      }
      currentFilters[finalStateName] = finalValue;
    }
    setFilters(currentFilters);
  };

  const deleteFilter = (stateName) => {
    if (filters?.[stateName]) {
      handleFilter(stateName, null);
    } else if (
      filters?.productDatas?.find((e) => e?.identifier === stateName)
    ) {
      handleFilter(stateName, null, true);
    }
  };

  const setOldFilters = (e) => {
    if (e.identifier === "skus" || e.identifier === "sku") {
      setOldFiltersHandler(true);
      setActiveFilter("sku");
      setCurrentSearch(e.value);
    } else {
      setOldFiltersHandler(true);
      setActiveFilter(e.identifier);
      setCurrentSearch(e?.option || e.value);
    }
  };

  const productCategories = getItemsLocalStorage("PRODUCT_CATEGORIES");
  let getInternalFilters =
    attributes?.product?.attributes?.edges
      ?.filter((e) => e.node.isSearchable)
      ?.map((e) => attributeSetup(e, currentLang))
      .filter(
        (e) =>
          e.type !== "image" && e.type !== "file" && e.type !== "mediaPicker"
      ) || [];
  getInternalFilters.unshift({
    type: "select",
    value: productCategories?.map((e) => {
      return {
        value: e?.id,
        label: e?.categoryDatas?.edges?.[0]?.node?.value || e?.libelle,
      };
    }),
    label: t("common.categories"),
    stateName: "categories",
    isSystem: false,
    currentLang: props.currentLang,
    helper: {
      link: false,
    },
    email: false,
    translated: false,
  });
  getInternalFilters.unshift({
    type: "text",
    value: "",
    label: "SKU",
    stateName: "sku",
    isSystem: false,
    currentLang: props.currentLang,
    helper: {
      link: false,
    },
    email: false,
    translated: false,
  });
  const productAttributes = getInternalFilters.map((e) => {
    if (e.type === "textarea") {
      e.type = "text";
    }
    return e;
  });

  return (
    <Grid container style={{ gap: 15 }}>
      {config?.filters?.options.map((option, i) => {
        if (option.type === "select") {
          return (
            <Grid
              item
              xs={"auto"}
              style={{
                width: 200,
                height: "fit-content",
              }}
            >
              <CustomSelect
                key={i}
                value={filters?.[option.stateName] || option.defaultValue || ""}
                onChange={(evt) =>
                  handleFilter(option.stateName, evt.target.value)
                }
                variant="outlined"
                fullWidth
                MenuProps={{
                  elevation: 0,
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  MenuListProps: {
                    style: {
                      padding: 0,
                    },
                  },
                  PaperProps: {
                    square: true,
                    style: {
                      outline: `0.5px solid ${colors.grey.border}`,
                      width: 300,
                      maxHeight: "500px !important",
                      // boxSizing: 'initial',
                    },
                  },
                }}
              >
                {option.values.map((e, j) => {
                  return (
                    <MenuItem
                      style={{ color: colors.black.regular }}
                      value={e.value}
                      key={i}
                    >
                      {t(e.label)}
                    </MenuItem>
                  );
                })}
              </CustomSelect>
            </Grid>
          );
        }
        if (option.type === "dynamicFilter") {
          return (
            <Grid
              item
              xs={option.xs ? option.xs : true}
              style={{
                height: "fit-content",
              }}
            >
              <Grid container item xs style={{ padding: 0 }}>
                <Grid
                  item
                  xs={props.inForm ? 12 : "auto"}
                  style={{
                    width: 200,
                  }}
                >
                  <CustomSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeFilter}
                    onChange={(evt) =>
                      setActiveFilter(
                        productAttributes.find(
                          (e) => e.stateName === evt.target.value
                        )?.stateName
                      )
                    }
                    variant="outlined"
                    noBorder
                    fullWidth
                    MenuProps={{
                      elevation: 0,
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      MenuListProps: {
                        style: {
                          padding: 0,
                        },
                      },
                      PaperProps: {
                        square: true,
                        style: {
                          outline: `1px solid ${colors.grey.border}`,
                          width: 300,
                          // boxSizing: 'initial',
                        },
                      },
                    }}
                  >
                    {productAttributes
                      .sort((a, b) => {
                        if (a.label === "SKU" || b.label === "SKU") {
                          return 0;
                        }
                        if (a.label < b.label) {
                          return -1;
                        } else if (a.label > b.label) {
                          return 1;
                        } else {
                          return 0;
                        }
                      })
                      .map((e, index) => {
                        return (
                          <MenuItem
                            style={{ color: colors.black.regular }}
                            value={e.stateName}
                            key={index}
                          >
                            {e.label}
                          </MenuItem>
                        );
                      })}
                  </CustomSelect>
                </Grid>
                <Grid item xs>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                      //   transform: "scaleY(0.95)",
                      //   height: "43px",
                    }}
                  >
                    <InputSearch
                      value={currentSearch}
                      input={productAttributes.find(
                        (e) => e.stateName === activeFilter
                      )}
                      errorCallback={() => {}}
                      startIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={colors.grey.lighter.hue600}
                          height={25}
                        >
                          <title>SearchIcon</title>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                          />
                        </svg>
                        // <SearchIcon style={{ color: "#C9CBCC" }} />
                      }
                      endIcon={
                        activeFilter === "sku" ? (
                          <label htmlFor="upload-skus">
                            <input
                              ref={inputRef}
                              style={{ display: "none" }}
                              id="upload-skus"
                              name="upload-skus"
                              type="file"
                              onChange={(evt) =>
                                handleFile(evt.target.files[0])
                              }
                              accept=".csv"
                            />
                            <OurIconButton
                              tooltip={"Importer des SKUS"}
                              component="span"
                              aria-label="add"
                            >
                              {uploadFileLoading ? (
                                <CircularProgress
                                  size={20}
                                  style={{ color: colors.black.regular }}
                                />
                              ) : (
                                <IcomoonReact
                                  size={"20px"}
                                  iconSet={iconSet}
                                  icon={"ico_getapp"}
                                />
                              )}
                            </OurIconButton>
                          </label>
                        ) : null
                      }
                      stateCallback={(evt) => {
                        let type = productAttributes.find(
                          (e) => e.stateName === activeFilter
                        )?.type;
                        if (type === "select")
                          handleFilter(activeFilter, evt, true);
                        if (type === "text")
                          setCurrentSearch(evt?.target.value);
                      }}
                      onKeyPress={(evt) => {
                        let type = productAttributes.find(
                          (e) => e.stateName === activeFilter
                        )?.type;
                        if (type === "text" && evt.code === "Enter")
                          handleFilter(activeFilter, evt.target.value, true);
                      }}
                    />
                  </div>
                  {chipsDatas.length > 0 && (
                    <Box
                      style={{
                        marginTop: 5,
                        display: "flex",
                        gap: 5,
                        flexWrap: "wrap",
                      }}
                    >
                      {chipsDatas?.map((e, index) => {
                        return (
                          <Chip
                            key={index}
                            size="small"
                            deleteIcon={
                              <CloseIcon
                                fontSize="small"
                                style={{ color: colors.black.regular }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: "200px",
                                  display: "block",
                                }}
                              >
                                {e.label} : {e.value}
                              </span>
                            }
                            onDelete={() =>
                              deleteFilter(e?.identifier, null, true)
                            }
                            onClick={() => setOldFilters(e)}
                            style={{
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={option.xs ? option.xs : true}>
              <TextFieldCustom
                placeholder={t(option.placeholder)}
                variant="outlined"
                // value={filtersState[option.stateName] || option.defaultValue || ""}
                // onChange={(evt)=>updateFilter(option.stateName,evt.target.value,false)}
                // onKeyDown={(evt) =>evt.code === "Enter" ? updateFilter(option.stateName,evt.target.value?.length > 0 ? evt.target.value : null,true):null}
                fullWidth
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
    attributeGroups: state.attributeGroups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default withTranslation()(
  withApollo(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsFilters))
  )
);
