import React, { useState } from "react";
import SearchBar from "./SearchBar";
import { withTranslation } from "react-i18next";
import OurButton from "../button/Button";
import { Box } from "@material-ui/core";
import colors from "../../../config/theme/colors";

const CustomSearch = ({ debouncedSearchValue, setDebouncedSearchValue }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const resetValues = () => {
    setSearchValue("");
    setDebouncedSearchValue("");
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <SearchBar
        type="text"
        placeholder="Rechercher..."
        onChange={handleInputChange}
        onKeyPress={(evt) => {
          if (evt.key === "Enter") {
            setDebouncedSearchValue(searchValue);
          }
        }}
        value={searchValue}
        noIcon
      />
      {debouncedSearchValue ? (
        <OurButton
          color={colors.red.darker}
          bgcolor={colors.red.lighterv2}
          bgcolorhover={colors.red.lighterv2Hover}
          style={{
            marginTop: 0,
            marginBottom: 0,
            borderRadius: 0,
            border: `1px solid ${colors.red.darker}`,
          }}
          onClick={resetValues}
        >
          Effacer
        </OurButton>
      ) : null}
      <OurButton onClick={() => setDebouncedSearchValue(searchValue)}>
        Rechercher
      </OurButton>
    </Box>
  );
};

export default withTranslation()(CustomSearch);
