import {
  TableHead,
  Table,
  TableBody,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
} from "@material-ui/core";
import TableRowDraggable from "./TableRowDraggable";
import { useEffect, useState } from "react";
import arrayMoveImmutable from "array-move";
import colors from "../../../../../config/theme/colors";
import _ from "lodash";

const TableViewNew = (props) => {
  const {
    items,
    config,
    selectionIsActive,
    selectedProducts,
    setSelectedProducts,
    tableProps,
    dragCallback,
    currentLang,
  } = props;
  const [itemsOrdered, setItemsOrdered] = useState(
    items.map((e, i) => {
      return { ...e, index: i };
    })
  );

  useEffect(() => {
    setItemsOrdered(
      items.map((e, i) => {
        return { ...e, index: i };
      })
    );
  }, [items]);

  const updateRow = (index) => {
    const itemIndex = items.findIndex(
      (item) => item.id === itemsOrdered[index].id
    );
    const updatedItems = Array.from(items);
    const draggedItem = updatedItems[itemIndex];
    updatedItems.splice(itemIndex, 1);
    updatedItems.splice(index, 0, draggedItem);

    const updatedIDs = updatedItems.map((item) => item.id);
    dragCallback(updatedIDs);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    setItemsOrdered((prevItems) => {
      return arrayMoveImmutable(prevItems, dragIndex, hoverIndex);
    });
  };

  return (
    <TableContainer
      elevation={0}
      component={Paper}
      style={{
        maxHeight: props.inForm ? "none" : "100%",
        borderRadius: "0px",
        border: `1px solid ${colors.grey.border}`,
      }}
    >
      <Table
        aria-label="headerList"
        style={{
          borderCollapse: "unset",
        }}
      >
        {config?.columns ? (
          <TableHead>
            <TableRow>
              {config?.columns?.map((column, index) => {
                return (
                  <TableCell
                    key={index}
                    padding={column.padding ?? "checkbox"}
                    style={{
                      padding: "15px 20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {column.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {itemsOrdered?.map((item, index) =>
            config.tableDraggable ? (
              <TableRowDraggable
                key={item.id}
                index={index}
                item={item}
                isLast={index === items.length - 1}
                selectionIsActive={selectionIsActive}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                tableProps={tableProps}
                config={config}
                moveRow={moveRow}
                updateRow={updateRow}
              />
            ) : (
              <config.tableComponent
                {...tableProps}
                key={`${item.id}-${index}`}
                item={item}
                isLast={index === items.length - 1}
                selectionIsActive={selectionIsActive}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                currentLang={currentLang}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableViewNew;
