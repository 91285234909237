export const ROUTE_HOME                                 = "/";
export const ALL_ROUTE_SPREADSUITE                      = [ ROUTE_HOME ];

export const ROUTE_STATS                                = "/statistics";
export const ROUTE_STATS_ORDERS                         = "/statistics/statsOrders";
export const ROUTE_STATS_ORDERS_BY_SITE                 = "/statistics/statsOrders/bySite";
export const ROUTE_STATS_ORDERS_BY_MARKETPLACES         = "/statistics/statsOrders/marketplaces";
export const ROUTE_STATS_RETAILERS                      = "/statistics/retailers";
export const ROUTE_STATS_ASSETS                         = "/statistics/assets";
export const ROUTE_STATS_CAMPAIGNS                      = "/statistics/campaigns";
export const ROUTE_STATS_GOOGLE_ANALYTICS               = "/statistics/googleAnalytics";
export const ALL_ROUTE_STATS                            = [ ROUTE_STATS, ROUTE_STATS_ORDERS, ROUTE_STATS_RETAILERS, ROUTE_STATS_ASSETS, ROUTE_STATS_CAMPAIGNS, ROUTE_STATS_ORDERS_BY_SITE, ROUTE_STATS_ORDERS_BY_MARKETPLACES ];

export const ROUTE_HISTORY                              = "/history";
export const ROUTE_HISTORY_DASHBOARD                    = "/history/dashboard";
export const ROUTE_HISTORY_IMPORT                       = "/history/import_history";
export const ROUTE_HISTORY_IMPORT_PRODUCT               = "/history/import-history-product"
export const ROUTE_HISTORY_ACTIONS_LOGS                 = "/history/actions-logs";
export const ALL_ROUTE_HISTORY                            = [ ROUTE_HISTORY, ROUTE_HISTORY_DASHBOARD, ROUTE_HISTORY_IMPORT, ROUTE_HISTORY_IMPORT_PRODUCT, ROUTE_HISTORY_ACTIONS_LOGS ];

export const ROUTE_SALES                                                = "/sales";
export const ROUTE_SALES_GLOBAL                                         = "/sales/global";
export const ROUTE_SALES_ESHOP                                          = "/sales/eshop";
export const ROUTE_SALES_ESHOP_DETAIL                                   = "/sales/eshop/detail/:id";
export const ROUTE_SALES_ESHOP_DETAIL_MANAGE_CONTENTS                   = "/sales/eshop/detail/:id/manageContents";
export const ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS                      = "/sales/eshop/detail/:id/manageSells";
export const ROUTE_SALES_ESHOP_DETAIL_MANAGE_SELLS_OFFERS               = "/sales/eshop/detail/:id/manageOffers";
export const ROUTE_SALES_ESHOP_DETAIL_STATS                             = "/sales/eshop/detail/:id/stats";
export const ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET                     = "/sales/eshop/detail/:id/headers";
export const ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME               = "/sales/eshop/detail/:id/home/:typeAsset/homeList";
export const ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS        = "/sales/eshop/detail/:id/home/:typeAsset/homeList/:page";
export const ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING            = "/sales/eshop/detail/:id/landing/:typeAsset/landingList";
export const ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS     = "/sales/eshop/detail/:id/landing/:typeAsset/landingList/:page";
export const ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK                     = "/sales/eshop/detail/:id/shopthelook";
export const ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING                     = "/sales/eshop/detail/:id/merchandising";
export const ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH            = "/sales/eshop/detail/:id/merchandising/category/:idCat";
export const ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER                    = "/builder-merch/:id/merchandising/:idCat/:idMerch";
export const ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS                 = "/sales/eshop/detail/:id/info-managements";
export const ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS                         = "/sales/eshop/detail/:id/discounts";
export const ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES                      = "/sales/eshop/detail/:id/top-messages";
export const ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES                  = "/sales/eshop/detail/:id/cache/categories";
export const ROUTE_SALES_ESHOP_FOOTER                                   = "/sales/eshop/detail/:id/footer";
export const ROUTE_SALES_ESHOP_DETAIL_CROSSSELL                         = "/sales/eshop/detail/:id/crosssell";
export const ROUTE_SALES_SOCIAL_COMMERCE                                = "/sales/social-commerce";
export const ROUTE_SALES_SOCIAL_COMMERCE_DETAILS                        = "/sales/social-commerce/detail/:id";
export const ROUTE_SALES_MARKETPLACES                                   = "/sales/marketplaces";
export const ROUTE_SALES_MARKETPLACES_DETAILS                           = "/sales/marketplaces/detail/:id";
export const ROUTE_SALES_SHOPS                                          = "/sales/shops";
export const ROUTE_SALES_SHOPS_DETAILS                                  = "/sales/shops/detail/:id";
export const ROUTE_SALES_APPLICATIONS                                   = "/sales/applications";
export const ROUTE_SALES_APPLICATIONS_DETAILS                           = "/sales/applications/detail/:id";
export const ROUTE_SALES_APPLICATIONS_NOTIFICATIONS                     = "/sales/applications/detail/:id/notifications";
export const ROUTE_SALES_RETAILERS                                      = "/sales/retailers";
export const ALL_ROUTE_SALES                                            = [ ROUTE_SALES, ROUTE_SALES_GLOBAL, ROUTE_SALES_ESHOP, ROUTE_SALES_SOCIAL_COMMERCE, ROUTE_SALES_MARKETPLACES, ROUTE_SALES_SHOPS, ROUTE_SALES_SHOPS_DETAILS, ROUTE_SALES_RETAILERS, ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, ROUTE_SALES_MARKETPLACES_DETAILS, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH, ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER, ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES, ROUTE_SALES_ESHOP_FOOTER, ROUTE_SALES_ESHOP_DETAIL_CROSSSELL, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS, ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS];

export const ROUTE_COMMUNITIES                          = "/communities";
export const ROUTE_COMMUNITIES_GAMES                    = "/communities/games";
export const ROUTE_COMMUNITIES_GAMES_DETAIL             = "/communities/games/detail/:id";
export const ROUTE_COMMUNITIES_GAMES_DETAIL_STATS       = "/communities/games/detail/:id/stats";
export const ROUTE_COMMUNITIES_GAMES_DIFFUSION          = "/communities/games/detail/diffusion/:id";
export const ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE     = "/communities/games/detail/diffusion/:id/:page";
export const ROUTE_COMMUNITIES_MINISITES                = "/communities/minisites";
export const ROUTE_COMMUNITIES_MINISITES_DETAIL         = "/communities/minisites/detail/:id";
export const ROUTE_COMMUNITIES_MINISITES_DIFFUSION      = "/communities/minisites/detail/diffusion/:id";
export const ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE = "/communities/minisites/detail/diffusion/:id/:page";
export const ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS    = "/communities/minisites/detail/:id/stats";
export const ROUTE_COMMUNITIES_NEWSLETTERS              = "/communities/newsletters";
export const ROUTE_COMMUNITIES_SOCIALS                  = "/communities/socials";
export const ROUTE_COMMUNITIES_SOCIALS_DETAIL           = "/communities/socials/detail/:id";
export const ROUTE_COMMUNITIES_PERMANENTS_POSTS         = "/communities/socials/detail/:id/posts";
export const ROUTE_COMMUNITIES_PERMANENTS_ADD_POST      = "/communities/socials/detail/:id/post/add";
export const ROUTE_COMMUNITIES_CMS                      = "/communities/cms";
export const ROUTE_COMMUNITIES_CMS_DETAIL               = "/communities/cms/detail/:id";
export const ROUTE_COMMUNITIES_CMS_DETAIL_MANAGE_CONTENTS                   = "/communities/cms/detail/:id/manageContents";
export const ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING            = "/communities/cms/detail/:id/landing/:typeAsset/landingList";
export const ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS     = "/communities/cms/detail/:id/landing/:typeAsset/landingList/:page";
export const ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER     = "/communities/cms/detail/:id/footer/:typeAsset/footerList";
export const ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS     = "/communities/cms/detail/:id/footer/:typeAsset/footerList/:page";
export const ALL_ROUTE_COMMUNITIES                      = [ ROUTE_COMMUNITIES, ROUTE_COMMUNITIES_GAMES, ROUTE_COMMUNITIES_MINISITES, ROUTE_COMMUNITIES_NEWSLETTERS, ROUTE_COMMUNITIES_SOCIALS, ROUTE_COMMUNITIES_GAMES_DIFFUSION, ROUTE_COMMUNITIES_GAMES_DIFFUSION_PAGE, ROUTE_COMMUNITIES_GAMES_DETAIL_STATS, ROUTE_COMMUNITIES_MINISITES_DIFFUSION, ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE, ROUTE_COMMUNITIES_SOCIALS_DETAIL, ROUTE_COMMUNITIES_PERMANENTS_POSTS,ROUTE_COMMUNITIES_PERMANENTS_ADD_POST, ROUTE_COMMUNITIES_CMS, ROUTE_COMMUNITIES_CMS_DETAIL, ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING, ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS];

export const ROUTE_DIFFUSION                                            = "/diffusion";
export const ROUTE_DIFFUSION_CALENDAR                                   = "/diffusion/calendar";
export const ROUTE_DIFFUSION_PERMANENT                                  = "/diffusion/permanent";
export const ROUTE_DIFFUSION_PERMANENTS_PAGE                            = "/diffusion/permanent/:id/:page";
export const ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET                   = "/diffusion/permanent/:id/headers";
export const ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET                     = "/diffusion/permanent/:id/pages/:typeAsset";
export const ROUTE_DIFFUSION_PERMANENTS_INFOS_MANAGEMENTS_ASSET         = "/diffusion/permanent/:id/info-managements";
export const ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET              = "/diffusion/permanent/:id/pages/:typeAsset/:page";
export const ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK                   = "/diffusion/permanent/:id/shopthelook";
export const ROUTE_DIFFUSION_CAMPAIGNS                                  = "/diffusion/campaigns";
export const ROUTE_DIFFUSION_CAMPAIGNS_ADD                              = "/diffusion/campaigns/add";
export const ROUTE_DIFFUSION_CAMPAIGNS_DETAIL                           = "/diffusion/campaigns/:id";
export const ROUTE_DIFFUSION_RETAILERS                                  = "/diffusion/retailers";
export const ROUTE_DIFFUSION_SOCIALS                                    = "/diffusion/socials";
export const ALL_ROUTE_DIFFUSION                                        = [ ROUTE_DIFFUSION, ROUTE_DIFFUSION_CALENDAR, ROUTE_DIFFUSION_PERMANENT , ROUTE_DIFFUSION_CAMPAIGNS, ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_DIFFUSION_CAMPAIGNS_DETAIL, ROUTE_DIFFUSION_RETAILERS, ROUTE_DIFFUSION_SOCIALS, ROUTE_DIFFUSION_PERMANENTS_HEADERS_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_CHILDS_ASSET, ROUTE_DIFFUSION_PERMANENTS_SHOP_THE_LOOK]

export const ROUTE_ENGINE                               = "/engine";
export const ROUTE_ENGINE_ASSETS                        = "/engine/assets";
export const ROUTE_ENGINE_ASSETS_DETAIL                 = "/engine/assets/detail/:id";
export const ROUTE_ENGINE_ASSETS_DETAIL_STATS           = "/engine/assets/detail/:id/stats";
export const ALL_ROUTE_ENGINE                           = [ ROUTE_ENGINE, ROUTE_ENGINE_ASSETS, ROUTE_ENGINE_ASSETS_DETAIL, ROUTE_ENGINE_ASSETS_DETAIL_STATS, ROUTE_DIFFUSION_PERMANENTS_PAGE ];

export const ROUTE_CATALOGS                             = "/catalogs";
export const ROUTE_CATALOGS_LIST                        = "/catalogs/list";
export const ROUTE_CATALOGS_DETAIL                      = "/catalogs/detail/:id";
export const ROUTE_CATALOGS_SETTINGS                    = "/catalogs/configuration";
export const ALL_ROUTE_CATALOGS                         = [ ROUTE_CATALOGS, ROUTE_CATALOGS_LIST, ROUTE_CATALOGS_DETAIL, ROUTE_CATALOGS_SETTINGS];


export const ROUTE_MEDIAS                               = "/medias";
export const ROUTE_MEDIAS_LIST                          = "/medias/list";
export const ROUTE_MEDIAS_SETTINGS                      = "/medias/configuration";
export const ROUTE_MEDIAS_TAGS                          = "/medias/configuration/tags";
export const ROUTE_MEDIAS_CATEGORIES                    = "/medias/configuration/categories";
export const ROUTE_MEDIAS_ATTRIBUTS                     = "/medias/configuration/attributes";
export const ALL_ROUTE_MEDIAS                           = [ ROUTE_MEDIAS, ROUTE_MEDIAS_CATEGORIES, ROUTE_MEDIAS_LIST, ROUTE_MEDIAS_SETTINGS ];

export const ROUTE_CONTENU                              = "/contenu";
export const ROUTE_CONTENU_LIST                         = "/contenu/list";
export const ROUTE_CONTENU_ADD                          = "/contenu/add";
export const ROUTE_CONTENU_LIST_DETAIL                  = "/contenu/list/:id";
export const ROUTE_CONTENU_SETTINGS                     = "/contenu/configuration";
export const ROUTE_CONTENU_CATEGORIES                   = "/contenu/configuration/categories";
export const ROUTE_CONTENU_ATTRIBUTES                   = "/contenu/configuration/attributes";
export const ROUTE_CONTENU_ARBO                         = "/contenu/arborescence";
export const ALL_ROUTE_CONTENU                          = [ ROUTE_CONTENU, ROUTE_CONTENU_CATEGORIES, ROUTE_CONTENU_LIST, ROUTE_CONTENU_ATTRIBUTES, ROUTE_CONTENU_SETTINGS ];


export const ROUTE_ORDERS                               = "/orders";
export const ROUTE_ORDERS_LIST                          = "/orders/list";
export const ROUTE_ORDERS_LIST_DELAY                    = "/orders/list/delay";
export const ROUTE_ORDERS_LIST_RETURN                   = "/orders/list/return";
export const ROUTE_ORDERS_LIST_PREORDER                 = "/orders/list/preorder";
export const ROUTE_ORDERS_DETAIL                        = "/orders/detail/:id"; //TODO Remove passing state use match params
export const ALL_ROUTE_ORDERS                           = [ ROUTE_ORDERS, ROUTE_ORDERS_LIST, ROUTE_ORDERS_DETAIL ];

export const ROUTE_ATTRIBUTS                            = "/attributs";
export const ROUTE_ATTRIBUTS_ATTRIBUTS                  = "/attributs/attributs";
export const ROUTE_ATTRIBUTS_CATEGORIES                 = "/attributs/attributs/categories";
export const ROUTE_ATTRIBUTS_POINT                      = "/attributs/attributs/points";
export const ALL_ROUTE_ATTRIBUTS                        = [ ROUTE_ATTRIBUTS, ROUTE_ATTRIBUTS_ATTRIBUTS, ROUTE_ATTRIBUTS_CATEGORIES, ROUTE_ATTRIBUTS_POINT];

export const ROUTE_SHELLS                               = "/shells";
export const ROUTE_SHELLS_LIST                          = "/shells/list";
export const ROUTE_SHELLS_CUSTOM                        = "/custom-eav/:alias/:id";
export const ROUTE_SHELLS_CUSTOM_LIST                   = "/custom-eav/:alias/:id/list";
export const ROUTE_SHELLS_CUSTOM_DETAIL                 = "/custom-eav/:alias/:id/detail/:idShell";
export const ROUTE_SHELLS_CUSTOM_ATTRIBUTES             = "/custom-eav/:alias/:id/attributes";
export const ALL_ROUTE_SHELLS                           = [ ROUTE_SHELLS, ROUTE_SHELLS_LIST ];
export const ALL_ROUTE_SHELLS_CUSTOM                    = [ ROUTE_SHELLS_CUSTOM, ROUTE_SHELLS_CUSTOM_ATTRIBUTES,ROUTE_SHELLS_CUSTOM_LIST, ROUTE_SHELLS_CUSTOM_DETAIL ];

export const ROUTE_PRODUCTS                             = "/products";
export const ROUTE_PRODUCTS_LIST                        = "/products/list";
export const ROUTE_PRODUCTS_ADD                         = "/products/add";
export const ROUTE_PRODUCTS_LIST_DETAIL                 = "/products/list/:id";
export const ROUTE_PRODUCTS_CONNECTORS                  = "/products/connectors";
export const ROUTE_PRODUCTS_SETTINGS                    = "/products/configuration";
export const ROUTE_PRODUCTS_COMPLETUDE                  = "/products/configuration/completude";
export const ROUTE_PRODUCTS_ATTRIBUTS                   = "/products/configuration/attributs";
export const ROUTE_PRODUCTS_CATEGORIES                  = "/products/configuration/categories";
export const ROUTE_PRODUCTS_COVER_RATE                  = "/products/cover-rate";
export const ROUTE_PRODUCTS_COVER_RATE_DETAIL           = "/products/cover-rate/:sku";
export const ROUTE_PRODUCTS_COLLECTIONS                 = "/products/collections";
export const ROUTE_PRODUCTS_PRICE_COMPARE               = "/products/prices";
export const ROUTE_PRODUCTS_PRICE_COMPARE_DASHBOARD     = "/products/prices/dashboard";
export const ROUTE_PRODUCTS_PRICE_COMPARE_LIST          = "/products/prices/list";
export const ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL        = "/products/prices/list/detail/:sku";
export const ROUTE_PRODUCTS_BRANDS_LIST                 = "/products/brands/list";
export const ROUTE_PRODUCTS_BRANDS_DETAIL               = "/products/brands/list/detail/:id";
export const ROUTE_PRODUCTS_WORKFLOW_NEW                = "/products/workflow/new";
export const ROUTE_PRODUCTS_WORKFLOW_DELTA              = "/products/workflow/delta";
export const ROUTE_PRODUCTS_WORKFLOW_MANUALLY                = "/products/workflow/manually";
export const ROUTE_PRODUCTS_WORKFLOW_TO_VERIFY              = "/products/workflow/toverify";
export const ROUTE_PRODUCTS_WORKFLOW_TO_VALIDATE              = "/products/workflow/tovalidate";
export const ROUTE_PRODUCTS_WORKFLOW_LISTING              = "/products/workflow/listing";
export const ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE              = "/products/workflow/to_complete";
export const ROUTE_PRODUCTS_WORKFLOW_DELAYED              = "/products/workflow/delayed";
export const ROUTE_PRODUCTS_WORKFLOW_ERRORS              = "/products/workflow/errors";
export const ROUTE_PRODUCTS_IMPORTS              = "/products/imports";


// export const ROUTE_PRODUCTS_STORELOCATOR                = "/storelocator";
// export const ROUTE_PRODUCTS_STORELOCATOR_DETAILS        = "/storelocator/:id";
export const ALL_ROUTE_PRODUCTS                         = [ ROUTE_PRODUCTS, ROUTE_PRODUCTS_CATEGORIES, ROUTE_PRODUCTS_COMPLETUDE, ROUTE_PRODUCTS_LIST, ROUTE_PRODUCTS_ADD, ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_CONNECTORS, ROUTE_PRODUCTS_SETTINGS, ROUTE_PRODUCTS_COVER_RATE, ROUTE_PRODUCTS_COVER_RATE_DETAIL, ROUTE_PRODUCTS_PRICE_COMPARE, ROUTE_PRODUCTS_PRICE_COMPARE_DASHBOARD, ROUTE_PRODUCTS_PRICE_COMPARE_LIST, ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL, ROUTE_PRODUCTS_BRANDS_LIST, ROUTE_PRODUCTS_BRANDS_DETAIL, ROUTE_PRODUCTS_WORKFLOW_NEW, ROUTE_PRODUCTS_WORKFLOW_DELTA];


export const ROUTE_CHANNELS                             = "/channels";
export const ROUTE_CHANNELS_RETAILERS                   = "/channels/retailers";
export const ROUTE_CHANNELS_BRANDS                      = "/channels/brands";
export const ROUTE_CHANNELS_MARKETPLACES                = "/channels/marketplaces";
export const ROUTE_CHANNELS_SOCIALS                     = "/channels/socials";
export const ROUTE_CHANNELS_CMS                         = "/channels/cms";
export const ROUTE_CHANNELS_CONTENTS                    = "/channels/connectorsContent";
export const ROUTE_CHANNELS_MAILER                      = "/channels/newsletter";
export const ROUTE_CHANNELS_MEDIAS                      = "/channels/connectorsMedia";
export const ROUTE_CHANNELS_CONNECTORS                  = "/channels/connectors";
export const ALL_ROUTE_CHANNELS                         = [ ROUTE_CHANNELS, ROUTE_CHANNELS_RETAILERS, ROUTE_CHANNELS_SOCIALS, ROUTE_CHANNELS_CMS, ROUTE_CHANNELS_CONTENTS, ROUTE_CHANNELS_MAILER, ROUTE_CHANNELS_MEDIAS, ROUTE_CHANNELS_CONNECTORS ];

export const ROUTE_BRAND_GUIDELINE                      = "/brand/guideline";
export const ROUTE_BRAND_GUIDELINE_ADD                  = "/brand/guideline/add";
export const ROUTE_BRAND_GUIDELINE_UPDATE               = "/brand/guideline/:id";
export const ROUTE_BRAND_FLOW                           = "/brand/flow";
export const ROUTE_BRAND_FLOW_DETAILS                   = "/brand/flow/:id";
export const ALL_ROUTE_BRAND                            = [ ROUTE_BRAND_GUIDELINE, ROUTE_BRAND_GUIDELINE_ADD, ROUTE_BRAND_GUIDELINE_UPDATE, ROUTE_BRAND_FLOW, ROUTE_BRAND_FLOW_DETAILS ];

export const ROUTE_SETTINGS                             = "/settings";
export const ROUTE_SETTINGS_LANGUAGES                   = "/settings/languages";
export const ROUTE_SETTINGS_CURRENCIES                  = "/settings/currencies";
export const ROUTE_SETTINGS_TAXRATES                    = "/settings/taxrates";
export const ROUTE_SETTINGS_GROUPRATES                  = "/settings/groupRates";
export const ROUTE_SETTINGS_USERS                       = "/settings/users";
export const ROUTE_SETTINGS_DETAIL_USER                 = "/settings/user/:id";
export const ROUTE_SETTINGS_USERS_GROUPS                = "/settings/users-groups";
export const ROUTE_SETTINGS_ACCESS                      = "/settings/access";
export const ROUTE_SETTINGS_SUBSCRIPTIONS               = "/settings/subscriptions";
export const ROUTE_SETTINGS_GUIDELINE                   = "/settings/guideline";
export const ROUTE_SETTINGS_GUIDELINE_ADD               = "/settings/guideline/add";
export const ROUTE_SETTINGS_GUIDELINE_UPDATE            = "/settings/guideline/:id";
export const ALL_ROUTE_SETTINGS                         = [ ROUTE_SETTINGS, ROUTE_SETTINGS_LANGUAGES, ROUTE_SETTINGS_CURRENCIES, ROUTE_SETTINGS_TAXRATES, ROUTE_SETTINGS_USERS, ROUTE_SETTINGS_DETAIL_USER, ROUTE_SETTINGS_SUBSCRIPTIONS, ROUTE_PRODUCTS_COLLECTIONS, ROUTE_SETTINGS_GUIDELINE, ROUTE_SETTINGS_GUIDELINE_ADD, ROUTE_SETTINGS_GUIDELINE_UPDATE  ];

export const ROUTE_SYSTEM                               = "/system";
export const ROUTE_SYSTEM_CONNECTORS                    = "/system/connectors";
export const ROUTE_SYSTEM_CURRENCIES                    = "/system/currencies";
export const ROUTE_SYSTEM_LANGUAGES                     = "/system/languages";
export const ROUTE_SYSTEM_TAXRATES                      = "/system/taxrates";
export const ROUTE_SYSTEM_GROUPRATES                    = "/system/groupRates";
export const ALL_ROUTE_SYSTEM                           = [ ROUTE_SYSTEM, ROUTE_SYSTEM_CONNECTORS, ROUTE_SYSTEM_CURRENCIES, ROUTE_SYSTEM_LANGUAGES, ROUTE_SYSTEM_TAXRATES, ROUTE_SYSTEM_GROUPRATES];

export const ROUTE_LOGIN                                = "/login";
export const ROUTE_FORGET_PASSWORD                      = "/password/forget";
export const ROUTE_RESET_PASSWORD                       = "/password/reset";
export const ROUTE_PREFERENCES                          = "/preferences";
export const ROUTE_HELPS                                = "/helps";
export const ALL_ROUTE_LOGS                             = [ ROUTE_LOGIN, ROUTE_FORGET_PASSWORD, ROUTE_RESET_PASSWORD, ROUTE_PREFERENCES, ROUTE_HELPS ];

export const ROUTE_BUILDER                              = "/builder/:assetId/:pageId";
export const ROUTE_BUILDER_PREVIEW                      = "/builder-preview/:assetId/:pageId";
export const ROUTE_BUILDER_MODEL_PRESENTATION           = "/builder/presentation/model/:alias";
export const ROUTE_BUILDER_PRESENTATION                 = "/builder/presentation/:alias";
export const ROUTE_BUILDER_DEV                          = "/builder/dev/:page";
export const ROUTE_BUILDER_CMS_CONTENT                  = "/builder/cms/:assetId/:contentId";

export const ROUTE_BUILDER_HOMEPAGE_GAME                = "/game/homepage";
export const ALL_ROUTE_BUILDER                          = [ ROUTE_BUILDER, ROUTE_BUILDER_PREVIEW, ROUTE_BUILDER_HOMEPAGE_GAME, ROUTE_BUILDER_CMS_CONTENT ];


/*-----------------CRM------------------*/

export const ROUTE_CRM                                  = "/crm";

export const ROUTE_CRM_STATS                            ="/crm/stats"
export const ROUTE_CRM_STATS_CLIENTS                    ="/crm/stats/clients"
export const ROUTE_CRM_STATS_RESSOURCES                 ="/crm/stats/ressources"
export const ROUTE_CRM_STATS_CAMPAIGNS                  ="/crm/stats/campaigns"
export const ALL_ROUTE_CRM_STATS                        = [ROUTE_CRM_STATS,ROUTE_CRM_STATS_CLIENTS,ROUTE_CRM_STATS_RESSOURCES,ROUTE_CRM_STATS_CAMPAIGNS]

export const ROUTE_CRM_OFFERS                               ="/crm/offers"
export const ROUTE_CRM_OFFERS_LIST                          ="/crm/offers/list"
export const ROUTE_CRM_OFFERS_ADD                           ="/crm/offers/add"
export const ROUTE_CRM_OFFERS_EDIT                          ="/crm/offers/details/:id"
export const ROUTE_CRM_OFFERS_PROJECTS                      ="/crm/offers/projects"
export const ROUTE_CRM_OFFERS_MODELES                       ="/crm/offers/modeles/list"
export const ROUTE_CRM_OFFERS_MODELES_ADD                   ="/crm/offers/modeles/add"
export const ROUTE_CRM_OFFERS_MODELES_EDIT                  ="/crm/offers/modeles/details/:id"
export const ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS         ="/crm/offers/models-presentations/list"
export const ROUTE_CRM_OFFERS_PRESENTATIONS                 ="/crm/offers/presentations/list"
export const ROUTE_CRM_OFFERS_SETTINGS                      ="/crm/offers/settings"
export const ALL_ROUTE_CRM_OFFERS                           = [ROUTE_CRM_OFFERS_LIST, ROUTE_CRM_OFFERS, ROUTE_CRM_OFFERS_ADD, ROUTE_CRM_OFFERS_EDIT,ROUTE_CRM_OFFERS_PROJECTS,ROUTE_CRM_OFFERS_MODELES,ROUTE_CRM_OFFERS_MODELES_ADD,ROUTE_CRM_OFFERS_MODELES_EDIT, ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS, ROUTE_CRM_OFFERS_PRESENTATIONS]
export const ALL_ROUTE_CRM_OFFERS_SETTINGS                  = [ROUTE_CRM_OFFERS_SETTINGS]

export const ROUTE_CRM_MEDIAS                           ="/crm/medias";
export const ROUTE_CRM_MEDIAS_CATEGORIES                ="/crm/medias/categories";
export const ALL_ROUTE_CRM_MEDIAS                       = [ROUTE_CRM_MEDIAS,ROUTE_CRM_MEDIAS_CATEGORIES]


export const ROUTE_CRM_CONTACTS                         ="/crm/contacts";
export const ROUTE_CRM_CONTACTS_SOCIETIES               ="/crm/contacts/societies";
export const ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS       ="/crm/contacts/societies/:id";
export const ROUTE_CRM_CONTACTS_CLIENTS                 ="/crm/contacts/list/clients";
export const ROUTE_CRM_CONTACTS_LEADS                   ="/crm/contacts/list/leads";
export const ROUTE_CRM_CONTACTS_LIST                    ="/crm/contacts/list";
export const ROUTE_CRM_CONTACTS_DETAILS                 ="/crm/contacts/details/:id";
export const ROUTE_CRM_CONTACTS_ADD                     ="/crm/contacts/add";
export const ALL_ROUTE_CRM_CONTACTS                     =[ROUTE_CRM_CONTACTS,ROUTE_CRM_CONTACTS_SOCIETIES,ROUTE_CRM_CONTACTS_CLIENTS,ROUTE_CRM_CONTACTS_LEADS,ROUTE_CRM_CONTACTS_LIST,ROUTE_CRM_CONTACTS_DETAILS,ROUTE_CRM_CONTACTS_ADD];

export const ROUTE_CRM_SETTINGS                         ="/crm/settings";                       
export const ROUTE_CRM_SETTINGS_LANGUAGES               ="/crm/settings/languages";          
export const ROUTE_CRM_SETTINGS_USERS                   ="/crm/settings/users";
export const ROUTE_CRM_SETTINGS_DETAIL_USER             ="/crm/settings/user";
export const ROUTE_CRM_AGENCY                           ="/crm/settings/agencies"
export const ROUTE_CRM_PRICES                           ="/crm/settings/prices"
export const ROUTE_CRM_TAXES                            ="/crm/settings/taxes"
export const ROUTE_CRM_PAYMENT_TERMS                    ="/crm/settings/paymentTerms"
export const ROUTE_CRM_PAYMENT_DEADLINES                ="/crm/settings/paymentDeadlines"
export const ROUTE_CRM_CGV                              ="/crm/settings/cgv"
export const ALL_ROUTE_CRM_SETTINGS                     =[ROUTE_CRM_SETTINGS,ROUTE_CRM_SETTINGS_LANGUAGES,ROUTE_CRM_SETTINGS_USERS, ROUTE_CRM_AGENCY, ROUTE_CRM_PRICES, ROUTE_CRM_TAXES,ROUTE_CRM_PAYMENT_TERMS,ROUTE_CRM_PAYMENT_DEADLINES, ROUTE_CRM_CGV];    

export const ROUTE_FLIPBOOK                             ="/flipbook";
export const ROUTE_FLIPBOOK_RESULT                      ="/flipbook/result";
export const ROUTE_FLIPBOOK_GAME                        ="/flipbook/game";
export const ROUTE_FLIPBOOK_RECAP                       ="/flipbook/recap";

/*-----------------RESTAURANTS------------------*/
export const ROUTE_RESTAURANTS                          ="/restaurants/list";
export const ROUTE_RESTAURANTS_DETAILS                  ="/restaurants/detail/:id";
export const ROUTE_RESTAURANTS_DEMANDS                  ="/restaurants/demands";
export const ALL_ROUTE_RESTAURANTS                      = [ROUTE_RESTAURANTS, ROUTE_RESTAURANTS_DEMANDS, ROUTE_RESTAURANTS_DETAILS];