import React, { useEffect } from 'react';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import {listSettings,listMappers,perPageOptions} from './config/listBrands.config';
import formBrand from "./config/formBrand.config"
import Listing from '../../../layouts/Listing/Listing';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import * as formHelper from '../../../../js/helpers/form'
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import { getBrands, getOneBrand } from '../../../../queries/brands';

function ListBrands(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [openForm, setOpenForm] = React.useState(false);
    const [reloadBrands, setReloadBrands] =  React.useState(false);
    const [searchNameLocal, setSearchNameLocal] =  React.useState(null);
    const [searchName, setSearchName] =  React.useState(null);
    const [brands, setBrands] = React.useState(null);
    const [oneBrand, setOneBrand] = React.useState(null);
    const [errors, setErrors] =  React.useState({});
    const [seeErrors, setSeeError] =  React.useState(false);
    const [states, setStates] =  React.useState({
        brandId: null,
        brandImage:null,
        brandName: null,
        brandDesc: null,
        brandLink: null,        
        brandRows:[],
        brandEcartNegative :null,
        brandEcartPositive :null,                                
        mapOn:[],
        isActive: true,
        isActiveAlert:true
    });


    function initBrands(){
        // props.client.query({
        //     query: getBrands,
        //     fetchPolicy: "no-cache",
        //     variables: {}
        // }).then(result => {
        //     setBrands(result.data.productSources.edges);
        // }); 


        // props.client.query({
        //     query: getOneBrand,
        //     fetchPolicy: "no-cache",
        //     variables: {"id": "/api/product-sources/1"}
        // }).then(result => {            
        //     setOneBrand(result.data.productSource);
        // }); 


    }

    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {
        initBrands();
    }, [])

    const handleButtonGroupChange = (stateName, value) => {
        let getState = {...states};
        getState[stateName] = value;
        setStates(getState);
    };

    const goTo = (route) => {
        props.history.push({
            pathname: route,
        });
    };

    const handleToggleDrawer = () => {
        setOpenForm(!openForm)
    };

    const initForm=()=>{
        handleToggleDrawer('openForm');
    }

    const getBrandsCallback = () => {
        setReloadBrands(false)
    }
    const filterBrands = () => {
        setSearchName(searchNameLocal)
    };
    
    const handleNextStep = () =>{
        let result = formHelper.handleStep('next',errors);
        setSeeError(!result)        
        return result;
    }

    const handleMediaPicker=(selected,stateName)=>{        
        let getState = {...states};        
        getState[stateName] =  selected
        setStates(getState)

    }

    const handlerMutation = async () => {
        console.log('errors', errors)
        if (hasErrors()) {
            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }
        props.startLoading();
        let variables = null;        
        if (true){        
            variables={                
                libelle: states.brandName, 
                description: states.brandDesc,                
                media:states.brandImage?.id,
                status:states.isActive,
                isDisplay:true,
                isBrand:true,                
                activeAlert: states.isActiveAlert,
                link: states.brandLink,
                brandEcartPositive:states.brandEcartPositive,
                brandEcartNegative:states.brandEcartNegative
            }
            try{
                let createBrand = await saveElement('productSource', variables, {enableLoad:false, setDates:false})
                let createSource
                if (createBrand){                    
                    Promise.all(states.brandRows.map(async(row)=>{
                        let scrapVariables = {
                            name:row.code,
                            link:row.code,
                            codeScrap:row.code,
                            productSource:createBrand.id,
                        }
                        try {
                            createSource = await saveElement('productSourceScrap', scrapVariables, {enableLoad:false, setDates:false})                                                                                                                
                            Object.keys(states.mapOn).map(async(m)=>{
                                if (row.code === states.mapOn[m]) {
                                    let mapVariables = {
                                        productSource:createBrand.id,
                                        attribute:m,
                                        productSourceScrap:createSource.id,
                                    }
                                    try {
                                        await saveElement('productSourceMapping', mapVariables, {enableLoad:false, setDates:false})                                                                                    
                                    } catch (error) {
                                        props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                                        console.log(error)
                                    }
                                }                                
                            })                        
                        } catch (error) {
                            props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                            console.log(error)
                        }
                    }))
                }
                setReloadBrands(true)
                setOpenForm(!openForm)
                props.snack(ALERT_SUCCESS, 'Marque ajoutée avec succès');
            }catch(err){
                props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la marque');
                console.log(err)
            }
        }
        props.stopLoading();
    };


    const stateCallback = (stateName, value, custom, translated, callback) => {        
        let getState = {...states};        
        getState[stateName] =  value?.target?.value ?? value;
        setStates(getState,callback)
    };


    const handleFormError = (stateName, error) => {
        let getErrors = errors;        
        getErrors[stateName] = error;
        setErrors(getErrors);
    };
    const hasErrors = () => {
        if (errors) {
            for (let error in errors) {
                if (errors[error])
                    return true;
            }
        }
        return false;
    };

    return (
        <div>
            <TopPanel
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t('products.brands.title')}
                subtitle={props.t('products.brands.subtitle')}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                handlerAdd={() => { initForm() }}
                textAdd={props.t("products.brands.addBrand")}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container direction='column' style={{flex: 1}}>
                <AccordionCustom defaultExpanded={false} expanded={false} title={"Filtres et Recherche"} custommargin={"16px 22px 22px"} style={{ borderRadius: 0 }}>
                    <Grid container spacing={2} alignItems={"flex-end"}>
                        <Grid item style={{flex: 1}}>
                            {/* Search by name */}
                            <SearchBar noIcon
                                label={"Recherche par nom"}
                                variant="standard"
                                underlined={false}
                                style={{
                                    margin: '0',
                                    width: '100%',
                                    background: 'white',
                                }}
                                onChange={(e) => setSearchNameLocal(e.target.value.trim())}
                                value={searchNameLocal}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end">
                                {
                                    searchNameLocal ?
                                        <Button onClick={() => {setSearchName(null); setSearchNameLocal(null);}} color={colors.red.darker} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: 0, border: `1px solid ${colors.red.darker}` }}>Effacer</Button>
                                    : null
                                }
                                <Button onClick={() => {
                                    filterBrands()
                                }} style={{ marginBottom: 0, borderRadius: 0, marginTop: 0 }}>Filtrer</Button>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    
                </AccordionCustom>
                <Grid container style={{marginTop: 16}}>
                    <Listing
                        label = 'marques'
                        settings = {listSettings}
                        cardProps = {{
                            openForm : openForm,
                            currentLang : currentLang,
                            textButton:'Voir la marque',
                            handlerButton: goTo,
                            windowWidth: props.windowWidth, 
                        }}
                        perPageOptions = {perPageOptions}
                        mappers = {listMappers}
                        currentLang = {currentLang}
                        identifier = 'productSources'
                        queryVariables={{
                            // statut: searchStatus !== "-1" ? searchStatus === "active" ? true : false : null,
                            libelle: searchName
                        }}
                        propsToPass = {props}
                        viewsOptions = {{
                            current : 'card',
                            settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={reloadBrands}
                        listingCallback = {getBrandsCallback}
                    />
                </Grid>       
                <LayoutBuilder
                    opened={openForm} 
                    forClose={()=>handleToggleDrawer()} 
                    handlerSetup={()=>{}} 
                    dataLayout={formBrand("add",handleMediaPicker, states)} 
                    allState={states}    
                    stepperButtonAction={[
                        () => {
                            if (hasErrors()) {
                                props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                setStates({...states, seeErrors: true });
                                eventService.fire();
                                return false;
                            }

                            handleNextStep()

                            return true;
                        },
                        handleNextStep, 
                        handleNextStep,
                    ]}
                    stateCallback={stateCallback} 
                    errorCallback={handleFormError}    
                    checkError={() => {}}
                    // // // handleButtonGroupChange={this.handleButtonGroupChange}
                    // validateButton={true}
                    handlerMutation={handlerMutation}
                    handleButtonGroupChange={handleButtonGroupChange}
                />         
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListBrands)))));