import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { getElements } from "../../../js/utils/functions";
import PageLoader from "../../ui/loadings/page-loader/PageLoader";
import TableViewNew from "./components/TableView/TableViewNew";
import { itemMapper } from "../../../js/mappers/mapper";
import { connect } from "react-redux";
import { setItemsLocalStorage } from "../../../js/helpers/filters";
import ProductsFilters from "./components/Filters/ProductsFilters";
import SimpleFilters from "./components/Filters/SimpleFilters";
import request from "../../../js/utils/fetch";
import Actions from "./components/Actions";
import GridViewNew from "./components/GridView/GridViewNew";

const ListingNew = (props) => {
  const {
    t,
    storage,
    storageName,
    queryVariables,
    identifier,
    listingCallback,
    currentLang,
    setCurrentLang,
    config,
    selectionIsActive,
    setSelectionIsActive,
    selectedProducts,
    setSelectedProducts,
    handleSelection,
    refresh,
    setRefresh,
    cardProps,
    additionalAction,
    tableProps,
    dragCallback,
    restUrl,
  } = props;
  const [isFilterInit, setisFilterInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noResult, setNoResult] = useState(false);
  const [pagination, setPagination] = useState(
    storage?.pagination || {
      page: 1,
      itemsPerPage: config?.perPage?.options?.[0] || null,
      viewMode: config?.views?.default || "card",
      order: config?.order?.default || "desc",
      count: 0,
    }
  );
  const [formattedResults, setFormatedResults] = useState({
    card: [],
    list: [],
  });
  const [activeFilter, setActiveFilter] = useState("sku");
  const [filters, setFilters] = useState(storage?.filters || null);

  const mapItems = async (items) => {
    const result = await itemMapper(config?.mappers, items, currentLang);
    return result;
  };

  useEffect(() => {
    if (refresh) {
      handleGetItems();
    }
  }, [refresh]);

  const setVariablesOnRest = (variables) => {
    let url = "&";
    const obj = { ...variables };
    if (config?.order) {
      obj[config?.order?.stateName] = pagination.order;
    }
    Object.keys(obj).forEach((key, index) => {
      if (obj[key] !== "all") {
        if (index === Object.keys(obj).length - 1) {
          url += `${key}=${obj[key]}`;
        } else {
          url += `${key}=${obj[key]}&`;
        }
      }
    });
    return url === "&" ? "" : url;
  };

  const handleGetItems = async () => {
    try {
      setLoading(true);
      const variables = {
        ...filters,
        ...queryVariables,
        localeId: props.locales.find((e) => e.node.code === currentLang)?.node
          .id,
        itemsPerPage: pagination.itemsPerPage,
        page: pagination.page,
      };
      let result = [];
      let formatedItems = [];
      let count;

      if (restUrl) {
        try {
          const response = await request(
            `${process.env.REACT_APP_API}/${restUrl}.jsonld?page=${
              pagination.page
            }${
              pagination.itemsPerPage
                ? `&itemsPerPage=${pagination.itemsPerPage}`
                : ""
            }${setVariablesOnRest({
              ...filters,
              ...queryVariables,
            })}`
          );

          result = response["hydra:member"];
          count = response["hydra:totalItems"];
          formatedItems = await mapItems(result);
        } catch (error) {
          console.error("error", error.message);
        }
      } else {
        if (config?.order) {
          if (config?.order?.stateName) {
            variables[config?.order?.stateName] = pagination.order;
          } else {
            variables.order = pagination.order;
          }
        }
        if (variables?.productDatas) {
          variables.productDatas = variables.productDatas?.map((e) =>
            JSON.stringify(e)
          );
        }
        const { data } = await getElements(identifier, variables);
        result = data[identifier]?.edges;
        count = data[identifier]?.totalCount;
        formatedItems = await mapItems(result);
      }

      if (listingCallback)
        listingCallback(count, formatedItems[pagination?.viewMode]);

      setFormatedResults(formatedItems);
      setPagination((prevState) => {
        return {
          ...prevState,
          count,
        };
      });
      setNoResult(result?.length === 0);
      setLoading(false);
      setRefresh(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLang = async (code) => {
    setCurrentLang(code);
  };

  const initFilters = async () => {
    if (!filters && config?.filters) {
      const obj = {};
      config?.filters?.options.forEach((option, i) => {
        if (option.defaultValue && !filters?.[option.stateName]) {
          if (option.defaultValue !== "all") {
            obj[option.stateName] = option.defaultValue;
          }
        }
        if (i === config?.filters?.options.length - 1) {
          setFilters(obj);
        }
      });
    } else {
      handleGetItems();
    }
    setisFilterInit(true);
  };

  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    if (isFilterInit) {
      setPagination((prevState) => {
        return {
          ...prevState,
          page: 1,
        };
      });
      handleGetItems();
    }
  }, [filters]);

  useEffect(() => {
    if (isFilterInit) {
      handleGetItems();
    }
  }, [pagination.itemsPerPage, pagination.page, pagination.order, currentLang]);

  useEffect(() => {
    if (storageName) {
      setItemsLocalStorage(storageName, {
        ...storage,
        pagination,
        lang: currentLang,
        filters: filters,
      });
    }
  }, [pagination, currentLang, filters]);

  return (
    <Grid container style={{ gap: 10 }}>
      {config?.filters ? (
        config?.filters?.type === "product" ? (
          <ProductsFilters
            config={config}
            filters={filters}
            setFilters={setFilters}
            currentLang={currentLang}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        ) : (
          <SimpleFilters
            config={config}
            filters={filters}
            setFilters={setFilters}
          />
        )
      ) : null}
      {(config?.views || config?.perPage || config?.order || config?.lang) && (
        <Actions
          config={config}
          pagination={pagination}
          setPagination={setPagination}
          currentLang={currentLang}
          setCurrentLang={handleLang}
          selectionIsActive={selectionIsActive}
          setSelectionIsActive={setSelectionIsActive}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          items={formattedResults?.[pagination?.viewMode]}
          filters={filters}
          setFilters={setFilters}
          handleSelection={handleSelection}
          additionalAction={additionalAction}
        />
      )}
      {!loading || refresh ? (
        noResult ? (
          <Grid item xs={12}>
            <Typography variant={"body1"}>{t("common.noResult")}</Typography>
          </Grid>
        ) : pagination?.viewMode === "card" ? (
          <GridViewNew
            config={config}
            items={formattedResults?.card}
            currentLang={currentLang}
            cardProps={cardProps}
          />
        ) : (
          pagination?.viewMode === "list" && (
            <TableViewNew
              items={formattedResults?.list}
              config={config}
              currentLang={currentLang}
              selectionIsActive={selectionIsActive}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              tableProps={tableProps}
              dragCallback={dragCallback}
            />
          )
        )
      ) : (
        <PageLoader />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    locales: state.locales,
    attributes: state.attributes,
  };
};

export default withTranslation()(connect(mapStateToProps)(ListingNew));
