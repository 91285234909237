import React from "react";
import { IconButton } from "@material-ui/core";
import Tooltip from "../tooltip/Tooltip";

/**
 * https://mui.com/material-ui/api/icon-button/
 */
const OurIconButton = ({
  tooltip,
  tooltipPlacement = "bottom",
  children,
  disabled = false,
  ...props
}) => {
  return !disabled ? (
    <Tooltip title={tooltip ?? ''} placement={tooltipPlacement}>
      <IconButton {...props}>
        {children}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton disabled={disabled} {...props}>
      {children}
    </IconButton>
  );
};

export default OurIconButton;
