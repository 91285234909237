export const IMAGES     = ["image/png","image/jpeg","image/jpg","image/gif", "image/svg+xml", "image/svg"];
export const VIDEOS     = ["video/mpeg","video/webm","video/mp4"];
export const AUDIOS     = ["audio/mpeg"];
export const CSV        = ["csv","text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
export const DOCUMENTS  = 
    [
        "application/pdf",
        //Text (Word)
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.oasis.opendocument.text",
        //Presentations (Powerpoint)
        "application/vnd.ms-powerpoint",
        "application/vnd.oasis.opendocument.presentation",
        //Tableur (Excel)
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
        // Audios(MPEG)
        "audio/mpeg",
    ].concat(CSV);
export const DOCUMENTS_ONLY  = 
    [
        "application/pdf",
        //Text (Word)
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.oasis.opendocument.text",
        //Presentations (Powerpoint)
        "application/vnd.ms-powerpoint",
        "application/vnd.oasis.opendocument.presentation",
        //Tableur (Excel)
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet",
    ];
export const OTHERS = [
    "zip",
    "xlsx"
]
export const LIST_MEDIAS    = IMAGES.concat(VIDEOS,DOCUMENTS_ONLY,AUDIOS);
export const ALLOWED    = IMAGES.concat(VIDEOS,DOCUMENTS);
export const ALLOWED_IN_PRODUCT    = IMAGES.concat(VIDEOS,DOCUMENTS_ONLY);