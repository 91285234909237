import { withTranslation } from "react-i18next";
import { Grid, Divider, Checkbox, Box } from "@material-ui/core";
import styled from "styled-components";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import _ from "lodash";

import ChangeViewNew from "./ChangeViewNew";
import SortView from "./SortView";
import colors from "../../../../config/theme/colors";
import OurIconButton from "../../../ui/icon-button/OurIconButton";
import ReturnBtn from "../../../ui/link/ReturnBtn";
import TraductionSelect from "../../TopPanel/TraductionSelect";

const TablePaginationStyle = styled(Pagination)`
  .MuiPaginationItem-root {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
  }
  .Mui-selected {
    background-color: #fff;
    border: 1px solid ${colors.grey.border};
    font-weight: bold;
    line-height: 8px;
  }
  svg {
    font-size: 1.5rem;
  }
  .MuiToolbar-root {
    padding: 0;
  }
  .MuiTablePagination-spacer {
    display: none;
  }
`;

const Actions = (props) => {
  const {
    t,
    config,
    pagination,
    setPagination,
    locales,
    currentLang,
    setCurrentLang,
    selectionIsActive,
    setSelectionIsActive,
    items,
    selectedProducts,
    setSelectedProducts,
    handleSelection,
    additionalAction,
  } = props;

  const handlePage = (e, page) => {
    setPagination((prevState) => {
      return {
        ...prevState,
        page,
      };
    });
  };

  const handleProducts = () => {
    if (selectionIsActive) {
      if (selectedProducts?.length === items?.length) {
        setSelectedProducts([]);
      } else {
        setSelectedProducts(items?.map((e) => e.id));
      }
    } else {
      setSelectionIsActive(true);
      setPagination((prevState) => {
        return {
          ...prevState,
          viewMode: "list",
        };
      });
      setSelectedProducts(items?.map((e) => e.id));
    }
  };

  return (
    <Grid container direction="row">
      <Grid container item xs style={{ width: "auto", gap: 10 }}>
        {config?.return ? (
          <Grid item>
            <ReturnBtn />
          </Grid>
        ) : null}
        {(config?.views?.options?.length > 0 ||
          config?.perPage?.options?.length > 0) && (
          <Grid item>
            <ChangeViewNew
              config={config}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Grid>
        )}
        {config?.order ? (
          <Grid item>
            <SortView
              config={config}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Grid>
        ) : null}
        {config?.perPage?.options?.length > 0 && (
          <Grid item>
            <TablePaginationStyle
              showFirstButton
              showLastButton
              count={
                Math.ceil(pagination?.count / pagination?.itemsPerPage) || 1
              }
              page={pagination?.page}
              onChange={handlePage}
            />
          </Grid>
        )}
        {config?.selection && (
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Divider
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
              flexItem
              orientation="vertical"
            />
            <Checkbox
              style={{
                color: colors.black.regular,
                padding: 0,
              }}
              onClick={handleProducts}
              checked={
                items?.length > 0
                  ? _.isEqual(
                      items?.map((e) => e.id),
                      selectedProducts
                    )
                  : false
              }
            />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                color: colors.blue.darker.hue300,
                width: selectedProducts?.length > 0 ? "100%" : "0%",
                transition: "width 0.1s linear",
                overflow: "hidden",
              }}
            >
              <Divider flexItem orientation="vertical" />
              {config?.selection?.options.map((option, i) => {
                return (
                  <OurIconButton
                    onClick={() => handleSelection(option?.value)}
                    tooltip={t(option.label)}
                    style={{
                      padding: 5,
                      color: colors.blue.darker.hue300,
                    }}
                  >
                    <option.icone style={{ cursor: "pointer", fontSize: 21 }} />
                  </OurIconButton>
                );
              })}
            </Box>
          </Grid>
        )}
      </Grid>
      {additionalAction && (
        <Grid item xs={"auto"}>
          {additionalAction}
        </Grid>
      )}
      {config?.lang && (
        <Grid
          item
          xs={"auto"}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TraductionSelect
            currentLang={currentLang}
            handleLang={setCurrentLang}
            locales={locales}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    locales: state.locales,
  };
};

export default withTranslation()(connect(mapStateToProps)(Actions));
