import { useEffect, useState } from "react"
import TopPanel from "../../../layouts/TopPanel/TopPanel"
import colors from "../../../../config/theme/colors"
import { ROUTE_CONTENU_ADD, ROUTE_CONTENU_LIST } from "../../../../js/constants/route-names"
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, TextField } from "@material-ui/core"
import TraductionSelect from "../../../layouts/TopPanel/TraductionSelect"
import { eventService } from "../../../../js/services/event.service"
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types"
import { withTranslation } from "react-i18next"
import { withApollo } from "react-apollo"
import { withRouter } from "react-router"
import { connect } from "react-redux";
import Typography from '../../../ui/typography/Typography';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import request from '../../../../js/utils/fetch';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader"
import styled from "styled-components"
import OurTooltip from "../../../ui/tooltip/Tooltip"
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import { DELETE_CONTENT, SEARCH_CONTENTS_SKU_BY_CAT } from '../../../../queries/contents';
import Listing from "../../../layouts/Listing/Listing"
import { listMappers, listSettings, perPageOptions } from "./config/listContents.config"
import EmptyCard from "../../../ui/empty-card/EmptyCard"
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import importProductsConfig from './config/importProducts.config';
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BreadcrumbCustom from "../../../ui/breadcrumb/Breadcrumb"
import { CARD } from "../../../../js/constants/form-types"
import OurButton from "../../../ui/button/Button"
import PlaceholderModel from '../../../../assets/pictos/icono-tempalte.svg'
import CustomSearch from "../../../ui/search/CustomSearch"
import { GET_CONTENTS_SEARCH_PAGINATION } from "../../../../queries/content_categories"
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom"

const FolderContainer = styled(Grid)`
    display: grid;
    grid-template-columns:
        ${props => props.windowWidth > 1500 ? 'repeat(auto-fill, minmax(20%, 1fr))' // 4 columns
        : props.windowWidth > 765 ? 'repeat(auto-fill, minmax(25%, 1fr))' // 3 columns
            : 'repeat(auto-fill, minmax(33%, 1fr))'}; // 2 columns
    width: 100%;
    gap: 17px;
    margin-top: 9px;
`

const FolderCustom = styled(Grid)`
    background-color: ${colors.white};
    border: 0.5px solid ${colors.grey.lighter.hue700};
    padding: 10px 15px;
    color: ${colors.black.regular};
    font-weight: bold;
    height: 52px;
    gap: 10px;
    cursor: pointer;
    '&:hover': {
        background-color: ${colors.grey.lighter.hue900};
    }
`

const PageWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    position: relative;
    min-height: calc(100% - 64px);

    & > .layout-wrapper {
        display: none;
    }
`;

const ListContents = (props) => {
    const [categories,setCategories]=useState([])
    const [categorieIsLoaded,setCategoriesLoaded]=useState(false)

    const [skuList,setSkuList]=useState([])
    const [skuListIsLoaded,setSkuListIsLoaded]=useState(false)
    const [currentFolder,setCurrentFolder]=useState(null)
    const [previousFolders,setPreviousFolders]=useState([])
    const [openForm,setOpenForm]=useState(false)
    const [openFormModel,setOpenFormModel]=useState(false)
    const [selectedModel,setSelectedModel]=useState(null)
    const [error,setError]=useState(false)
    const [currentLang,setCurrentLang]=useState(props.locales[1].node.code)
    const [state,setState]=useState({
        headers: null,
        importFile: null,
        importSep: ';',
        importValues: {},
        importLang: props.locales[0].node.id
    })
    const [filters,setFilters]=useState({
        name:null
    })
    const [models,setModels]=useState([])

    const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

    useEffect(()=>{                        
        const savedCategory =JSON.parse(localStorage.getItem('CONTENT_CATEGORY'))
        if (savedCategory) {
            setCurrentFolder(savedCategory.currentFolder)
            setPreviousFolders(savedCategory.previousFolders)
        }
    },[])

    const goTo = (route, state) => {
        props.history.push({
            pathname: route,
            state: state
        });
    };

    const handleChange=(e, value) =>{
        clearTimeout(this.typingSearchTimer);
        if (e?.target?.value) {
            this.setState({ searchValue: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearch();
            }, 500);
        }
        else if (value) {
            this.setState({
                searchValue: e.target.value
            }, this.handleSearch);
        }
        else {
            this.setState({
                skulist: [],
                noResult: false,
                searchValue: ''
            }, () => {
                if (this.state.activeCategorie.length <= 1) {
                    this.handleGetContents();
                }
                else {
                    this.handleChangeTab(null, null, this.state.idActiveCat);
                }
            });
        }
    }

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const handleGetCategories=()=> {
        setCategoriesLoaded(false)
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/categories-list/content`, 'get').then(
                (data) => {
                    if (data.success && data.datas.length > 0) {                        
                        setCategories(data.datas)
                        setCategoriesLoaded(true)
                    }else{
                        setError(true)
                        setCategoriesLoaded(true)
                    }
                    resolve();
                }
            );
        });
    }


    const getSkuList=() =>{
        setSkuListIsLoaded(false)                        
        props.client.query({
                query: SEARCH_CONTENTS_SKU_BY_CAT,                    
                variables:{
                    "idlist": currentFolder.id,                         
                },
                fetchPolicy: 'no-cache'
            }).then(result => {   
                let list =[]
                if (result?.data?.contentCategories[0]?.contents?.edges?.length === 0) {
                    list = []
                }else{
                    result?.data?.contentCategories[0]?.contents?.edges?.map((item)=>{                                            
                        list.push(item.node.sku)
                    })
                }           
                setSkuList(list)      
                setSkuListIsLoaded(true)                                            
            })        
    }

    const handlerMutation = async () => {
        props.startLoading();

        let importConfig = {
            "url": state.media.contentUrl,
            "mapper": state.mapper,
            "eavType": props.attributes.eavTypes.find(e => e.node.code === 'content').node.id,
            "locale": state.importLang,
            "delimiter": state.importSep
        };

        try {
            // todo thomas
            await request(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
            props.snack(ALERT_SUCCESS, props.t("content.content.importMediaSuccess"), 6000);
            setOpenForm(!openForm)
            props.stopLoading();
        } catch (e) {
            props.snack(ALERT_ERROR, props.t("content.content.importMediaFailed"));
            props.stopLoading();
        }
    };


    const resetContents =()=>{
        setCurrentFolder(null)
    }

    useEffect(()=>{        
        if (!currentFolder) {
            setSkuList([])
            handleGetCategories()
        }else{
            getSkuList()
            const savedCategory =JSON.parse(localStorage.getItem('CONTENT_CATEGORY'))
            let models = props.attributeGroups.filter(e => (!e.node.isSystem && e.node.isForContent) || e.node.identifier === 'content')    
            const getAttributesList = (e)=>{
                return `Ajouter un contenu comprenant : ${e?.node?.attributes?.edges?.map((item)=>{
                    return ` ${item?.node?.translation?.translationDatas?.edges[0]?.node?.value?.toLowerCase()}`
                })}`
            }
            let newModels = models.map((e)=>{
                return {
                    'libelle':e.node.translation.translationDatas.edges[0]?.node.value,
                    'picto': PlaceholderModel,
                    'textButton': "Créer le contenu",
                    'description': getAttributesList(e),
                    'onClick': () => goTo(ROUTE_CONTENU_ADD, {model:e.node.id,currentFolder:currentFolder})
                }
            })
            setModels(newModels)
        }        
    },[currentFolder])

    useEffect(()=>{                
        saveCategoryOnLocalStorage()
    },[currentFolder])   

    const saveCategoryOnLocalStorage = () => {        
        if (currentFolder) {
            const objectToSave = {
                currentFolder,
                previousFolders
            }
            localStorage.setItem('CONTENT_CATEGORY',JSON.stringify(objectToSave));    
        }else{
            localStorage.removeItem('CONTENT_CATEGORY');            
        }
    }

    const stateCallback = async (stateName, value, custom, translated, callback,isGroup) => {
        if (isGroup) {
            setState({
                ...state,
                ...value
            })
        }else{
            setState({
                ...state,
                [stateName]: value?.target?.value ?? value
            })
        }
        if (callback) {
            callback()
        }
    };
    
    // Click on breadcrumb
    const getClickedCategory = (id) => {        
        if (id) {
            const categoryIndex = previousFolders.findIndex(folder => folder?.id === id)            
            setCurrentFolder(previousFolders[categoryIndex])
            setPreviousFolders(previousFolders.slice(0, categoryIndex))
        } else {            
            setCurrentFolder(null)
            setPreviousFolders([])
        }
    }

    // Click on Folder
    const getPreviousFolders = (category) => {
        if(previousFolders.length <= 1){
            const parentCategory = categories.find(folder => folder.id === category.parent)
            if (parentCategory) {
                previousFolders.push(parentCategory)
            }else{
                previousFolders.push(null)
            }
        } else {
            previousFolders.push(currentFolder)
        }
    }

    const deleteMutation = (id) => {
        let query = null;
        let variables = null;

        props.startLoading()

        query = DELETE_CONTENT;
        variables = { id: id };

        request(`${process.env.REACT_APP_API}/wordpress/content/delete/${id.replace('/api/contents/', '')}`, 'get').then(
            (data) => {
                if (data.success) {
                    console.log("supprimé de WP");
                }
            }
        );

        props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            getSkuList()
            props.snack(ALERT_SUCCESS, "Le contenu a bien été supprimé", 6000);
            props.stopLoading()            
        })
    };

    useEffect(() => {
        if (debouncedSearchValue !== "") {
            getSearchData(debouncedSearchValue);
        } else if (currentFolder && debouncedSearchValue === "") {
            getSkuList()
        }
    }, [debouncedSearchValue]);

    const getSearchData = (value) => {
        props.client
          .query({
            query: GET_CONTENTS_SEARCH_PAGINATION,
            variables: {
              contentDatas: [
                JSON.stringify({
                  identifier: "content_title",
                  value: value,
                  locale: props.currentLang,
                  option: null,
                }),
              ],
              page: 1,
              itemsPerPage: 1000,
            },
            fetchPolicy: "no-cache",
          })
          .then((result) => {
            let list = [];
            if (result?.data?.researchContents?.edges?.length === 0) {
              list = [];
            } else {
              result?.data?.researchContents?.edges?.forEach((item) => {
                list.push(item.node.sku);
              });
            }
            setSkuList(list);
          });
      };

    return (
        <PageWrapper>
            <TopPanel
                icomoon="picto-content"
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("content.content.manageContents")}
                subtitle={props.t("content.content.manageContentSubtitle")}
                // handlerAdd={() => goTo(ROUTE_CONTENU_ADD)}
                handlerAdd={()=>setOpenFormModel(!openFormModel)}
                textAdd={props.t("content.content.addContent")}
                handlerImport={() => setOpenForm(!openForm)}
                textImport={props.t("content.content.importContent")}
                searchHandler={handleChange}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                windowWidth={props.windowWidth}
                openForm={openForm}
                buttonAvailable={categorieIsLoaded}
                hasBorder={true}
            />

            <Grid container style={{
                display: 'grid',
                gridTemplateRows: 'auto auto auto 1fr'
            }}>
                {/* Filtres */}
                <Grid item xs={12} style={{
                    marginBottom: 10,
                }}>
                    <AccordionCustom 
                        defaultExpanded={false}
                        title={
                            <Typography variant={"h4"} style={{fontSize: 14, fontWeight: '600'}}>
                                {props.t('products.list.filter.filterSearch')}
                            </Typography>
                        }
                        custompadding={"10px 15px !important"}
                    >
                        <CustomSearch 
                            debouncedSearchValue={debouncedSearchValue}
                            setDebouncedSearchValue={setDebouncedSearchValue}
                        />
                    </AccordionCustom>
                </Grid>
                <Grid item xs={12}>
                    <TraductionSelect
                        currentLang={currentLang}
                        handleLang={handleLang}
                        locales={props.locales}
                    />
                </Grid> 

                {debouncedSearchValue !== "" ? 
                    skuList.length > 0 ? (
                        <Listing
                            label='contents'
                            settings={listSettings}
                            perPageOptions={perPageOptions}
                            mappers={listMappers}
                            pagination={true}
                            identifier='contents'
                            currentLang={currentLang}
                            propsToPass={{
                                actionButtonDelete:deleteMutation
                            }}
                            queryVariables={{
                                "nbperpage": 12,
                                "skulist": skuList,              
                            }}
                            viewsOptions = {{
                                current : 'card',
                                settings : ['card'] // Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                            }}
                        />)
                    : (
                        <Typography style={{
                            margin : "0"
                        }}>
                            Aucun résultats
                        </Typography>
                    )
                 : 
                 (
                    <>
                        <Grid item xs={12} style={{marginBottom:18, marginTop: 18}}>                                                        
                        {
                            categories.length > 0 ?
                                <BreadcrumbCustom 
                                    currentFolder={currentFolder}
                                    previousFolders={previousFolders}
                                    rootName={'CX Plateforme'}
                                    windowWidth={props.windowWidth}
                                    getClickedCategory={getClickedCategory}
                                    nbPreviousItems={4}
                                    separator={<ArrowForwardIosSharpIcon fontSize='small' />}
                                    size={25}
                                />
                            : null
                        }
                        </Grid>
                        {currentFolder?.childrens?.length > 0 || !currentFolder ?
                            <Box>
                                <Grid item xs={12}>
                                    {
                                        categorieIsLoaded ?
                                            <FolderContainer container windowWidth={props.windowWidth}>                                        
                                                {
                                                    (!currentFolder 
                                                        // Root
                                                        ? (categories && categories.length > 0) ? categories : []
                                                        // Subfolders
                                                        : currentFolder.childrens
                                                    )?.map((category, i) => {   
                                                        const name = category.categoryDatas?.find(e=>e?.attribute ===  "/api/attributes/11" && e?.locale === props.locales?.find(e=>e?.node?.code === currentLang)?.node?.id)?.value || category.libelle
                                                            return (                                                    
                                                                <FolderCustom
                                                                    style={{
                                                                        display: 'flex',
                                                                        height: '80px',
                                                                        padding: '0px 25px'
                                                                    }}
                                                                    key={`cat-${category.id}`}
                                                                    alignItems={'center'}
                                                                    onClick={() => {
                                                                        getPreviousFolders(category)
                                                                        setCurrentFolder(category)
                                                                    }}
                                                                >
                                                                    <Grid item>
                                                                        <FolderSharpIcon style={{ marginTop: '3px', fontSize: '28px' }} />
                                                                    </Grid>
                                                                    <Grid item
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            whiteSpace: 'nowrap',
                                                                            textOverflow: 'ellipsis',
                                                                            fontSize: '20px'
                                                                        }}
                                                                    >
                                                                        {name} {category?.contentsTotal !== 0 ? `(${category?.contentsTotal})`:null }
                                                                    </Grid>
                                                                </FolderCustom>
                                                            );
                                                    })
                                                }
                                            </FolderContainer>                                        
                                        : null
                                    }
                                </Grid>
                            </Box>
                        : null}
                        <Grid item container xs={12} spacing={2} style={{paddingTop:20, height: '100%', position: 'relative'}}>
                            {
                                currentFolder ?                            
                                    skuListIsLoaded ?
                                        skuList.length > 0 ?
                                            <Listing
                                                label='contents'
                                                settings={listSettings}
                                                perPageOptions={perPageOptions}
                                                mappers={listMappers}                                
                                                pagination={true}
                                                identifier='contents'
                                                currentLang={currentLang}
                                                propsToPass={{
                                                    actionButtonDelete:deleteMutation
                                                }}
                                                queryVariables={{
                                                    "nbperpage": 12, 
                                                    "skulist": skuList,                
                                                }}                            
                                                viewsOptions = {{
                                                    current : 'card',
                                                    settings : ['card'] // Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                                }}
                                            />                        
                                        : currentFolder?.childrens.length === 0 ?
                                            (<Typography style={{
                                                margin : "0"
                                            }}>
                                                Aucun résultats
                                            </Typography>)
                                        : null
                                    : null
                                :null
                            }
                        </Grid>   
                        {error ?
                            (<EmptyCard title={"Aucun résultat pour cette recherche"} textButton={ "Ajouter un contenu"} onClick={()=>setOpenFormModel(!openFormModel)} picto={EmptyProduct}  mtImg={2} />)
                        :null}   
                    </>
                 )
                }          
            </Grid>
            <LayoutBuilder
                isSublayout={true}
                opened={openForm}
                icomoon={'ico-import-fichier'}
                forClose={() => setOpenForm(false)}
                dataLayout={importProductsConfig}
                // drawerWidth={props.drawerWidth}
                // handleCancel={handleCancel}
                handlerMutation={handlerMutation}
                allState={state}
                stateCallback={stateCallback}
                // stepperButtonDisabled={[() => state.headers === null, null]}
                stepperButtonAction={[null, null]}
                backStepperButtonAction={[null, null, null]}                    
            />
            <LayoutBuilder
                isSublayout={true}
                opened={openFormModel}
                icomoon={'ico-import-fichier'}
                forClose={() => setOpenFormModel(false)}
                dataLayout={        {
                    langSelect: false,
                    drawerType: 'swipeable',
                    noForm: true,
                    component: OurButton,
                    formConfig: {
                        type: CARD,
                    },
            
                }}
                dataCard={models}
                // drawerWidth={props.drawerWidth}
                // handleCancel={handleCancel}
                handlerMutation={handlerMutation}
                allState={state}
                stateCallback={stateCallback}
                // stepperButtonDisabled={[() => state.headers === null, null]}
                stepperButtonAction={[null, null]}
                backStepperButtonAction={[null, null, null]}                    
            />
        </PageWrapper>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListContents))));