import { Grid } from "@material-ui/core";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";

// import ImgNotFound from "../../../../assets/images/image-fake.png";
import StatusInfo from "../../../../ui/status-info/StatusInfo";
import OurTypography from "../../../../ui/typography/Typography";
import colors from "../../../../../config/theme/colors";
import OurMenu from "../../../../ui/menu/Menu";
import OurButton from "../../../../ui/button/Button";
import { ROUTE_CONTENU_LIST_DETAIL } from "../../../../../js/constants/route-names";
import { goTo } from "../../../../../js/utils/goTo";
import ImgNotFound from "../../../../../assets/images/image-fake.png";

const CardContent = (props) => {
  const { t, item, deleteContent } = props;
  const history = useHistory();
  const { id, title, image, status, categorie } = item;
  return (
    <Grid
      container
      style={{
        padding: 10,
        gap: 15,
      }}
    >
      <Grid item xs={12}>
        <img
          style={{
            width: "100%",
            height: 250,
            objectFit: "cover",
            opacity: status ? 1 : 0.4,
          }}
          src={image || ImgNotFound}
          alt={title}
        />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs>
          <span
            style={{
              color: status ? colors.black.regular : colors.grey.disabled,
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {title}
          </span>
        </Grid>
        <Grid item xs={"auto"}>
          <OurMenu
            menuOptions={[
              {
                label: t("common.delete"),
                color: colors.red.regular,
                icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                action: (e) => {
                  e.stopPropagation();
                  deleteContent(id);
                },
              },
            ]}
            handleClick={(e, item) => item.action(e)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          gap: 5,
        }}
      >
        <Grid container item xs={12}>
          <Grid item xs>
            <OurTypography
              colortext={status ? colors.black.regular : colors.grey.disabled}
              variant={"body1"}
            >
              {t("products.list.cardproduct.status")}:{" "}
            </OurTypography>
          </Grid>
          <Grid item xs>
            <StatusInfo status={status} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs>
            <OurTypography
              colortext={status ? colors.black.regular : colors.grey.disabled}
              variant={"body1"}
            >
              {t("products.list.cardproduct.inCategory")}:{" "}
            </OurTypography>
          </Grid>
          <Grid item xs>
            <OurTypography
              fontweighttext={"bold"}
              colortext={status ? colors.black.regular : colors.grey.disabled}
              variant={"body1"}
            >
              {categorie}
            </OurTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <OurButton
          margin={"0px !important"}
          style={{ width: "100%" }}
          onClick={() =>
            goTo(
              history,
              ROUTE_CONTENU_LIST_DETAIL.replace(
                ":id",
                id.replace("/api/contents/", "")
              )
            )
          }
        >
          {t("content.content.seeContent")}
        </OurButton>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(CardContent);
