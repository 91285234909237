// React
import React, { useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";

// Material ui
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormatShapesIcon from "@material-ui/icons/FormatShapes";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Styles
import { ServerStyleSheet } from "styled-components";
import "./builder.scss";
import "./shareable/components/assets/minisite/scss/style.scss";
import "./shareable/components/assets/offer/scss/style.scss";
import "./shareable/components/shared/scss/style.scss";
import "swiper/css/swiper.min.css";

// Utils
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { CopyWithJSON } from "../js/utils/copy";
import colors from "../config/theme/colors";
import {
  refreshPhasesOnOffers,
  savePhases,
} from "../components/screens/dashboardCRM/offers/utils/OfferHelpers";
import { getTotals } from "../components/screens/dashboardCRM/offers/components/Calculator";
import { getDefaultData, getDefaultSlide } from "./utils/data";
import {
  getDefaultDotations,
  getDefaultDotationsBF,
  getDefaultDotationsMoreDetails,
  getDefaultRules,
  getDefaultRulesInlineFlex,
  getDefaultRulesBF,
  getDefaultSimpleSlide,
  getDefaultSocial,
  getDefaultSocialComplete,
  getDefaultScratchCard,
  getDefaultListCodes,
  getDefaultListProducts,
  getOfferStats,
  getOfferPole,
  getOfferLeader,
  getOfferInformations,
  getOfferListText,
  getDefaultSlideCategory,
  getDefaultSlideProduct,
  getDefaultGallery,
  getDefaultListEtapesQuiz,
  getDefaultListResultQuiz,
  getDefaultListImages,
  getDefaultListImagesWithHover,
} from "./utils/helpers";
import {
  getDefaultSlideTop,
  getDefaultSlideAbout,
  getDefaultSlideInstagram,
  getDefaultCategorieHome,
  getDefaultCollectionHome,
  getDefaultProductHome,
  getDefaultSlideImage,
  getDefaultSlidePresentationMedia,
  getDefaultSlideMoments,
  getDefaultDescriptionManifest,
  getDefaultListCategories,
  getDefaultCategorie2,
  getDefaultListCategoriesSubitem,
  getDefaultSlideByTab,
  getDefaultTabForSlider,
  getDefaultLinkCms,
  getDefaultTextareaCms,
  getDefaultTextarea,
  getDefaultAccordion
} from "./utils/helpersCms";
import { BlocTextMedia } from "./utils/config/cms";
import request from "../js/utils/fetch";

// GQL
import {
  GET_ASSET_BY_IDENTIFIER_BUILDER,
  UPDATE_ASSET,
} from "../queries/assets";
import { UPDATE_MINISITE_PAGE } from "../queries/asset_minisites";
import { UPDATE_GAME_PAGE } from "../queries/asset_games";
import {
  ADD_MODELE,
  UPDATE_MODELE,
  DELETE_MODELE,
} from "../queries/crm_modeles";
import {
  ADD_FOOTER_OFFER,
  ADD_OFFER,
  ADD_OFFER_FOOTER_TAX,
  DELETE_OFFER,
  UPDATE_FOOTER_OFFER,
  UPDATE_OFFER,
  UPDATE_OFFER_FOOTER_TAX,
} from "../queries/crm_offers";
import {
  GET_MODEL_PRESENTATION_BY_ALIAS,
  GET_PRESENTATION_BY_ALIAS,
  UPDATE_MODEL_PRESENTATION,
  UPDATE_PRESENTATION,
} from "../queries/crm_presentations";
import {
  GET_AGENCE_POLES,
  GET_AGENCE_TARIFS,
  GET_AGENCE_TAX_RATES,
  GET_PAYMENT_DEADLINES,
  GET_PAYMENT_TERMS,
} from "../queries/crm_agence";

// Constants
import {
  START_LOADING,
  STOP_LOADING,
  SNACK,
} from "../js/constants/action-types";
import { ROUTE_BUILDER } from "../js/constants/route-names";
import {
  ALERT_ERROR,
  ALERT_INFO,
  ALERT_SUCCESS,
} from "../js/constants/alert-types";

// Internal
import {
  ProductSelector,
  MultipleBlockConfig,
  MiniBlockConfig,
  AmazingBlockConfig,
  PageCreator,
  FinderConfig,
  OfferConfig,
} from "./core/components/builder";
import {
  TextField,
  ColorPicker,
  SliderInput,
  SelectInput,
  SwitchInput,
  ButtonGroupInput,
  Textarea,
  ImagePicker,
  MenuConfigurator,
  SocialSelector,
  ProductSelector as SingleProductSelector,
  CategorySelector,
  CustomInput,
  GameRetailerInformation,
} from "./core/inputs";
import {
  ItemTypes,
  FormInputTypes,
  Pages,
  EditableTypes,
  AssetTypes,
} from "./shareable/types";
import { Droppable } from "./core/components/builder";
import { getComponent } from "./core/components/assets";
import { getComponent as getComponentMinisite } from "./shareable/components/assets/minisite/components/utils";
import { getComponent as getComponentCMS } from "./shareable/components/assets/cms/components/utils";
import { getDefaultPageConfig, getDefaultParams } from "./core/config";
import { BuilderPanel, BuilderContent } from "./structure";

// Emails
import EmailBase from "../email/base";
import MinisiteContactEmail from "../email/minisite/emailContact";
import { htmlBuildSuccessEmail } from "../email/flipbook/emailSuccess";
import { htmlBuildWinEmail } from "../email/flipbook/emailWin";
import { htmlBuildLooseEmail } from "../email/flipbook/emailLoose";
import { htmlBuildGameEmail } from "../email/game/mailBase";

// Images
import imgNotFound from "../assets/images/not-found.png";

// Snapshots
import { SnapshotListingModal, SnapshotCreationModal } from "./core/snapshots";

// CMS
import { getScripts } from "./utils/config/cms";
import {
  GET_CONTENT_PAGE,
  UPDATE_MAGENTO_PAGE_CONTENT,
  UPDATE_MAGENTO_PAGE,
} from "../queries/assetMagentoPage";
import {
  GET_CONTENT_PAGES_PAGE,
  UPDATE_PAGE_CONTENT,
  UPDATE_PAGE,
} from "../queries/assetPagesPage";

import styled from "styled-components";
import BlocAccordion from "./shareable/components/assets/cms/components/BlocAccordion/BlocAccordion";

const AccordionSummaryCustom = styled(AccordionSummary)`
  background: ${colors.blue.lighter.hue900};
  & > div {
    justify-content: space-between;
    align-items: center;
    p {
      color: ${colors.blue.lighter.hue300};
    }
    svg {
      fill: ${colors.blue.lighter.hue300};
    }
  }
`;

export const BuilderContext = React.createContext();

// Main

const BuilderComponent = ({
  client,
  history,
  snack,
  attributes,
  locales,
  onlyPreview = false,
  onlyContent = false,
  noToolbar = false,
  presentation = false,
  presentationModel = false,
  assetData,
  pageToEdit,
  externalConfig,
  updateExternalConfig,
  dev = false,
  startLoading,
  stopLoading,
}) => {
  const height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  const classes = useStyles();

  // Routing
  let { assetId, pageId, contentId, page, alias } = useParams();
  const PAGE_TO_EDIT = pageToEdit || pageId || contentId || page;

  // console.log('PAGE_TO_EDIT', PAGE_TO_EDIT)

  // Hooks
  const [currentTab, setTab] = React.useState(0);
  const [currentComponent, setComponent] = React.useState(null);
  const [currentBlock, setBlock] = React.useState(null);
  const [preview, setPreview] = React.useState(onlyPreview);
  const [asset, setAsset] = React.useState(null);
  const [assetType, setAssetType] = React.useState(null);
  const [assetToken, setAssetToken] = React.useState(null);
  const [catalog, setCatalog] = React.useState(null);
  const [localConfig, setLocalConfig] = React.useState(null);
  const [apiConfig, setApiConfig] = React.useState(null);
  const [pagesFlipbook, setPagesFlipbook] = React.useState(null);
  const [varianteFlipbook, setVarianteFlipbook] = React.useState(null);
  const [ready, setReady] = React.useState(false);
  const [noPanel, setNoPanel] = React.useState(false);
  const [openChangerPage, setOpenChangerPage] = React.useState(false);
  const [products, updateProducts] = React.useState([]); // used for context (minisite wishlist)

  // Magento
  const [assetPage, setAssetPage] = React.useState(null);
  const [assetPageName, setAssetPageName] = React.useState(null);

  // Offer
  const [offer, setOffer] = React.useState(null);
  const [poles, setPoles] = React.useState(null);
  const [tarifs, setTarifs] = React.useState(null);
  const [taxes, setTaxes] = React.useState(null);
  const [payments, setPayments] = React.useState(null);
  const [deadlines, setDeadlines] = React.useState(null);

  // Snapshots
  const [snapModalOpen, setSnapModalOpen] = React.useState(false);
  const [snapListModalOpen, setSnapListModalOpen] = React.useState(false);
  const [snapIdentifier, setSnapIdentifier] = React.useState("");
  const [snapData, setSnapData] = React.useState(null);
  const [snapKey, setSnapKey] = React.useState(null);

  let config = externalConfig || localConfig;

  // ComponentDidMount
  useEffect(() => {
    (async () => {
      if (assetData) {
        // External use

        setCatalog(assetData.catalog);
        setAsset(assetData);
        setAssetType(PAGE_TO_EDIT);
        setReady(true);
      } else if (presentation) {
        // CRM presentation

        initPresentation();
      } else if (assetId) {
        // Classic asset use

        client
          .query({
            query: GET_ASSET_BY_IDENTIFIER_BUILDER,
            variables: { identifier: assetId },
            fetchPolicy: "no-cache",
          })
          .then(async (GET_ASSET_BY_IDENTIFIER_RESULT) => {
            let currentAsset =
              GET_ASSET_BY_IDENTIFIER_RESULT.data.assets.edges[0].node;
            const ASSET_TYPE = currentAsset.assetType.identifier;

            setCatalog(currentAsset.catalog);
            setAsset(currentAsset);
            setAssetType(ASSET_TYPE);
            setAssetToken(currentAsset.token);

            // console.log('ASSET_TYPE', ASSET_TYPE)

            localStorage.setItem("products", []); // context related

            let config = {
              params: !currentAsset.content
                ? getDefaultParams(ASSET_TYPE)
                : cleanParams(JSON.parse(currentAsset.content), currentAsset),
            };

            if (
              ASSET_TYPE !== "gift_finder" &&
              ASSET_TYPE !== "magento" &&
              ASSET_TYPE !== "pages" &&
              ASSET_TYPE !== "shopify" &&
              ASSET_TYPE !== "wordpress"
            ) {
              // minisite || jeu || flipbook

              let page =
                ASSET_TYPE === "minisite" ||
                ASSET_TYPE === "newsletter" ||
                ASSET_TYPE === "flipbook"
                  ? currentAsset.assetMinisitePages.edges.find(
                      (e) =>
                        e.node.assetMinisitePageType.identifier === PAGE_TO_EDIT
                    )
                  : ASSET_TYPE === "jeu"
                  ? currentAsset.assetGamePages.edges.find(
                      (e) =>
                        e.node.assetGamePageType.identifier === PAGE_TO_EDIT
                    )
                  : null;

              if (ASSET_TYPE === "minisite")
                getMinisiteEmails(currentAsset, config);

              config[PAGE_TO_EDIT] = page.node.content
                ? cleanConfig(JSON.parse(page.node.content))
                : getDefaultPageConfig(PAGE_TO_EDIT, ASSET_TYPE);
            } else {
              if (
                (ASSET_TYPE === "magento" ||
                  ASSET_TYPE === "pages" ||
                  ASSET_TYPE === "shopify" ||
                  ASSET_TYPE === "wordpress") &&
                contentId
              ) {
                client
                  .query({
                    query:
                      ASSET_TYPE === "pages"
                        ? GET_CONTENT_PAGES_PAGE
                        : GET_CONTENT_PAGE,
                    variables: {
                      id:
                        ASSET_TYPE === "pages"
                          ? `/api/asset-pages-page-contents/${contentId}`
                          : `/api/asset-magento-page-contents/${contentId}`,
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then(async (GET_CONTENT_RESULT) => {
                    let result = GET_CONTENT_RESULT.data;
                    let content =
                      ASSET_TYPE === "pages"
                        ? result.assetPagesPageContent.contentConfig
                        : result.assetMagentoPageContent.contentConfig;
                    let page =
                      ASSET_TYPE === "pages"
                        ? result.assetPagesPageContent.pagesPage
                        : result.assetMagentoPageContent.magentoPage;

                    config[PAGE_TO_EDIT] = content
                      ? JSON.parse(content)
                      : (config[PAGE_TO_EDIT] = { elements: [] });

                    config[PAGE_TO_EDIT] = cleanConfig(
                      config[PAGE_TO_EDIT],
                      true
                    );

                    setAssetPage(page.id);
                    setAssetPageName(page.libelle);
                  });
              } else {
                config[PAGE_TO_EDIT] = currentAsset.content
                  ? JSON.parse(currentAsset.content)
                  : getDefaultPageConfig(PAGE_TO_EDIT, ASSET_TYPE);
              }
            }

            if (
              ASSET_TYPE === "minisite" ||
              ASSET_TYPE === "newsletter" ||
              ASSET_TYPE === "flipbook" ||
              ASSET_TYPE === "gift_finder" ||
              ASSET_TYPE === "magento" ||
              ASSET_TYPE === "shopify" ||
              ASSET_TYPE === "pages" ||
              ASSET_TYPE === "jeu" ||
              ASSET_TYPE === "wordpress"
            ) {
              let data = new FormData();
              data.append("token", currentAsset.token);

              if (
                ASSET_TYPE === "magento" ||
                ASSET_TYPE === "shopify" ||
                ASSET_TYPE === "pages" ||
                ASSET_TYPE === "wordpress"
              )
                data.append("displayProducts", 0);

              let apiConfig = await request(
                `${process.env.REACT_APP_API}/asset/config`,
                "post",
                data,
                "multipart/form-data"
              );
              setApiConfig(apiConfig.asset);

              if (ASSET_TYPE === "flipbook") {
                apiConfig.asset.flipbook.map((page) => {
                  page.parsedHtmlContent = JSON.parse(
                    page.html_content || null
                  );
                });
                setPagesFlipbook(apiConfig.asset.flipbook);
                setVarianteFlipbook(config?.params?.variante?.value);
              }
            }

            setLocalConfig(config);
            setReady(true);
          });
      }

      if (externalConfig) return;
    })();
  }, []);

  // ComponentDidUpdate
  useEffect(() => {
    if (assetType === "flipbook") {
      let variante = config?.params?.variante?.value;

      if (varianteFlipbook && variante && variante !== varianteFlipbook) {
        setVarianteFlipbook(variante);
        config[PAGE_TO_EDIT] = getDefaultPageConfig(
          PAGE_TO_EDIT,
          asset.assetType.identifier,
          config,
          variante
        );
        setLocalConfig(config);
      }
    }
  }, [localConfig]);

  // Presentation

  const initPresentation = async () => {
    const process = (response) => {
      let result = presentationModel
        ? response.data.modelPresentations.edges[0]?.node
        : response.data.presentations.edges[0]?.node;

      if (!result) return setReady(true);

      setOffer(result);
      let config = {
        params: null,
      };

      let data = null;

      try {
        data = result.htmlContent
          ? cleanConfig(JSON.parse(result.htmlContent))
          : null;
      } catch (e) {
        console.log("Error parsing invalid json");
      }

      config[Pages.PRESENTATION] = data
        ? refreshPhasesOnOffers(result, data, !presentationModel)
        : {
            elements: [
              {
                type: ItemTypes.OFFER_FRONT_PAGE,
                key: uuidv4(),
                data: getDefaultData(ItemTypes.OFFER_FRONT_PAGE),
              },
            ],
          };

      setLocalConfig(config);
      setReady(true);
    };

    let poles = await client.query({
      query: GET_AGENCE_POLES,
      fetchPolicy: "no-cache",
    });
    let tarifs = await client.query({
      query: GET_AGENCE_TARIFS,
      fetchPolicy: "no-cache",
    });
    let taxes = await client.query({
      query: GET_AGENCE_TAX_RATES,
      fetchPolicy: "no-cache",
    });
    let payments = await client.query({
      query: GET_PAYMENT_TERMS,
      fetchPolicy: "no-cache",
    });
    let deadlines = await client.query({
      query: GET_PAYMENT_DEADLINES,
      fetchPolicy: "no-cache",
    });

    setPoles(poles?.data.agencyPoles.edges);
    setTarifs(tarifs?.data.agencyRates.edges);
    setTaxes(taxes?.data.agencyTaxRates.edges);
    setPayments(payments?.data.agencyPaymentTerms.edges);
    setDeadlines(deadlines?.data.agencyPaymentDeadlines.edges);

    if (presentationModel) {
      client
        .query({
          query: GET_MODEL_PRESENTATION_BY_ALIAS,
          variables: { alias },
          fetchPolicy: "no-cache",
        })
        .then(async (GET_MODEL_PRESENTATION_BY_ALIAS_RESULT) =>
          process(GET_MODEL_PRESENTATION_BY_ALIAS_RESULT)
        );
    } else {
      client
        .query({
          query: GET_PRESENTATION_BY_ALIAS,
          variables: { alias },
          fetchPolicy: "no-cache",
        })
        .then(async (GET_PRESENTATION_BY_ALIAS_RESULT) =>
          process(GET_PRESENTATION_BY_ALIAS_RESULT)
        );
    }
  };

  const exportLink = () => {
    snack(ALERT_SUCCESS, "Lien copié !");
  };

  // Minisite

  const getMinisiteEmails = (asset, config) => {
    let page = asset.assetMinisitePages.edges.find(
      (e) => e.node.assetMinisitePageType.identifier === Pages.EMAIL_CONTACT
    );

    if (!page) return;

    config[Pages.EMAIL_CONTACT] = page.node.content
      ? cleanConfig(JSON.parse(page.node.content))
      : getDefaultPageConfig(PAGE_TO_EDIT, asset.assetType.identifier);
  };

  // Config

  const cleanConfig = (config, isMagento = false) => {
    let elements = config?.elements;

    if (!elements) return config;

    for (let element of elements) {
      let defaultConfig = getDefaultData(element.type);
      if (!defaultConfig) continue;

      // inputs

      if (!element?.data?.inputs) element.data.inputs = {};

      for (let defaultInputName in defaultConfig.inputs) {
        let input = element.data.inputs[defaultInputName];

        if (!input) {
          // not found
          input = defaultConfig.inputs[defaultInputName];
          element.data.inputs[defaultInputName] = input;
        } else if (input.type === FormInputTypes.IMAGE) {
          input.isCroppable = true;
        }
      }

      // blocks
      if (!isMagento) {
        if (!element?.data?.block) element.data.block = {};

        for (let defaultBlockName in defaultConfig.blocks) {
          if (!element.data.blocks[defaultBlockName]) {
            // not found
            element.data.blocks[defaultBlockName] =
              defaultConfig.blocks[defaultBlockName];
          } else {
            // block inputs

            let block = element.data.blocks[defaultBlockName];
            let defaultBlock = defaultConfig.blocks[defaultBlockName];

            if (!block?.inputs) block.inputs = {};

            for (let blockInputName in defaultBlock.inputs) {
              let input = block.inputs[blockInputName];

              if (!input) {
                // not found
                input = defaultBlock.inputs[blockInputName];
              } else if (input.type === FormInputTypes.IMAGE) {
                input.isCroppable = true;
              }
            }
          }
        }
      }
    }

    return config;
  };

  const cleanParams = (config, currentAsset) => {
    if (!config) return config;

    let defaultConfig = getDefaultParams(currentAsset.assetType.identifier);

    for (let defaultInputName in defaultConfig) {
      if (!config[defaultInputName]) {
        // not found
        config[defaultInputName] = defaultConfig[defaultInputName];
      }
    }

    return config;
  };

  const updateConfig = () => {
    let newConfig = { ...config };
    externalConfig
      ? updateExternalConfig(newConfig)
      : setLocalConfig(newConfig);
  };

  const buildFormInput = (input, id, first = null) => {
    const onChange = (e) => {
      input.value = e?.target?.value ?? e;
      updateConfig();
    };

    if (input?.type) {
      switch (input.type) {
        case FormInputTypes.TEXTAREA:
          return (
            <TextField
              key={`${FormInputTypes.TEXT}-${id}`}
              label={input.label}
              type="text"
              value={input.value}
              onChange={onChange}
              variant="outlined"
              multiline
              rows={5}
              translate={input.translate}
            />
          );
        case FormInputTypes.WYSIWYG:
          return (
            <Textarea
              key={`${FormInputTypes.WYSIWYG}-${id}`}
              value={input.value}
              onChange={onChange}
              translate={input.translate}
            />
          );
        case FormInputTypes.TEXT:
          return (
            <TextField
              key={`${FormInputTypes.TEXT}-${id}`}
              label={input.label}
              placeholder={input.placeholder}
              type="text"
              value={input.value}
              onChange={onChange}
              variant="standard"
              translate={input.translate}
            />
          );
        case FormInputTypes.DATE:
          return (
            <TextField
              key={`${FormInputTypes.DATE}-${id}`}
              label={input.label}
              placeholder={input.placeholder}
              type="date"
              value={input.value}
              onChange={onChange}
              variant="standard"
            />
          );
        case FormInputTypes.NUMBER:
          return (
            <TextField
              key={`${FormInputTypes.NUMBER}-${id}`}
              label={input.label}
              type="number"
              value={input.value}
              onChange={onChange}
              variant="outlined"
            />
          );
        case FormInputTypes.LINK:
          return (
            <TextField
              key={`${FormInputTypes.LINK}-${id}`}
              label="Lien"
              type="text"
              value={input.value}
              onChange={onChange}
              variant="outlined"
            />
          );
        case FormInputTypes.COLOR:
          if (input.value === null) return null;

          return (
            <ColorPicker
              key={`${FormInputTypes.COLOR}-${id}`}
              label={input.label}
              color={input.value}
              first={first}
              onChange={(color) => {
                input.value = color.rgb
                  ? `rgba(${color.rgb.r},${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                  : color;
                let newConfig = { ...config };
                setLocalConfig(newConfig);
              }}
            />
          );
        case FormInputTypes.SLIDER:
          if (input.value === null || input.value === false) return null;
          return (
            <SliderInput
              key={`${FormInputTypes.SLIDER}-${id}`}
              label={input.label}
              value={input.value}
              params={input.params}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.SELECT:
          if (input.value || input.value === 0 || input.value === "") {
            return (
              <SelectInput
                key={`${FormInputTypes.SELECT}-${id}`}
                label={input.label}
                value={input.value}
                params={input.params}
                onChange={(value) => {
                  if (input.special) {
                    let i = 0;

                    for (let element of localConfig[PAGE_TO_EDIT].elements) {
                      if (
                        element.type === value.type &&
                        element.key === currentComponent
                      ) {
                        localConfig[PAGE_TO_EDIT].elements.splice(i, 1, value);
                        setLocalConfig(localConfig);
                        updateConfig();
                      }
                      i++;
                    }
                  } else {
                    input.value = value;
                    updateConfig();
                  }
                }}
              />
            );
          } else {
            return null;
          }
        case FormInputTypes.SWITCH:
          return (
            <SwitchInput
              key={`${FormInputTypes.SWITCH}-${id}`}
              label={input.label}
              value={input.value ? true : false}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.BUTTON_GROUP:
          return (
            <ButtonGroupInput
              key={`${FormInputTypes.SWITCH}-${id}`}
              label={input.label}
              value={input.value}
              params={input.params}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.IMAGE:
          return (
            <>
              {input.label && (
                <Typography gutterBottom>{input.label}</Typography>
              )}
              <ImagePicker
                key={`${FormInputTypes.IMAGE}-${id}`}
                value={input.value}
                valueRef={input.valueRef}
                isCroppable={input.isCroppable}
                onChange={(value, ref) => {
                  input.value = value;
                  input.valueRef = ref
                    ? typeof ref === "string"
                      ? ref
                      : { ...ref }
                    : null;

                  updateConfig();
                }}
                onDelete={(value, ref) => {
                  input.value = null;
                  input.valueRef = null;
                  updateConfig();
                }}
              />
            </>
          );
        case FormInputTypes.MENU:
          return (
            <MenuConfigurator
              key={`${FormInputTypes.MENU}-${id}`}
              value={input.value}
              catalog={catalog}
              locales={locales}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.GAME_RETAILER:
          return (
            <GameRetailerInformation
              key={`${FormInputTypes.GAME_RETAILER}-${id}`}
              value={input.value}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.PRODUCTS:
          return (
            <ProductSelector
              key={`${FormInputTypes.PRODUCTS}-${id}`}
              catalog={catalog}
              products={apiConfig.products}
              label={input.label}
              value={input.value || null}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.PRODUCT:
          return (
            <SingleProductSelector
              key={`${FormInputTypes.PRODUCTS}-${id}`}
              catalog={catalog}
              products={apiConfig.products}
              label={input.label}
              value={input.value}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.CATEGORY:
          return (
            <CategorySelector
              key={`${FormInputTypes.PRODUCTS}-${id}`}
              catalog={catalog}
              locales={locales}
              label={input.label}
              value={input.value}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.SOCIAL:
          return (
            <SocialSelector
              key={`${FormInputTypes.SOCIAL}-${id}`}
              value={input.value}
              onChange={(value) => {
                input.value = value;
                updateConfig();
              }}
            />
          );
        case FormInputTypes.GROUP:
          let inputs = [];

          inputs.push(
            <h3
              style={{
                fontSize: 16,
                fontWeight: "initial",
                color: colors.grey.regular,
              }}
            >
              {input.label}
            </h3>
          );

          _.mapKeys(input.inputs, function (input, index) {
            inputs.push(buildFormInput(input, index));
          });

          return inputs;
        case FormInputTypes.CUSTOM:
          return (
            <CustomInput
              key={`${FormInputTypes.CUSTOM}-${id}`}
              value={input.value}
              onChange={(value) => {
                input.value = value; // composed of HTML, CSS and JS
                updateConfig();
              }}
            />
          );

        default:
          return null;
      }
    }
  };

  const buildForm = () => {
    let pageConfig = config[PAGE_TO_EDIT];

    let component = currentComponent;

    if (component === "email_game_win_random_draw") component = "mail_win";

    if (component === "confirmation_email_random_draw")
      component = "mail_confirmation";

    let element = pageConfig.isEmail
      ? pageConfig // gets email config
      : pageConfig.elements.find((e) => e.key === component); // gets component config

    // component not found
    if (!element) return null;

    // all inputs to display
    let inputs = [];
    let inputsConfig = [];
    let specialInput = {};

    if (assetType === "jeu" || assetType === "flipbook") {
      let i = 0;
      specialInput.type = FormInputTypes.SELECT;
      specialInput.special = true;
      specialInput.label = "Récupérer la configuration de la page :";
      specialInput.value = true;
      specialInput.params = [];

      if (assetType === "jeu") {
        for (let page of asset.assetGamePages.edges) {
          let namePage = page.node.assetGamePageType.identifier;
          let content = JSON.parse(page.node.content);

          if (content && namePage !== PAGE_TO_EDIT) {
            for (let item of content.elements) {
              if (item.type === element.type) {
                specialInput.params[i] = {
                  label: getPageName(namePage),
                  value: item,
                };
                i++;
              }
            }
          }
        }
      } else {
        for (let page of asset.assetMinisitePages.edges) {
          let namePage = page.node.assetMinisitePageType.identifier;
          let content = JSON.parse(page.node.content);

          if (content && namePage !== PAGE_TO_EDIT) {
            for (let item of content.elements) {
              if (item.type === element.type) {
                specialInput.params[i] = {
                  label: getPageName(namePage),
                  value: item,
                };
                i++;
              }
            }
          }
        }
      }
    }

    // list component inputs if no block is selected
    if (!currentBlock && element.data?.inputs) {
      if (element.data.name) {
        let pageName = getPageName(PAGE_TO_EDIT);

        inputs.push(
          <h2
            style={{
              color: colors.black.regular,
              width: "100%",
              padding: "10px 16px",
              marginBottom: 6,
              fontSize: 14,
            }}
          >
            {element.data.name}
          </h2>
        );
      }

      if (
        specialInput &&
        specialInput.params &&
        specialInput.params.length > 0
      ) {
        inputsConfig.push(buildFormInput(specialInput, -1));
      }

      _.mapKeys(element.data.inputs, function (input, i) {
        if (
          element.type === ItemTypes.OFFER_DEVIS &&
          input.hideIfModel &&
          presentationModel
        )
          return;

        inputsConfig.push(buildFormInput(input, i, true));

        if (
          input.type === FormInputTypes.SWITCH &&
          input.value &&
          input.conditionalInputs
        ) {
          _.mapKeys(input.conditionalInputs, function (conditionalInput, j) {
            inputsConfig.push(buildFormInput(conditionalInput, j, true));
          });
        }
      });

      inputs.push(
        <Accordion
          style={{
            borderRadius: 0,
            boxShadow: "inherit",
            width: "100%",
            marginBottom: 16,
          }}
        >
          <AccordionSummaryCustom
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{element.data.name} - Configuration du bloc</Typography>
          </AccordionSummaryCustom>
          <AccordionDetails>
            <div
              style={{
                padding: "8px",
                width: "calc(100% - 16px)",
                background: "#F0F7FA",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background: "white",
                  padding: 8,
                  width: "calc(100% - 16px)",
                }}
              >
                {inputsConfig}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (currentBlock) {
      let block = element.data.blocks[currentBlock];

      if (block?.type === EditableTypes.MULTIPLE_BLOCKS) {
        return (
          <>
            {element.type === ItemTypes.FLIPBOOK_WITH_CART && (
              <PageCreator
                data={element.data}
                pagesFlipbook={pagesFlipbook}
                buildFormInput={buildFormInput}
                products={apiConfig.products}
                update={updateConfig}
                catalog={catalog}
                updateFlipbook={updateFlipbook}
              />
            )}
            <h2
              style={{
                color: colors.black.regular,
                width: "100%",
                padding: "10px 16px",
                marginBottom: 6,
                fontSize: 16,
              }}
            >
              {block.name}
            </h2>
            <MultipleBlockConfig
              datas={block}
              buildFormInput={buildFormInput}
              addBlock={addBlock}
              deleteBlock={deleteBlock}
              updateConfig={updateConfig}
            />
          </>
        );
      }

      if (block?.type === EditableTypes.MINI_BLOCKS) {
        return (
          <>
            {element.type === ItemTypes.FLIPBOOK_WITH_CART && (
              <PageCreator
                data={element.data}
                pagesFlipbook={pagesFlipbook}
                buildFormInput={buildFormInput}
                products={apiConfig.products}
                update={updateConfig}
                catalog={catalog}
                updateFlipbook={updateFlipbook}
              />
            )}
            <h2
              style={{
                color: colors.black.regular,
                width: "100%",
                padding: "10px 16px",
                marginBottom: 6,
                fontSize: 16,
              }}
            >
              {block.name}
            </h2>
            <MiniBlockConfig
              datas={block}
              buildFormInput={buildFormInput}
              addBlock={addBlock}
              deleteBlock={deleteBlock}
              updateConfig={updateConfig}
            />
          </>
        );
      }

      if (block?.type === EditableTypes.AMAZING_BLOCKS) {
        return (
          <>
            {element.type === ItemTypes.FLIPBOOK_WITH_CART && (
              <PageCreator
                data={element.data}
                pagesFlipbook={pagesFlipbook}
                buildFormInput={buildFormInput}
                products={apiConfig.products}
                update={updateConfig}
                catalog={catalog}
                updateFlipbook={updateFlipbook}
              />
            )}
            <h2
              style={{
                color: colors.black.regular,
                width: "100%",
                padding: "10px 16px",
                marginBottom: 6,
                fontSize: 16,
              }}
            >
              {block.name}
            </h2>
            <AmazingBlockConfig
              datas={block}
              buildFormInput={buildFormInput}
              addBlock={addBlock}
              deleteBlock={deleteBlock}
              updateConfig={updateConfig}
            />
          </>
        );
      }
    }

    for (let blockName in element.data?.blocks) {
      let loopBlock = element.data.blocks[blockName];

      if (
        currentBlock &&
        currentBlock !== blockName &&
        loopBlock.type !== EditableTypes.MINI_BLOCKS &&
        loopBlock.type !== EditableTypes.AMAZING_BLOCKS
      )
        continue;

      if (
        loopBlock?.type === EditableTypes.MINI_BLOCKS ||
        loopBlock.type === EditableTypes.AMAZING_BLOCKS
      ) {
        let block = element.data.blocks[blockName].blocks[currentBlock];
        let configForm = [];

        if (block) {
          configForm.push(
            <h2
              style={{
                color: colors.black.regular,
                width: "100%",
                padding: "10px 0",
                marginBottom: 6,
                fontSize: 16,
              }}
            >
              {block.name}
            </h2>
          );

          if (block.type === EditableTypes.MULTIPLE_BLOCKS) {
            return (
              <>
                {element.type === ItemTypes.FLIPBOOK_WITH_CART && (
                  <PageCreator
                    data={element.data}
                    pagesFlipbook={pagesFlipbook}
                    buildFormInput={buildFormInput}
                    products={apiConfig.products}
                    update={updateConfig}
                    catalog={catalog}
                    updateFlipbook={updateFlipbook}
                  />
                )}
                <h2
                  style={{
                    color: colors.black.regular,
                    width: "100%",
                    padding: "10px 16px",
                    marginBottom: 6,
                    fontSize: 16,
                  }}
                >
                  {block.name}
                </h2>
                <MultipleBlockConfig
                  datas={block}
                  buildFormInput={buildFormInput}
                  addBlock={addBlock}
                  deleteBlock={deleteBlock}
                  updateConfig={updateConfig}
                />
              </>
            );
          }

          let index = 0;
          for (let inputName in block.inputs) {
            let input = block.inputs[inputName];
            configForm.push(buildFormInput(input, `${block.id}-${index}`));
            index++;
          }

          inputs.push(
            <div
              style={{
                width: "calc(100% - 16px)",
                padding: 8,
                background: colors.blue.lighter.hue900,
              }}
            >
              <div style={{ background: "white", padding: 16 }}>
                {configForm}
              </div>
            </div>
          );
        }
      } else {
        let block = element.data.blocks[blockName];
        let configForm = [];

        configForm.push(
          <h2
            style={{
              color: colors.black.regular,
              width: "100%",
              padding: "10px 0",
              marginBottom: 6,
              fontSize: 16,
            }}
          >
            {block.name}
          </h2>
        );

        if (loopBlock.type === EditableTypes.MULTIPLE_BLOCKS)
          configForm.push(
            <MultipleBlockConfig
              datas={loopBlock}
              buildFormInput={buildFormInput}
              addBlock={addBlock}
              deleteBlock={deleteBlock}
              updateConfig={updateConfig}
            />
          );

        if (block.type === EditableTypes.FINDER) {
          configForm.push(
            <FinderConfig
              key={`${EditableTypes.FINDER}-${block.id}`}
              value={block.config}
              categories={apiConfig.categories}
              locales={locales}
              attributes={attributes}
              onChange={(config) => {
                block.config = config;
                updateConfig();
              }}
            />
          );
        }

        let index = 0;

        for (let inputName in block.inputs) {
          let input = block.inputs[inputName];
          configForm.push(buildFormInput(input, `${block.id}-${index}`));
          index++;
        }

        inputs.push(
          <div
            style={{
              width: "calc(100% - 16px)",
              padding: 8,
              background: colors.blue.lighter.hue900,
            }}
          >
            <div style={{ background: "white", padding: 16 }}>{configForm}</div>
          </div>
        );
      }

      if (element.type === ItemTypes.TAB_PRODUCT) {
        return (
          <>
            {inputs}
            <ProductSelector
              catalog={catalog}
              products={apiConfig.products}
              value={element.data.products}
              onChange={(all) => {
                element.data.products = all;
                updateConfig();
              }}
            />
          </>
        );
      }
    }

    if (
      element.type === ItemTypes.FLIPBOOK ||
      element.type === ItemTypes.FLIPBOOK_WITH_CART
    ) {
      return (
        <>
          {inputs}
          <PageCreator
            data={element.data}
            pagesFlipbook={pagesFlipbook}
            buildFormInput={buildFormInput}
            products={apiConfig.products}
            update={updateConfig}
            catalog={catalog}
            updateFlipbook={updateFlipbook}
          />
        </>
      );
    }

    if (element.type === ItemTypes.OFFER_DEVIS) {
      return (
        <>
          {inputs}
          <OfferConfig
            data={element.data}
            update={updateConfig}
            isModel={presentationModel}
            listDeadlines={deadlines}
            listTaxes={taxes}
            listAgencePoles={poles}
            listPayments={payments}
            listTarifs={tarifs}
          />
        </>
      );
    }

    return inputs;
  };

  const buildGeneralInputs = () => {
    let inputs = [];
    let configForm = [];

    for (let name in config.params) {
      let param = config.params[name];

      if (!param) continue;

      if (param.name)
        configForm.push(
          <h2
            key={param.name}
            style={{
              color: colors.black.regular,
              width: "100%",
              padding: "10px 0",
              marginBottom: 6,
              fontSize: 16,
            }}
          >
            {param.name}
          </h2>
        );

      configForm.push(buildFormInput(param, param.id));
    }

    inputs.push(
      <div
        style={{
          width: "calc(100% - 16px)",
          padding: 8,
          background: colors.blue.lighter.hue900,
        }}
      >
        <div style={{ background: "white", padding: 16 }}>{configForm}</div>
      </div>
    );

    return inputs;
  };

  const inputCallback = (component, block = null) => {
    setComponent(component);
    setBlock(block);
    setTab(1);
    setNoPanel(false);
  };

  const changeTab = (event, newValue) => {
    setTab(newValue);
  };

  // COMPONENTS

  const getEmail = (element) => {
    switch (element.pageType) {
      case Pages.EMAIL_CONTACT:
        return <MinisiteContactEmail data={element.data} spread={false} />;
    }
  };

  const addComponent = (type, index) => {
    setLocalConfig((config) => {
      config[PAGE_TO_EDIT].elements.splice(index, 0, {
        type,
        key: uuidv4(),
        data: getDefaultData(type),
      });

      let newConfig = { ...config };
      return newConfig;
    });
  };

  const addComponentInside = (type, index, insideIndex) => {
    let item = _.clone(config[PAGE_TO_EDIT].elements[index - 1]);
    let insideBlock = item.data.blocks.insideBlock?.blocks;
    if (insideBlock[type]) {
      insideBlock.push(getDefaultData(type));
    } else {
      insideBlock.push(getDefaultData(type));
    }
    item.data.blocks.insideBlock.blocks = insideBlock;

    setLocalConfig((config) => {
      config[PAGE_TO_EDIT].elements.splice(index - 1, 1, item);
      let newConfig = { ...config };
      return newConfig;
    });
  };

  const deleteComponent = (id) => {
    config[PAGE_TO_EDIT].elements = config[PAGE_TO_EDIT].elements.filter(
      (e) => e.key !== id
    );
    updateConfig();
  };

  // DND
  const TypedDroppable = ({
    id,
    type,
    index,
    inside = false,
    insideIndex = null,
  }) => {
    return (
      <Droppable
        key={`${id}-drop`}
        addComponent={inside ? addComponentInside : addComponent}
        accept={extractAcceptsFromType(type)}
        index={index + 1}
      />
    );
  };

  const extractAcceptsFromType = (type) => {
    let minisiteBase = [
      ItemTypes.SLIDER,
      ItemTypes.SLIDER_MULTIPLE,
      ItemTypes.SLIDER_WITH_RIGHT_IMAGES,
      ItemTypes.TAB_PRODUCT,
      ItemTypes.SECTION_TITLE,
      ItemTypes.BLOC_TEXT,
      ItemTypes.IMAGE_ROW,
      ItemTypes.THREE_IMAGES_GRID,
      ItemTypes.THREE_IMAGES_ROW,
      ItemTypes.IMAGE,
      ItemTypes.VIDEO_ONLY,
      ItemTypes.INFORMATION_BANNER,
      ItemTypes.LINK_BANNER,
      ItemTypes.CATEGORY,
      ItemTypes.TEXT_IMAGE,
      ItemTypes.TEXT,
      ItemTypes.GIFT_FINDER,
      ItemTypes.SLIDER_PRODUCT,
      ItemTypes.TEXT_VIDEO,
      ItemTypes.CMS_ENTETE,
      ItemTypes.CMS_TOP_IMAGE_FULLSCREEN,
      ItemTypes.CMS_SLOGAN,
      ItemTypes.CMS_TITLE_TEXT,
      ItemTypes.CMS_SEPARATOR,
      ItemTypes.CMS_SLIDER,
      ItemTypes.CMS_SLIDER_ABOUT,
      ItemTypes.CMS_SLIDER_INSTAGRAM,
      ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA,
      ItemTypes.CMS_ACCORDION,
      ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA,
      ItemTypes.CMS_SLIDER_MOMENTS,
      ItemTypes.CMS_SLIDER_IMAGE,
      ItemTypes.CMS_GRID_IMAGE,
      ItemTypes.CMS_TEXT_MEDIA,
      ItemTypes.CMS_MULTIPLE_TEXT,
      ItemTypes.CMS_BANNER_MEDIA,
      ItemTypes.CMS_SECTION_DESCRIPTION_PAGE,
      ItemTypes.CMS_SECTION_MANIFEST_BLOCK,
      ItemTypes.CMS_CATEGORIES_HOME,
      ItemTypes.CMS_COLLECTIONS_HOME,
      ItemTypes.CMS_PRESENTATION_DUO,
      ItemTypes.CMS_LOOP_LINK,
      ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
      ItemTypes.CMS_BLOC_STORELOCATOR_HOME,
      ItemTypes.CMS_LIST_CATEGORIES,
      ItemTypes.CMS_BLOC,
      ItemTypes.CMS_SLIDER_BY_TAB,
      ItemTypes.CMS_CUSTOM,
    ];

    let offerBase = [
      ItemTypes.OFFER_SECTION_TITLE,
      ItemTypes.OFFER_FRONT_PAGE,
      ItemTypes.OFFER_ABOUT_SLIDE,
      ItemTypes.OFFER_TRIPLE_BLOCK_LIST,
      ItemTypes.OFFER_TRIPLE_BLOCK,
      ItemTypes.OFFER_TRIPLE_BLOCK_TEXT,
      ItemTypes.OFFER_TEXT_WITH_IMAGE,
      ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE,
      ItemTypes.OFFER_DEVIS,
    ];

    let flipbookBase = [
      ItemTypes.HEADER_BOOK,
      ItemTypes.HEADER_CART_BOOK,
      ItemTypes.FOOTER_BOOK,
      ItemTypes.TOP_HOMEPAGE_BOOK,
      ItemTypes.POSTCARD_BOOK,
      ItemTypes.RECAP_BLOCK_BOOK,
      ItemTypes.RESULTS_BOOK,
      ItemTypes.BANNER_LINK_BOOK,
      ItemTypes.EMAIL_SUCCESS_BOOK,
      ItemTypes.FLIPBOOK,
      ItemTypes.FLIPBOOK_WITH_CART,
      ItemTypes.COPYRIGHT_BOOK,
      // ItemTypes.FLIPBOOK_WITH_WISHLIST
    ];

    console.log(type);
    switch (type) {
      case ItemTypes.HEADER_BOOK:
      case ItemTypes.HEADER_CART_BOOK:
      case ItemTypes.FOOTER_BOOK:
      case ItemTypes.TOP_HOMEPAGE_BOOK:
      case ItemTypes.POSTCARD_BOOK:
      case ItemTypes.RECAP_BLOCK_BOOK:
      case ItemTypes.RESULTS_BOOK:
      case ItemTypes.BANNER_LINK_BOOK:
      case ItemTypes.EMAIL_SUCCESS_BOOK:
      case ItemTypes.FLIPBOOK:
      case ItemTypes.FLIPBOOK_WITH_CART:
      case ItemTypes.COPYRIGHT_BOOK:
        // case ItemTypes.FLIPBOOK_WITH_WISHLIST:
        return flipbookBase;
      case ItemTypes.ALL:
        return minisiteBase.concat(offerBase);
      case ItemTypes.HEADER:
      case ItemTypes.SLIDER:
      case ItemTypes.SLIDER_MULTIPLE:
      case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
      case ItemTypes.TAB_PRODUCT:
      case ItemTypes.SECTION_TITLE:
      case ItemTypes.BLOC_TEXT:
      case ItemTypes.IMAGE_ROW:
      case ItemTypes.THREE_IMAGES_GRID:
      case ItemTypes.THREE_IMAGES_ROW:
      case ItemTypes.IMAGE:
      case ItemTypes.VIDEO_ONLY:
      case ItemTypes.INFORMATION_BANNER:
      case ItemTypes.LINK_BANNER:
      case ItemTypes.CATEGORY:
      case ItemTypes.TEXT_IMAGE:
      case ItemTypes.SLIDER_PRODUCT:
      case ItemTypes.TEXT_VIDEO:
      case ItemTypes.TEXT:
      case ItemTypes.PAGE_CONTACT:
      case ItemTypes.PAGE_WISHLIST:
      case ItemTypes.PAGE_LANDING:
      case ItemTypes.PAGE_PRODUCT:
      case ItemTypes.CMS_ENTETE:
      case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN:
      case ItemTypes.CMS_SLOGAN:
      case ItemTypes.CMS_TITLE_TEXT:
      case ItemTypes.CMS_SEPARATOR:
      case ItemTypes.CMS_SLIDER:
      case ItemTypes.CMS_SLIDER_ABOUT:
      case ItemTypes.CMS_SLIDER_INSTAGRAM:
      case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
      case ItemTypes.CMS_ACCORDION:
      case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
      case ItemTypes.CMS_SLIDER_MOMENTS:
      case ItemTypes.CMS_SLIDER_IMAGE:
      case ItemTypes.CMS_GRID_IMAGE:
      case ItemTypes.CMS_TEXT_MEDIA:
      case ItemTypes.CMS_MULTIPLE_TEXT:
      case ItemTypes.CMS_BANNER_MEDIA:
      case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE:
      case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
      case ItemTypes.CMS_CATEGORIES_HOME:
      case ItemTypes.CMS_COLLECTIONS_HOME:
      case ItemTypes.CMS_LOOP_LINK:
      case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
      case ItemTypes.CMS_BLOC_STORELOCATOR_HOME:
      case ItemTypes.CMS_LIST_CATEGORIES:
      case ItemTypes.CMS_BLOC:
      case ItemTypes.CMS_SLIDER_BY_TAB:
      case ItemTypes.CMS_CUSTOM:
        return minisiteBase;
      case ItemTypes.FOOTER:
        return [];
      case ItemTypes.OFFER_SECTION_TITLE:
      case ItemTypes.OFFER_FRONT_PAGE:
      case ItemTypes.OFFER_ABOUT_SLIDE:
      case ItemTypes.OFFER_TRIPLE_BLOCK_LIST:
      case ItemTypes.OFFER_TRIPLE_BLOCK:
      case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT:
      case ItemTypes.OFFER_TEXT_WITH_IMAGE:
      case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE:
      case ItemTypes.OFFER_DEVIS:
        return offerBase;
      case ItemTypes.HOME_GAME:
        return [
          ItemTypes.HOME_COMPLETE_FORM_GAME,
          ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE,
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.PRE_HEADER_GAME,
          ItemTypes.HOME_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.HOME_COMPLETE_FORM_GAME:
        return [ItemTypes.PRE_HEADER_GAME, ItemTypes.CMS_CUSTOM];
      case ItemTypes.ALREADYPLAYED_GAME:
        return [ItemTypes.ALREADYPLAYED_GAME_SIMPLE, ItemTypes.CMS_CUSTOM];
      case ItemTypes.ENDED_GAME:
        return [
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.PRE_HEADER_GAME:
        return [
          ItemTypes.HOME_COMPLETE_FORM_GAME,
          ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE,
          ItemTypes.HOME_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.HEADER_GAME:
        return [
          ItemTypes.PRE_HEADER_GAME,
          ItemTypes.GAME_RAIN,
          ItemTypes.GAME_CLICKER,
          ItemTypes.GAME_QUIZ_PERSONALITY,
          ItemTypes.GAME_CODE_FINDER,
          ItemTypes.GAME_SCRATCH_IMAGE,
          ItemTypes.GAME_WISHLIST,
          ItemTypes.RECAP_WISHLIST,
          ItemTypes.CMS_CUSTOM,
          ItemTypes.AMAZING_GAME,
          ItemTypes.HOME_GAME,
          ItemTypes.HOME_COMPLETE_FORM_GAME,
          ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE,
          ItemTypes.GAME_PLACEHOLDER,
        ];
      case ItemTypes.AMAZING_GAME:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.FOOTER_GAME,
          ItemTypes.REDIRECT_ON_GAME,
          ItemTypes.REDIRECT_ON_GAME2,
          ItemTypes.REDIRECT_ON_GAME3,
          ItemTypes.REDIRECT_ON_GAME4,
          ItemTypes.BANNER_IMAGE,
          ItemTypes.AMAZING_GAME,
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.RECAP_WISHLIST,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE:
      case ItemTypes.AMAZING_GAME_INSIDE:
        return [
          ItemTypes.TITLE_GAME,
          ItemTypes.PARAGRAPH_GAME,
          ItemTypes.RULES_AMAZING_GAME,
          ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME,
          ItemTypes.DOTATIONS_ONE_IMAGE,
          ItemTypes.SOCIAL_LIST,
          ItemTypes.BUTTON_GAME,
          ItemTypes.GALLERY_GAME,
        ];
      case ItemTypes.FOOTER_GAME:
        return [
          ItemTypes.HOME_COMPLETE_FORM_GAME,
          ItemTypes.HOME_COMPLETE_FORM_GAME_TOULOUSE,
          ItemTypes.HOME_GAME,
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.FOOTER_GAME,
          ItemTypes.REDIRECT_ON_GAME,
          ItemTypes.REDIRECT_ON_GAME2,
          ItemTypes.REDIRECT_ON_GAME3,
          ItemTypes.REDIRECT_ON_GAME4,
          ItemTypes.BANNER_IMAGE,
          ItemTypes.AMAZING_GAME,
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.RECAP_WISHLIST,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.BASIC_BLOC:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.BASIC_BLOC2:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.REDIRECT_ON_GAME:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.REDIRECT_ON_GAME2:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.REDIRECT_ON_GAME3:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.REDIRECT_ON_GAME4:
        return [
          ItemTypes.BASIC_BLOC,
          ItemTypes.BASIC_BLOC2,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.EXPLANATIONS_GAME:
        return [
          ItemTypes.EXPLANATIONS_GAME,
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.RESULTS_WIN_GAME:
        return [
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.HOME_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.RECAP_WISHLIST:
        return [
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      case ItemTypes.RESULTS_LOOSE_GAME:
        return [
          ItemTypes.EXPLANATIONS_ACV_GAME,
          ItemTypes.EXPLANATIONS_BF_GAME,
          ItemTypes.EXPLANATIONS_MORE_DETAILS_GAME,
          ItemTypes.AMAZING_GAME,
          ItemTypes.CMS_CUSTOM,
        ];
      default:
        return [
          ItemTypes.SECTION_TITLE,
          ItemTypes.IMAGE_ROW,
          ItemTypes.SLIDER,
          ItemTypes.TAB_PRODUCT,
        ];
    }
  };

  // MULTIPLE BLOCKS

  const addBlock = (datas) => {
    console.log(datas);
    switch (datas.subtype) {
      case ItemTypes.SLIDER:
        datas.config.push(CopyWithJSON(getDefaultSlide()));
        updateConfig();
        break;
      case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
        datas.config.push(CopyWithJSON(getDefaultSimpleSlide()));
        updateConfig();
        break;
      case ItemTypes.SLIDER_MULTIPLE:
        datas.config.push(
          CopyWithJSON(
            getDefaultSimpleSlide("https://via.placeholder.com/230x85")
          )
        );
        updateConfig();
        break;
      case ItemTypes.CATEGORY:
        datas.config.push(
          CopyWithJSON(
            getDefaultSlideCategory(
              "https://via.placeholder.com/230x85",
              "Titre"
            )
          )
        );
        updateConfig();
        break;
      case ItemTypes.SLIDER_PRODUCT:
        datas.config.push(
          CopyWithJSON(
            getDefaultSlideProduct(
              "https://via.placeholder.com/230x85",
              "Titre"
            )
          )
        );
        updateConfig();
        break;
      case ItemTypes.DOTATION:
        datas.config.push(CopyWithJSON(getDefaultDotations()));
        updateConfig();
        break;
      case ItemTypes.DOTATION_MORE_DETAILS:
        datas.config.push(CopyWithJSON(getDefaultDotationsMoreDetails()));
        updateConfig();
        break;
      case ItemTypes.DOTATION_BF:
        datas.config.push(CopyWithJSON(getDefaultDotationsBF()));
        updateConfig();
        break;
      case ItemTypes.RULE:
        datas.config.push(CopyWithJSON(getDefaultRules()));
        updateConfig();
        break;
      case ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME:
        datas.config.push(CopyWithJSON(getDefaultRulesInlineFlex()));
        updateConfig();
        break;
      case ItemTypes.RULE_BF:
        datas.config.push(CopyWithJSON(getDefaultRulesBF()));
        updateConfig();
        break;
      case ItemTypes.GALLERY_GAME:
        datas.config.push(CopyWithJSON(getDefaultGallery()));
        updateConfig();
        break;
      case ItemTypes.SOCIAL:
        datas.config.push(CopyWithJSON(getDefaultSocial()));
        updateConfig();
      case ItemTypes.SOCIAL_COMPLETE:
        datas.config.push(CopyWithJSON(getDefaultSocialComplete()));
        updateConfig();
        break;
      case ItemTypes.GAME_QUIZ_STEP:
        datas.config.push(CopyWithJSON(getDefaultListEtapesQuiz()));
        updateConfig();
        break;
      case ItemTypes.GAME_QUIZ_RESULT:
        datas.config.push(CopyWithJSON(getDefaultListResultQuiz()));
        updateConfig();
        break;
      case ItemTypes.SCRATCH_CARD:
        datas.config.push(CopyWithJSON(getDefaultScratchCard()));
        updateConfig();
        break;
      case ItemTypes.CODE_FINDER:
        datas.config.push(CopyWithJSON(getDefaultListCodes()));
        updateConfig();
        break;
      case ItemTypes.CODE_FINDER_PRODUCTS:
        datas.config.push(CopyWithJSON(getDefaultListProducts()));
        updateConfig();
        break;
      case ItemTypes.OFFER_STATS:
        datas.config.push(CopyWithJSON(getOfferStats()));
        updateConfig();
        break;
      case ItemTypes.OFFER_TRIPLE_BLOCK_LIST_POLE:
        datas.config.push(CopyWithJSON(getOfferPole()));
        updateConfig();
        break;
      case ItemTypes.OFFER_TRIPLE_BLOCK_LIST_LEADER:
        datas.config.push(CopyWithJSON(getOfferLeader()));
        updateConfig();
        break;
      case ItemTypes.OFFER_TRIPLE_BLOCK_INFORMATIONS:
        datas.config.push(CopyWithJSON(getOfferInformations()));
        updateConfig();
        break;
      case ItemTypes.OFFER_TRIPLE_BLOCK_LIST_TEXT:
        datas.config.push(CopyWithJSON(getOfferListText()));
        updateConfig();
        break;
      case ItemTypes.RULE:
        datas.config.push(CopyWithJSON(getDefaultRules()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER:
        datas.config.push(CopyWithJSON(getDefaultSlideTop()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_ABOUT:
        datas.config.push(CopyWithJSON(getDefaultSlideAbout()));
        updateConfig();
        break;
      case ItemTypes.CMS_LIST_CATEGORIES:
        datas.config.push(CopyWithJSON(getDefaultListCategories()));
        updateConfig();
        break;
      case ItemTypes.CMS_BLOC:
        datas.config.push(CopyWithJSON(getDefaultCategorie2()));
        updateConfig();
        break;
      case ItemTypes.CMS_TEXTAREA:
        datas.config.push(
          CopyWithJSON(
            getDefaultTextareaCms(
              "Contenu",
              "Sed mi quam, dictum at malesuada ac, rutrum in libero. Curabitur sit amet scelerisque libero. Maecenas non tellus sed lorem congue ullamcorper eget posuere diam. Integer ornare elementum congue. Cras in turpis nec urna sagittis tincidunt id sed dui. Sed suscipit nulla et velit molestie tristique.",
              true,
              "#000",
              true,
              null,
              true,
              true,
              "px",
              null
            )
          )
        );
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_BY_TAB:
        datas.config.push(CopyWithJSON(getDefaultTabForSlider()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_INSTAGRAM:
        datas.config.push(CopyWithJSON(getDefaultSlideInstagram()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
        datas.config.push(CopyWithJSON(BlocTextMedia));
        updateConfig();
        break;
      case ItemTypes.CMS_ACCORDION:
        datas.config.push(CopyWithJSON(getDefaultAccordion()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_IMAGE:
        datas.config.push(CopyWithJSON(getDefaultSlideImage()));
        updateConfig();
        break;
      case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
        datas.config.push(CopyWithJSON(getDefaultDescriptionManifest()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
        datas.config.push(CopyWithJSON(getDefaultSlidePresentationMedia()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_MOMENTS:
        datas.config.push(CopyWithJSON(getDefaultSlideMoments()));
        updateConfig();
        break;
      case ItemTypes.CMS_GRID_IMAGE:
        datas.config.push(CopyWithJSON(getDefaultSlideImage()));
        updateConfig();
        break;
      case ItemTypes.CMS_CATEGORIES_HOME:
        datas.config.push(CopyWithJSON(getDefaultCategorieHome()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
        datas.config.push(CopyWithJSON(getDefaultProductHome()));
        updateConfig();
        break;
      case ItemTypes.CMS_COLLECTIONS_HOME:
        datas.config.push(CopyWithJSON(getDefaultCollectionHome()));
        updateConfig();
      case ItemTypes.CMS_PRESENTATION_DUO:
        datas.config.push(CopyWithJSON(getDefaultCollectionHome()));
        updateConfig();
        break;
      case ItemTypes.CMS_LOOP_LINK:
        datas.config.push(
          CopyWithJSON(
            getDefaultLinkCms(
              "Lien",
              "Information",
              "https://www.lecoqsportif.com/fr-fr/",
              "#000",
              "#FFF",
              true,
              null,
              1
            )
          )
        );
        updateConfig();
        break;
      case ItemTypes.CMS_LIST_CATEGORIES_SUBITEM:
        datas.config.push(CopyWithJSON(getDefaultListCategoriesSubitem()));
        updateConfig();
        break;
      case ItemTypes.CMS_SLIDER_BY_TAB_SUBITEM:
        datas.config.push(CopyWithJSON(getDefaultSlideByTab()));
        updateConfig();
        break;
      case ItemTypes.IMAGE_WITH_LINK:
        datas.config.push(CopyWithJSON(getDefaultSimpleSlide()));
        updateConfig();
        break;
      case ItemTypes.LIST_IMAGES:
        datas.config.push(CopyWithJSON(getDefaultListImages()));
        updateConfig();
        break;
      case ItemTypes.LIST_IMAGES_WITH_HOVER:
        datas.config.push(CopyWithJSON(getDefaultListImagesWithHover()));
        updateConfig();
        break;
      default:
        return null;
    }
  };

  const deleteBlock = (datas, i) => {
    datas.config.splice(i, 1);
    updateConfig();
  };

  // SPECIFIC

  const updateFlipbook = (newValues) => {
    setPagesFlipbook(newValues);
  };

  // SAVING

  const save = async () => {
    snack(ALERT_INFO, "Sauvegarde...");

    if (presentation) {
      let elements = config[PAGE_TO_EDIT]?.elements;
      let allModels = [];

      for (let element of elements) {
        if (element.type === ItemTypes.OFFER_DEVIS) {
          if (!element.offer) {
            if (presentationModel) {
              // CREATE OFFER MODEL

              const ADD_MODELE_RESULT = await client.mutate({
                mutation: ADD_MODELE,
                variables: {
                  name: element.data.inputs?.offerName?.value,
                  description: element.data.inputs?.offerDescription?.value,
                  recurring: element.data.inputs?.offerRecurring?.value,
                  recurringStartDate:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringStartDate?.value,
                  recurringInvoiceDay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringInvoiceDay?.value,
                  recurringDelay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringDelay?.value,
                },
              });

              element.offer = ADD_MODELE_RESULT.data.createModel.model.id;

              allModels.push(element.offer);

              await savePhases(
                element.offer,
                element.data.phases,
                client,
                false
              );
            } else {
              // CREATE OFFER

              const ADD_OFFER_RESULT = await client.mutate({
                mutation: ADD_OFFER,
                variables: {
                  title: element.data.inputs?.offerName?.value,
                  description: element.data.inputs?.offerDescription?.value,
                  poNumber: element.data.inputs?.offerNumberPO?.value,
                  status: "processing",
                  createdAt: moment().format("YYYY-MM-DD"),
                  validityDate: element.data.inputs?.offerValidityDate?.value,
                  recurring: element.data.inputs?.offerRecurring?.value,
                  recurringStartDate:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringStartDate?.value,
                  recurringInvoiceDay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringInvoiceDay?.value,
                  recurringDelay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringDelay?.value,
                },
              });

              element.offer = ADD_OFFER_RESULT.data.createOffer.offer.id;

              const ADD_FOOTER_OFFER_RESULT = await client.mutate({
                mutation: ADD_FOOTER_OFFER,
                variables: {
                  offer: element.offer,
                  discountFixed: element.data.footer?.discountFixed,
                  discountPercent: element.data.footer?.discountPercent,
                  advancePayment: element.data.footer?.advancePayment,
                  paymentTerm: element.data.footer?.paymentTerm,
                  paymentDeadline: element.data.footer?.paymentDeadline,
                  comment: element.data.footer?.comment,
                },
              });

              element.data.footer.id =
                ADD_FOOTER_OFFER_RESULT.data.createOfferFooter.offerFooter.id;

              let { totals } = getTotals(element.data.phases, taxes);

              for (let tax of taxes) {
                let amount = totals.find((e) => e.tax.id === tax.node.id);

                await client.mutate({
                  mutation: ADD_OFFER_FOOTER_TAX,
                  variables: {
                    offerFooter:
                      ADD_FOOTER_OFFER_RESULT.data.createOfferFooter.offerFooter
                        .id,
                    tax: tax.node.id,
                    amount: amount.total,
                  },
                });
              }

              allModels.push(element.offer);

              await savePhases(
                element.offer,
                element.data.phases,
                client,
                true
              );
            }
          } else {
            if (presentationModel) {
              // UPDATE OFFER MODEL

              await client.mutate({
                mutation: UPDATE_MODELE,
                variables: {
                  id: element.offer,
                  name: element.data.inputs?.offerName?.value,
                  description: element.data.inputs?.offerDescription?.value,
                  recurring: element.data.inputs?.offerRecurring?.value,
                  recurringStartDate:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringStartDate?.value,
                  recurringInvoiceDay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringInvoiceDay?.value,
                  recurringDelay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringDelay.value,
                },
              });
            } else {
              // UPDATE OFFER

              await client.mutate({
                mutation: UPDATE_OFFER,
                variables: {
                  id: element.offer,
                  title: element.data.inputs?.offerName?.value,
                  description: element.data.inputs?.offerDescription?.value,
                  poNumber: element.data.inputs?.offerNumberPO?.value,
                  validityDate: element.data.inputs?.offerValidityDate?.value,
                  status: "processing",
                  recurring: element.data.inputs?.offerRecurring?.value,
                  recurringStartDate:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringStartDate?.value,
                  recurringInvoiceDay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringInvoiceDay?.value,
                  recurringDelay:
                    element.data.inputs?.offerRecurring?.conditionalInputs
                      ?.offerRecurringDelay.value,
                },
              });

              await client.mutate({
                mutation: UPDATE_FOOTER_OFFER,
                variables: {
                  id: element.data.footer?.id,
                  discountFixed: element.data.footer?.discountFixed,
                  discountPercent: element.data.footer?.discountPercent,
                  advancePayment: element.data.footer?.advancePayment,
                  paymentTerm: element.data.footer?.paymentTerm,
                  paymentDeadline: element.data.footer?.paymentDeadline,
                  comment: element.data.footer?.comment,
                },
              });

              let { totals } = getTotals(element.data.phases, taxes);

              let existingTaxes = element.data.footer?.taxes ?? [];
              let allTaxes = [];

              for (let tax of taxes) {
                let amount = totals.find((e) => e.tax.id === tax.node.id);
                let found = existingTaxes.find((e) => e === tax.node.id);

                if (found) {
                  // Tax exists

                  await client.mutate({
                    mutation: UPDATE_OFFER_FOOTER_TAX,
                    variables: {
                      id: found,
                      offerFooter: element.data.footer?.id,
                      tax: tax.node.id,
                      amount: amount.total,
                    },
                  });

                  allTaxes.push(found);
                } else {
                  // Tax doesn't exist

                  const ADD_OFFER_FOOTER_TAX_RESULT = await client.mutate({
                    mutation: ADD_OFFER_FOOTER_TAX,
                    variables: {
                      offerFooter: element.data.footer?.id,
                      tax: tax.node.id,
                      amount: amount.total,
                    },
                  });

                  allTaxes.push(
                    ADD_OFFER_FOOTER_TAX_RESULT.data.createOfferFooterTax
                      .offerFooterTax.id
                  );
                }
              }

              element.data.footer.taxes = allTaxes;
            }

            allModels.push(element.offer);

            await savePhases(
              element.offer,
              element.data.phases,
              client,
              !presentationModel
            );
          }
        }
      }

      updateConfig();

      let toDelete = presentationModel
        ? offer.models.edges.filter(
            (e) => !allModels.find((f) => f === e.node.id)
          )
        : offer.offers.edges.filter(
            (e) => !allModels.find((f) => f === e.node.id)
          );

      for (let model of toDelete) {
        try {
          await client.mutate({
            mutation: presentationModel ? DELETE_MODELE : DELETE_OFFER,
            variables: { id: model.node.id },
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (presentationModel) {
        await client.mutate({
          mutation: UPDATE_MODEL_PRESENTATION,
          variables: {
            id: offer.id,
            htmlContent: JSON.stringify(config[PAGE_TO_EDIT]),
            models: allModels,
          },
        });
      } else {
        await client.mutate({
          mutation: UPDATE_PRESENTATION,
          variables: {
            id: offer.id,
            htmlContent: JSON.stringify(config[PAGE_TO_EDIT]),
            offers: allModels,
            status: offer.status === "processing" ? "ready" : offer.status,
          },
        });
      }

      snack(ALERT_SUCCESS, "Configuration sauvegardée !");
    } else if (
      asset.assetType.identifier === "minisite" ||
      asset.assetType.identifier === "newsletter" ||
      asset.assetType.identifier === "flipbook"
    ) {
      let page = asset.assetMinisitePages.edges.find(
        (e) => e.node.assetMinisitePageType.identifier === PAGE_TO_EDIT
      );
      let content = config[PAGE_TO_EDIT];

      if (content.isEmail) {
        let markup = ReactDOMServer.renderToStaticMarkup(getEmail(content));
        content.html = EmailBase(markup);
      }

      if (PAGE_TO_EDIT === Pages.EMAIL_SUCCESS_BOOK) {
        content.email = htmlBuildSuccessEmail(content?.elements?.[0]?.data);
      }
      if (
        PAGE_TO_EDIT === Pages.EMAIL_WIN_BOOK ||
        PAGE_TO_EDIT === Pages.EMAIL_WIN_BOOK_2
      ) {
        content.email = htmlBuildWinEmail(content?.elements?.[0]?.data);
      }
      if (PAGE_TO_EDIT === Pages.EMAIL_LOOSE_BOOK) {
        content.email = htmlBuildLooseEmail(content?.elements?.[0]?.data);
      }

      await client.mutate({
        mutation: UPDATE_MINISITE_PAGE,
        variables: {
          id: page.node.id,
          content: JSON.stringify(content),
          status: "Ready",
        },
      });

      await client.mutate({
        mutation: UPDATE_ASSET,
        variables: {
          id: asset.id,
          content: JSON.stringify(config.params),
        },
      });

      snack(ALERT_SUCCESS, "Configuration sauvegardée !");
    } else if (
      asset.assetType.identifier === "magento" ||
      asset.assetType.identifier === "shopify" ||
      asset.assetType.identifier === "wordpress"
    ) {
      let getData = await dry();

      await client.mutate({
        mutation: UPDATE_MAGENTO_PAGE_CONTENT,
        variables: {
          id: `/api/asset-magento-page-contents/${contentId}`,
          contentConfig: JSON.stringify(config[PAGE_TO_EDIT]),
          content: getData,
        },
      });

      await client.mutate({
        mutation: UPDATE_MAGENTO_PAGE,
        variables: {
          id: assetPage,
          hasToBePush: true,
        },
      });

      snack(ALERT_SUCCESS, "Configuration sauvegardée !");
    } else if (asset.assetType.identifier === "pages") {
      let getData = await dry();
      let getStructure = structure();

      await client.mutate({
        mutation: UPDATE_PAGE_CONTENT,
        variables: {
          id: `/api/asset-pages-page-contents/${contentId}`,
          contentConfig: JSON.stringify(config[PAGE_TO_EDIT]),
          content: getData,
          componentsStructure: JSON.stringify(getStructure),
        },
      });

      await client.mutate({
        mutation: UPDATE_PAGE,
        variables: {
          id: assetPage,
          hasToBePush: true,
        },
      });

      snack(ALERT_SUCCESS, "Configuration sauvegardée !");
    } else if (asset.assetType.identifier === "jeu") {
      let page = asset.assetGamePages.edges.find(
        (e) => e.node.assetGamePageType.identifier === PAGE_TO_EDIT
      );
      let content = config[PAGE_TO_EDIT];
      let html = "";

      if (content.isEmail) {
        let markup = ReactDOMServer.renderToStaticMarkup(getEmail(content));
        content.html = EmailBase(markup);
      }

      if (
        PAGE_TO_EDIT === Pages.EMAIL_CONFIRMATION_IG_GAME ||
        PAGE_TO_EDIT === Pages.EMAIL_CONFIRMATION_RANDOM_GAME ||
        PAGE_TO_EDIT === Pages.EMAIL_WIN_IG_GAME ||
        PAGE_TO_EDIT === Pages.EMAIL_WIN_RANDOM_GAME ||
        PAGE_TO_EDIT === Pages.EMAIL_SHARE_IG_GAME
      ) {
        content.email = htmlBuildGameEmail(content?.elements?.[0]?.data);
        html = htmlBuildGameEmail(content?.elements?.[0]?.data);
        await client.mutate({
          mutation: UPDATE_GAME_PAGE,
          variables: {
            id: page.node.id,
            content: JSON.stringify(content),
            htmlContent: html,
            status: "Ready",
          },
        });
      } else {
        html += `<div id="builder-template-scope-game" style="background-image${
          config.params?.bg?.value
            ? typeof config.params.bg?.value === "string"
              ? `url(${config.params.bg?.value})`
              : `url(${process.env.REACT_APP_MEDIAS}/${config.params.bg.value.filePath})`
            : null
        }; max-width: ${
          config.params?.maxWidth?.value
            ? `${config.params?.maxWidth?.value}px`
            : "100%"
        };">`;

        for (let element of config[PAGE_TO_EDIT].elements) {
          let markup = ReactDOMServer.renderToStaticMarkup(
            getComponent(
              element,
              0,
              assetType,
              assetToken,
              preview,
              TypedDroppable,
              config,
              apiConfig,
              updateConfig,
              inputCallback,
              deleteComponent,
              taxes,
              currentTab
            )
          );

          html += markup;
        }

        html += "</div>";
        await client.mutate({
          mutation: UPDATE_GAME_PAGE,
          variables: {
            id: page.node.id,
            content: JSON.stringify(config[PAGE_TO_EDIT]),
            htmlContent: html,
            status: "Ready",
          },
        });
      }

      if (config.params) {
        await client.mutate({
          mutation: UPDATE_ASSET,
          variables: {
            id: asset.id,
            content: JSON.stringify(config.params),
          },
        });
      }

      snack(ALERT_SUCCESS, "Configuration sauvegardée !");
    } else if (asset.assetType.identifier === "gift_finder") {
      await client.mutate({
        mutation: UPDATE_ASSET,
        variables: {
          id: asset.id,
          content: JSON.stringify(config[PAGE_TO_EDIT]),
        },
      });

      snack(ALERT_SUCCESS, "Configuration sauvegardée !");
    }
  };

  const reinit = async () => {
    if (presentation) {
      if (presentationModel) {
        await client.mutate({
          mutation: UPDATE_MODEL_PRESENTATION,
          variables: {
            id: offer.id,
            htmlContent: null,
          },
        });
      } else {
        await client.mutate({
          mutation: UPDATE_PRESENTATION,
          variables: {
            id: offer.id,
            htmlContent: null,
          },
        });
      }
    } else if (
      asset.assetType.identifier === "minisite" ||
      asset.assetType.identifier === "newsletter" ||
      asset.assetType.identifier === "flipbook"
    ) {
      // let page = asset.assetMinisitePages.edges.find(e => e.node.assetMinisitePageType.identifier === PAGE_TO_EDIT);

      // await client.mutate({
      //     mutation: UPDATE_MINISITE_PAGE,
      //     variables: {
      //         id: page.node.id,
      //         content: null,
      //         status: '0'
      //     },
      // });

      // await client.mutate({
      //     mutation: UPDATE_ASSET,
      //     variables: {
      //         id: asset.id,
      //         content: null
      //     },
      // });

      config[PAGE_TO_EDIT] = getDefaultPageConfig(
        PAGE_TO_EDIT,
        asset.assetType.identifier
      );

      snack(ALERT_SUCCESS, "Configuration réinitialisée !");
    } else if (asset.assetType.identifier === "jeu") {
      // let page = asset.assetGamePages.edges.find(e => e.node.assetGamePageType.identifier === PAGE_TO_EDIT);

      // await client.mutate({
      //     mutation: UPDATE_GAME_PAGE,
      //     variables: {
      //         id: page.node.id,
      //         content: null,
      //         status: '0'
      //     },
      // });

      config[PAGE_TO_EDIT] = getDefaultPageConfig(
        PAGE_TO_EDIT,
        asset.assetType.identifier
      );

      snack(ALERT_SUCCESS, "Configuration réinitialisée !");
    } else if (asset.assetType.identifier === "gift_finder") {
      await client.mutate({
        mutation: UPDATE_ASSET,
        variables: {
          id: asset.id,
          content: null,
        },
      });

      snack(ALERT_SUCCESS, "Configuration réinitialisée !");
    }
  };

  const structure = () => {
    let structure = [];

    // <script src="https://unpkg.com/swiper@5.4.5/js/swiper.min.js"></script>
    // <link rel="stylesheet" href="https://unpkg.com/swiper@5.4.5/css/swiper.min.css" />

    try {
      _.forEach(config[PAGE_TO_EDIT].elements, (element, index) => {
        let component = getComponentCMS(element, index);

        const sheet = new ServerStyleSheet();

        // html
        let markup = ReactDOMServer.renderToString(
          sheet.collectStyles(<>{component}</>)
        );

        // css
        let styles = sheet.getStyleTags();
        styles = styles.replaceAll("/*!sc*/", "");
        styles = styles.replaceAll("\n", "");

        // js
        let scripts = getScripts(element.type);

        if (index === 0) {
          markup =
            '<script src="https://unpkg.com/swiper@5.4.5/js/swiper.min.js"></script>' +
            markup;
          scripts = `
                        document.getElementsByTagName("head")[0].insertAdjacentHTML("beforeend",
                        "<link rel='stylesheet' href='https://unpkg.com/swiper@5.4.5/css/swiper.min.css' />");
                        ${scripts}
                    `;
        }

        structure.push({
          identifier: element?.data?.inputs.identifiant?.value,
          html: markup,
          css: styles,
          js: scripts,
        });
      });
    } catch (e) {
      console.log(e?.message ?? e);
    }

    return structure;
  };

  const dry = async () => {
    let components = [];
    let scripts = "";

    _.forEach(config[PAGE_TO_EDIT].elements, (element, index) => {
      scripts += getScripts(element.type);

      let component = getComponentCMS(element, index);
      components.push(component);
    });

    const sheet = new ServerStyleSheet();
    let result = null;

    try {
      let markup = ReactDOMServer.renderToString(
        sheet.collectStyles(<>{components}</>)
      );
      let styles = sheet.getStyleTags();

      styles = styles.replaceAll("/*!sc*/", "");
      styles = styles.replaceAll("\n", "");

      let finalScripts = `
                <script type="text/javascript" xml="space">
                    // <![CDATA[
                        ${scripts}
                    // ]]>
                </script>
            `;

      result = markup + styles;
    } catch (e) {
      console.log(e);
    } finally {
      sheet.seal();
      return result;
    }
  };

  window.dry = dry;

  // RENDERING

  const getPageName = (identifier) => {
    switch (identifier) {
      case "home":
        return "Page d'accueil";
      case "landing":
        return "Landing produits";
      case "product_details":
        return "Page produit";
      case "contact":
        return "Page de contact";
      case "wishlist":
        return "Wishlist";
      case "home_game":
        return "Page d'accueil du jeu";
      case "login_game":
        return "Identification du joueur";
      case "index_game":
        return "Index du jeu";
      case "game_game":
        return "Page de jeu";
      case "results_win_game":
        return "Résultat - Gagné";
      case "results_loose_game":
        return "Résultat - Perdu";
      case "alreadyplayed_game":
        return "Déjà joué";
      case "alreadywin_game":
        return "Déjà gagné";
      case "ended_game":
        return "Jeu terminé";
      case "not_started_game":
        return "Jeu non commencé ";
      case "flipbook":
        return "Flipbook";
      case "register":
        return "Demande enregistement";
      case "register_success":
        return "Enregistrement réussi";
      case "confirmation_email_random_draw":
        return "Email de confirmation";
      case "mail_confirmation":
        return "Email de confirmation";
      case "email_game_win_random_draw":
        return "Email de victoire";
      case "mail_win":
        return "Email de victoire";
      case "mail_win_virtual":
        return "Email de victoire virtual";
      case "email_share_win_random_draw":
        return "Email de partage";
      case "mail_share":
        return "Email de partage";
      case "email_register":
        return "Email de succès";
      case "email_contact":
        return "Email contact";
      case "email_wishlist":
        return "Email wishlist";
      case "email_win":
        return "Email de victoire";
      case "email_win_2":
        return "Email de victoire 2";
      case "email_loose":
        return "Email perdu";
      case Pages.PRESENTATION:
        return "Présentation";
      default:
        return null;
    }
  };

  const getImageType = (asset) => {
    const images = require.context("../assets/images", true);
    let image;
    try {
      image = images("./" + asset);
    } catch (e) {
      image = imgNotFound;
    }
    return image;
  };

  const changePage = (identifier) => {
    setTab(0);
    setComponent(null);
    setBlock(null);
    setOpenChangerPage(false);

    let page =
      asset.assetType.identifier === "minisite" ||
      asset.assetType.identifier === "newsletter" ||
      asset.assetType.identifier === "flipbook"
        ? asset.assetMinisitePages.edges.find(
            (e) => e.node.assetMinisitePageType.identifier === identifier
          )
        : asset.assetType.identifier === "jeu"
        ? asset.assetGamePages.edges.find(
            (e) => e.node.assetGamePageType.identifier === identifier
          )
        : null;

    config[identifier] = page.node.content
      ? JSON.parse(page.node.content)
      : getDefaultPageConfig(identifier, asset.assetType.identifier);

    setLocalConfig(config);

    history.push({
      pathname: ROUTE_BUILDER.replace(":assetId", assetId).replace(
        ":pageId",
        identifier
      ),
      state: { page: identifier },
    });
  };

  let pages = [];
  let pagesImg = 0;

  if (asset?.assetMinisitePages.edges.length > 0) {
    pages =
      asset?.assetMinisitePages.edges.map((page, i) => ({
        value: page.node.assetMinisitePageType.identifier,
        label: getPageName(page.node.assetMinisitePageType.identifier),
        image: getImageType(page.node.assetMinisitePageType.image),
      })) ?? [];

    for (let page of pages) {
      if (page.image !== imgNotFound) {
        pagesImg++;
      }
    }
  } else if (asset?.assetGamePages.edges.length > 0) {
    pages =
      asset?.assetGamePages.edges.map((page, i) => ({
        value: page.node.assetGamePageType.identifier,
        label: getPageName(page.node.assetGamePageType.identifier),
        image: getImageType(page.node.assetGamePageType.image),
      })) ?? [];

    for (let page of pages) {
      if (page.image !== imgNotFound) {
        pagesImg++;
      }
    }
  }

  let data = [
    // base
    asset,
    assetType,
    assetToken,
    PAGE_TO_EDIT,
    preview,
    noPanel,
    TypedDroppable,
    // config
    config,
    apiConfig,
    // methods
    updateConfig,
    inputCallback,
    deleteComponent,
    // specifics
    taxes,
    pagesFlipbook,
    (element) => {
      setSnapIdentifier(element.type);
      setSnapData(element.data);
      setSnapModalOpen(true);
    },
    (element) => {
      setSnapIdentifier(element.type);
      setSnapListModalOpen(true);
      setSnapKey(element.key);
    },
  ];

  const context = {
    asset,
    assetType,
    assetToken,
    ready,
    catalog,
    currentTab,
    currentComponent,
    setComponent,
    buildForm,
    buildGeneralInputs,
    products,
    updateProducts,
    data,
    save,
    reinit,
    page: PAGE_TO_EDIT,
    onlyContent,
    noPanel,
    setNoPanel,
    preview,
    setPreview,
    pages,
    pagesImg,
    getPageName,
    changePage,
    height,
    presentation,
    presentationModel,
    offer,
    exportLink,
    changeTab,
    dev,
    localConfig,
    setLocalConfig,
    currentTab,
    assetPageName,
  };

  return (
    <BuilderContext.Provider value={context}>
      <div className="builder" id="builder">
        {!onlyContent && !noPanel ? (
          <>
            <BuilderPanel
              extractAcceptsFromType={extractAcceptsFromType}
              addComponent={addComponent}
              updateConfig={updateConfig}
              startLoading={startLoading}
              stopLoading={stopLoading}
            />

            <BuilderContent />
          </>
        ) : (
          <BuilderContent />
        )}

        {/* Snapshots */}

        {snapModalOpen && (
          <SnapshotCreationModal
            identifier={snapIdentifier}
            data={snapData}
            onClose={() => setSnapModalOpen(false)}
          />
        )}

        {snapListModalOpen && (
          <SnapshotListingModal
            identifier={snapIdentifier}
            onClose={() => setSnapListModalOpen(false)}
            onValidate={(snap) => {
              if (!snap) return;

              for (let element of config[PAGE_TO_EDIT].elements) {
                if (element.key === snapKey) {
                  element.data = JSON.parse(snap.node.content);
                  updateConfig();
                  setSnapListModalOpen(false);
                  snack(ALERT_SUCCESS, "Snapshot appliqué !");
                }
              }
            }}
          />
        )}
      </div>
    </BuilderContext.Provider>
  );
};

// Styles

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

// Redux

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    locales: state.locales,
  };
};

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(BuilderComponent))
);
