import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import CardProduct from "../Card/cardContent/CardProduct";
import Swiper from "react-id-swiper";
import colors from '../../../config/theme/colors';

const useStyles = makeStyles((theme) => ({
    arrow: {
      display:'block',    
      zIndex:2,
      fill:'black',
      position:"absolute",
      backgroundColor:'white',
      border:"0.5px solid black",
      borderRadius:"50%",
      width:'30px',
      height:'30px',
      opacity:'1', 
      "&:hover":{
        opacity:'1 !important',
        fill:'black',
        backgroundColor:'white',
      }   
    },
    right:{
      right:'-20px'
    },
    left:{
      left:'-20px'
    }
  }));

const CarouselProductsContent = (props)=>{
    const {products,index} = props
    const classes = useStyles();

    const SampleArrow = (props) =>{
        const { className, style, onClick } = props;     
        return (        
          props.value === 'next'?
          <ChevronRightIcon onClick={onClick} className={[classes.arrow,classes.right,className]} />
          :<ChevronLeftIcon onClick={onClick} className={[classes.arrow,classes.left,className]}  />
        );
      }    
      const params = {
        slidesPerView:products.length <=2?2: 2.5,
        spaceBetween: 35,
        pagination: false,
      }

    return (
      <div style={{
        width:"100%"
      }}>
        <Swiper {...params}>
            {                                
                products.map((e,i)=>{
                    return (      
                      <div>
                        <CardProduct
                            currentLang={props.currentLang}
                            product={e?.node}
                            index={i}
                            border={`0.5px solid ${colors.grey.border}`}
                            paddingCard={"10px 20px"}
                        />
                      </div>                               
                    )
                })
            }                                           
        </Swiper>   
      </div>
    )
}

export default CarouselProductsContent